import { Injectable } from '@angular/core';
import { StoreService } from '../services/store/store.service';
import { PcBookingRpStatusPipe } from './pc-booking-rp-status/pc-booking-rp-status.pipe';
import { PcContentCreateKindPipe } from './pc-content-create-kind/pc-content-create-kind.pipe';
import { PcDatePipe } from './pc-date/pc-date.pipe';
import { PcImagePipe } from './pc-image/pc-image.pipe';
import { PcModelKindPipe } from './pc-model-type/pc-model-type.pipe';
import { PcOpeningHoursRequestStatusPipe } from './pc-opening-hours-request-status/pc-opening-hours-request-status.pipe';
import { PcShopServicePipe } from './pc-shop-service/pc-shop-service.pipe';
import { PcTicketCategoryPipe } from './pc-ticket-category/pc-ticket-category.pipe';
import { PcTicketLocationPipe } from './pc-ticket-location/pc-ticket-location.pipe';

@Injectable({
  providedIn: 'root',
})
export class PipeService {
  private datePipe: PcDatePipe;
  private imagePipe: PcImagePipe;
  private modelKindPipe: PcModelKindPipe;
  private contentCreatePipe: PcContentCreateKindPipe;
  private shopServicePipe: PcShopServicePipe;
  private bookingRpStatusPipe: PcBookingRpStatusPipe;
  private openingHoursRequestStatusPipe: PcOpeningHoursRequestStatusPipe;
  private ticketCategoryPipe: PcTicketCategoryPipe;
  private ticketLocationPipe: PcTicketLocationPipe;

  constructor(private store: StoreService) {
    this.datePipe = new PcDatePipe();
    this.imagePipe = new PcImagePipe();
    this.modelKindPipe = new PcModelKindPipe();
    this.contentCreatePipe = new PcContentCreateKindPipe(this.modelKindPipe);
    this.shopServicePipe = new PcShopServicePipe();
    this.bookingRpStatusPipe = new PcBookingRpStatusPipe();
    this.openingHoursRequestStatusPipe = new PcOpeningHoursRequestStatusPipe();
    this.ticketCategoryPipe = new PcTicketCategoryPipe(store);
    this.ticketLocationPipe = new PcTicketLocationPipe(store);
  }

  get datePipe$(): PcDatePipe {
    return this.datePipe;
  }

  get modelKindPipe$(): PcModelKindPipe {
    return this.modelKindPipe;
  }

  get contentCreatePipe$(): PcContentCreateKindPipe {
    return this.contentCreatePipe;
  }

  get bookingRpStatusPipe$(): PcBookingRpStatusPipe {
    return this.bookingRpStatusPipe;
  }

  get shopServicePipe$(): PcShopServicePipe {
    return this.shopServicePipe;
  }

  get imagePipe$(): PcImagePipe {
    return this.imagePipe;
  }

  get openingHoursRequestStatusPipe$(): PcOpeningHoursRequestStatusPipe {
    return this.openingHoursRequestStatusPipe;
  }

  get ticketCategoryPipe$(): PcTicketCategoryPipe {
    return this.ticketCategoryPipe;
  }

  get ticketLocationPipe$(): PcTicketLocationPipe {
    return this.ticketLocationPipe;
  }
}
