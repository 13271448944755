import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { PcIcon } from '@pc-types';

@Injectable({
  providedIn: 'root',
})
export class IconService {
  private icons: PcIcon[] = [
    '3_attention',
    '5_dragdrop',
    '5_more',
    '6_hamburger',
    '6_search',
    '8_feedback',
    '8_images',
    '8_notification',
    'abo',
    'add',
    'archive',
    'arrow-sorting',
    'arrow',
    'badge_crew',
    'badge_offer-coupon',
    'badge_offer-menu',
    'badge_offer',
    'calendar',
    'call',
    'cancel',
    'check-in',
    'check-out',
    'check',
    'clock',
    'close',
    'content_competition',
    'content_event',
    'content_news',
    'content_offer',
    'content-check_competition',
    'content-check_event',
    'content-check_news',
    'content-check_offer',
    'content-reject_competition',
    'content-reject_event',
    'content-reject_news',
    'content-reject_offer',
    'download',
    'duplicate',
    'dynamic-link',
    'edit-pen',
    'edit',
    'email',
    'feedback',
    'info',
    'maximize',
    'minimize',
    'minus-circle',
    'minus',
    'monthly-report',
    'notification_promotion-reply',
    'notification_promotion',
    'offer-coupon',
    'offer-menu',
    'outbreak',
    'pdf',
    'pin',
    'plus',
    'plus-circle',
    'preview',
    'print',
    'qr-code',
    'quickaction_archive',
    'quickaction_call',
    'quickaction_cancel',
    'quickaction_cancel',
    'quickaction_check-out',
    'quickaction_check',
    'quickaction_dynamic-link',
    'quickaction_edit',
    'quickaction_more',
    'quickaction_outbreak',
    'quickaction_preview',
    'quickaction_qr-code',
    'quickaction_trash',
    'read-no',
    'read-yes',
    'refresh',
    'repeat',
    'reply',
    'report_delayed',
    'report_locked',
    'report_pending',
    'save',
    'sortby-down',
    'sortby-up',
    'sortby',
    'support_kontakt',
    'support_tour',
    'tag-add',
    'tag-delete',
    'ticket',
    'trash',
    'uploader_check',
    'uploader_error',
    'uploader_loading',
    'uploader_trash',
    'visibility-off',
    'visibility-on',
    'warning',
    'weather_cloud',
    'weather_fog',
    'weather_rain-light',
    'weather_rain-snow-light',
    'weather_rain-snow',
    'weather_rain',
    'weather_snow-light',
    'weather_snow',
    'weather_sun-cloud',
    'weather_sun',
    'weather_thunderstorm',
  ];

  // svgs, that should not be compressed with svgo
  private plainSvgs: PcIcon[] = [];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}

  public initRegistry(): void {
    this.icons.map((iconName: PcIcon) => {
      this.matIconRegistry.addSvgIcon(
        iconName,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `assets/icons/${iconName}.svg`
        )
      );
    });

    this.plainSvgs.map((iconName: PcIcon) => {
      this.matIconRegistry.addSvgIcon(
        iconName,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `assets/svg/${iconName}.svg`
        )
      );
    });
  }

  public getIcons(): PcIcon[] {
    return this.icons;
  }
}
