import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '@pc-ui/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { ImagePreviewDialogComponent } from './image-preview-dialog.component';
import { ImageModule } from '@pc-ui/image/image.module';

@NgModule({
  declarations: [ImagePreviewDialogComponent],
  imports: [CommonModule, IconModule, ImageModule, MatDialogModule],
  exports: [ImagePreviewDialogComponent],
})
export class ImagePreviewDialogModule {}
