<div class="items-top js-error-scroll flex space-x-2 text-error-500" *ngIf="!errors?.silent">
  <div class="pt-0.5" *ngIf="isIconVisible">
    <pc-icon icon="3_attention" [size]="3"></pc-icon>
  </div>
  <div class="typo-bart">
    <ng-content></ng-content>
    <div *ngIf="errors">
      <div *ngIf="errors?.required">Dieses Feld ist ein Pflichtfeld.</div>
      <div *ngIf="errors?.requiredTrue">Dieses Feld ist ein Pflichtfeld.</div>
      <div *ngIf="errors?.maxlength">Dieses Feld ist zu lang.</div>
      <div *ngIf="errors?.minlength">Dieses Feld ist zu kurz.</div>
      <div *ngIf="errors?.pattern">Dieses Feld hat ein falsches Format.</div>
      <div *ngIf="errors?.mindate">Dieses Datum ist zu früh.</div>
      <div *ngIf="errors?.mintime">Diese Zeit ist zu früh.</div>
      <div *ngIf="errors?.maxdate">Dieses Datum ist zu spät.</div>
      <div *ngIf="errors?.min">Diese Zahl ist zu klein.</div>
      <div *ngIf="errors?.max">Diese Zahl ist zu groß.</div>
      <div *ngIf="errors?.email">Gültige E-Mail Adresse eingeben.</div>
      <div *ngIf="errors?.url">Gültige Url eingeben.</div>
      <div *ngIf="errors?.urlHttps">Dieses Feld benötigt folgendes Format: https://www.ihrewebsite.at/xyz</div>
      <div *ngIf="errors?.phone">Gültige Telefonnummer eingeben. Es sind nur Zahlen und "+" erlaubt. Min. 7 Ziffern.</div>
      <div *ngIf="errors?.custom">{{ errors.custom }}</div>
    </div>
  </div>
</div>
