import { Injectable } from '@angular/core';
import { PcItemPermission } from '@pc-types';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { isItemVisibleByPermissions } from '../../helpers/permission.helper';
import { StoreService } from '../store/store.service';

type PcRoutePermission = {
  url: string;
  permission?: PcItemPermission;
  isDynamicRoute?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class RouteService {
  public readonly routes: { [key in string]: PcRoutePermission } = {
    passwordReset: { url: '/start/password-reset' },
    login: { url: '/start/login' },
    feed: { url: '/feed' },
    feedView: { url: '/feed/view' },
    content: {
      url: '/content',
      permission: {
        module: 'content',
      },
    },
    contentEdit: {
      url: '/content/edit',
      permission: { module: 'content' },
      isDynamicRoute: true,
    },
    announcements: {
      url: '/announcements',
      permission: {
        module: 'announcements',
        role: 'manager',
      },
    },
    announcementEdit: {
      url: '/announcements/edit',
      permission: {
        module: 'announcements',
        role: 'manager',
      },
      isDynamicRoute: true,
    },
    promotions: {
      url: '/promotions',
      permission: {
        module: 'content',
        role: 'manager',
      },
    },
    promotion: {
      url: '/promotions/view',
      isDynamicRoute: true,
    },
    reservePickup: {
      url: '/reserve-pickup',
      permission: {
        module: 'reservepickup',
        isShopShop: true,
      },
      isDynamicRoute: true,
    },
    gastro: {
      url: '/gastro',
      permission: {
        isGastroBookingEnabled: true,
      },
    },
    jobs: {
      url: '/jobs',
      permission: {
        module: 'jobs',
      },
    },
    ticketing: {
      url: '/ticketing',
      permission: {
        module: 'ticketing',
        role: 'manager',
      },
    },
    ticket: {
      url: '/ticketing/view',
      permission: {
        module: 'ticketing',
        role: 'manager',
      },
      isDynamicRoute: true,
    },
    userManagement: {
      url: '/user-management',
      permission: { module: 'usermanagement', role: 'manager' },
    },
    reports: {
      url: '/reports',
      permission: {
        module: 'reports',
        role: 'manager',
      },
    },
    job: {
      url: '/jobs/view',
      permission: {
        module: 'jobs',
      },
      isDynamicRoute: true,
    },
    jobEdit: {
      url: '/jobs/edit',
      permission: {
        module: 'jobs',
      },
      isDynamicRoute: true,
    },
    insights: {
      url: '/insights',
      permission: {
        module: 'insights',
      },
    },
    cookieSettings: { url: '/cookie-einstellungen' },
    dataProtection: { url: '/datenschutz' },
    agb: { url: '/agb' },
    imprint: { url: '/impressum' },
    kids: {
      url: '/kids',
      permission: {
        module: 'kids',
      },
    },
    userSettings: { url: '/settings/user', isDynamicRoute: true },
    shopSettings: {
      url: '/settings/shop',
      permission: { module: 'shop', role: 'tenant' },
      isDynamicRoute: true,
    },
  };

  private blockedRoutes?: string[];

  public initialized$ = new BehaviorSubject(false);

  constructor(private store: StoreService) {}

  public init(): void {
    combineLatest([
      this.store.permissions$,
      this.store.myShop$,
      this.store.myGlobalPermissions$,
    ]).subscribe(([permissions, myShop, myGlobalPermissions]) => {
      this.blockedRoutes = [];
      Object.keys(this.routes).forEach((routeKey) => {
        const route = this.routes[routeKey];
        const isItemVisible = isItemVisibleByPermissions(
          route.permission,
          permissions,
          myShop,
          myGlobalPermissions
        );

        if (!isItemVisible) {
          this.blockedRoutes?.push(routeKey);
        }
      });

      this.initialized$.next(true);
    });
  }

  public isRouteAvailable(route: string): boolean {
    const isAvailable = !this.blockedRoutes?.some((blockedRouteKey) => {
      const blockedRoute = this.routes[blockedRouteKey];
      const lastIndexOfSlash = route.lastIndexOf('/');
      const compareRoute =
        blockedRoute.isDynamicRoute && lastIndexOfSlash !== 0 // for the case of dynamic routes such as /reserve-pickup and their subroutes
          ? route.substring(0, lastIndexOfSlash)
          : route;

      return blockedRoute.url === compareRoute;
    });

    return isAvailable ?? false;
  }
}
