import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { PcPipesModule } from '@pc-pipes';
import { ButtonModule } from '@pc-ui/button';
import { DialogModule } from '@pc-ui/dialog';
import { FeedbackModule } from '@pc-ui/feedback';
import { InputModule } from '@pc-ui/input';
import { SelectModule } from '@pc-ui/select';
import { SalesReportDialogComponent } from './sales-report-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    ButtonModule,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    InputModule,
    PcPipesModule,
    SelectModule,
    FeedbackModule,
  ],
  declarations: [SalesReportDialogComponent],
})
export class SalesReportDialogModule {}
