import firebase from 'firebase/compat/app';

export enum PC_COLLECTIONS {
  ANNOUNCEMENTS = 'cockpit-announcements',
  BOOKINGS = 'bookings',
  COCKPIT_PROMOTIONS = 'cockpit-promotions',
  COCKPIT_PROMOTIONS_FEEDBACK = 'feedback',
  COCKPIT_PROMOTIONS_ACTIONS = 'actions',
  COCKPIT_USER_PROFILES = 'cockpit-user-profiles',
  COMPETITION_PARTICIPANTS = 'participants',
  COMPETITIONS = 'competitions',
  CONTRACTS = 'contracts',
  EVENTS = 'events',
  HOLIDAYS = 'holidays',
  JOBS = 'jobs',
  KIDS_CHECKINS = 'kids-checkins',
  LOG_EMAIL = 'log-email',
  NEWS = 'news',
  NEWS_STATUS = 'cockpit-news-status',
  NOTIFICATIONS = 'notifications',
  OFFERS = 'offers',
  REPORTS = 'report-sales',
  SETTINGS = 'settings',
  SHOP_CATEGORIES = 'shop-categories',
  SHOP_COMPETITIONS = 'cockpit-competitions',
  SHOP_EVENTS = 'cockpit-events',
  SHOP_JOBS = 'cockpit-jobs',
  SHOP_NEWS = 'cockpit-news',
  SHOP_OFFERS = 'cockpit-offers',
  SHOP_OPENING_HOURS_REQUESTS = 'opening-hours-requests',
  SHOPS = 'shops',
  TAGS = 'tags',
  USER_COCKPIT_SESSIONS = 'cockpit-sessions',
  USER_CREATE_REQUESTS = 'user-create-requests',
  USER_PERMISSIONS = 'user-permissions',
  USER_PROFILES = 'user-profiles',
  VISITORS_FORECAST = 'visitors-forecast',
  TICKETING = 'cockpit-ticketing',
  TICKETING_CATEGORIES = 'cockpit-ticketing-categories',
  TICKETING_LOCATIONS = 'cockpit-ticketing-locations',
}

export enum PC_FIREBASE_HTTP_FUNCTIONS {
  DYNAMIC_LINKS_GENERATE = 'https://us-central1-metacity-369e6.cloudfunctions.net/httpsDynamiclinksGenerate',
  BOOKING_CANCEL = 'https://us-central1-metacity-369e6.cloudfunctions.net/httpsBookingCancel',
  ACCOUNT_REQUEST = 'https://us-central1-metacity-369e6.cloudfunctions.net/httpsCockpitUserRequest',
  ACTIONS_LOG = 'https://us-central1-metacity-369e6.cloudfunctions.net/httpsActionsLog',
  INSIGHTS_GET = 'https://us-central1-metacity-369e6.cloudfunctions.net/httpsInsightsGet',
  COCKPIT_USER_PERMISSIONS = 'https://us-central1-metacity-369e6.cloudfunctions.net/httpsCockpitUserAssociate',
}

export const PC_FIREBASE_API_KEY = 'yu9FT1v4omNKU6d67gP5';

export type PcEnv = 'dev' | 'prod';
export type PcSentryEnv = 'prod' | 'dev' | 'next' | 'local';

export type PcImageFirebase = {
  url?: string;
  fileName?: string;
};

export type PcImageFirebaseOld = {
  bucket?: string;
  fullPath?: string;
  url?: string;
};

export type PcTsFirebase = firebase.firestore.Timestamp;
export type PcTsSeconds = number & { _brand: 'timestamp_seconds' };
export type PcTsMilliSeconds = number & {
  _brand: 'timestamp_milliseconds';
};

export type PcGeoPointFirebase = firebase.firestore.GeoPoint;

export type PcMapFirebase<T extends string = string> = { [key in T]?: boolean };

/*** MODELS ***/

export type PcErrorFirebase = {
  code: string;
  message: string;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export type Nullable<T> = T | null;

export type PcEditMode = 'edit' | 'create';
