<div>
  <pc-label *ngIf="label" class="text-left" [ngClass]="{ 'opacity-50': isDisabled }" [isRequired]="isRequired" [htmlId]="htmlId">{{ label }}</pc-label>
  <div class="relative">
    <input class="form-input" [type]="getInputType()" [placeholder]="placeholder" [id]="htmlId" [autocomplete]="autocomplete" [ngModel]="internalValue" (ngModelChange)="onChange($event, true)" [ngClass]="getClasses()" [maxlength]="maxlength ?? ''" [readonly]="isReadonly ? 'readonly' : ''" [disabled]="isDisabled ? 'disabled' : 'false'" (blur)="onBlur(input.value)" #input />

    <button (click)="togglePasswordVisibility()" buttonClass="absolute inset-y-0 right-0 pr-4 pl-2" type="button" *ngIf="inputType === 'password'" pc-button variant="link" [color]="theme === 'dark' ? 'white' : 'gray'">
      <pc-icon [icon]="shouldShowPassword ? 'visibility-off' : 'visibility-on'"></pc-icon>
    </button>
    <div class="absolute inset-y-0 right-0 flex items-center">
      <ng-content></ng-content>
    </div>
  </div>
  <div class="mt-2" *ngIf="shouldShowErrors()">
    <pc-error [errors]="getErrors()"></pc-error>
  </div>
</div>
