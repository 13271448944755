import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { convertFirebaseImageToString } from '@pc-helpers';
import { PcImageFirebase } from '@pc-types';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'pcImage',
})
export class PcImagePipe implements PipeTransform {
  constructor() {}

  public transform(image: PcImageFirebase | undefined): string | undefined {
    return convertFirebaseImageToString(image);
  }
}
