import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '@pc-ui/button';
import { CheckboxGroupModule } from '@pc-ui/checkbox-group';
import { DialogModule } from '@pc-ui/dialog';
import { InputModule } from '@pc-ui/input';
import { MessageModule } from '@pc-ui/message';
import { RadioGroupModule } from '@pc-ui/radio-group';
import { ShopSelectModule } from '@pc-ui/shop-select';
import { SpinnerModule } from '@pc-ui/spinner';
import { UserSelectModule } from '@pc-ui/user-select';
import { UserManagementCreateDialogComponent } from './user-management-create-dialog.component';

@NgModule({
  declarations: [UserManagementCreateDialogComponent],
  imports: [
    CommonModule,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    UserSelectModule,
    InputModule,
    ShopSelectModule,
    RadioGroupModule,
    CheckboxGroupModule,
    MessageModule,
    SpinnerModule,
  ],
  exports: [UserManagementCreateDialogComponent],
})
export class UserManagementCreateDialogModule {}
