import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { ButtonModule } from '@pc-ui/button';
import { DialogModule } from '@pc-ui/dialog';
import { ErrorModule } from '@pc-ui/error/error.module';
import { TextareaModule } from '@pc-ui/textarea';
import { BookingCancelDialogComponent } from './booking-cancel-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    MatDialogModule,
    TextareaModule,
    ReactiveFormsModule,
    FormsModule,
    ErrorModule,
    DialogModule,
  ],
  declarations: [BookingCancelDialogComponent],
})
export class BookingCancelDialogModule {}
