import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { ButtonModule } from '@pc-ui/button';
import { ClipboardCopyModule } from '@pc-ui/clipboard-copy';
import { DialogModule } from '@pc-ui/dialog';
import { SpinnerModule } from '@pc-ui/spinner';
import { QrCodeModule } from 'ng-qrcode';
import { DynamiclinkDialogComponent } from './dynamiclink-dialog.component';

@NgModule({
  declarations: [DynamiclinkDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    QrCodeModule,
    ClipboardCopyModule,
    ButtonModule,
    SpinnerModule,
    DialogModule,
  ],
  exports: [DynamiclinkDialogComponent],
})
export class DynamiclinkDialogModule {}
