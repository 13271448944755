import { PcItemPermission } from './permission.type';

export type PcNavigationItem = {
  action?: string;
  activeUrl?: string;
  children?: PcNavigationItem[];
  name: string;
  pathFn?: () => string[];
  queryParams?: { [key: string]: any };
  permission?: PcItemPermission;
};

export const PC_RESERVE_PICKUP_FALLBACK_SLUG = 'first-reserve-pickup-offer';
