import { PcItemPermission } from '../types/permission.type';
import { PcShop } from '../types/shop.type';
import { PcGlobalPermissions, PcPermissions } from '../types/user.type';
import { isCategoryTypeInShop, isGastroModuleEnabled } from './shop.helper';

export const PC_KINDERLAND_SHOP_ID = 'FUFwCRRepTq4cbbTuTC9';

export function isItemVisibleByPermissions(
  itemPermission: PcItemPermission | undefined,
  permissions: PcPermissions,
  myShop: PcShop | null | undefined,
  globalPermissions: PcGlobalPermissions | undefined
): boolean {
  if (!itemPermission) {
    return true;
  }

  const modulePermissionRole = itemPermission.module
    ? permissions[itemPermission.module]
    : undefined;

  const isModuleOk = itemPermission.module
    ? !!permissions[itemPermission.module]
    : true;

  const isRoleOk = itemPermission.role
    ? itemPermission.module
      ? itemPermission.role === modulePermissionRole
      : itemPermission.role === globalPermissions?.role
    : true;

  const isShopShop = myShop ? isCategoryTypeInShop('shops', myShop) : false;

  const isShopShopOk =
    typeof itemPermission.isShopShop !== 'undefined'
      ? itemPermission.isShopShop === isShopShop
      : true;

  const isGastroBookingEnabled = myShop ? isGastroModuleEnabled(myShop) : false;

  const isGastroBookingEnabledOk =
    typeof itemPermission.isGastroBookingEnabled !== 'undefined'
      ? itemPermission.isGastroBookingEnabled === isGastroBookingEnabled
      : true;

  return isModuleOk && isRoleOk && isShopShopOk && isGastroBookingEnabledOk;
}
