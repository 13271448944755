import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardPromotionMiniComponent } from './card-promotion-mini.component';
import { IconModule } from '@pc-ui/icon';
import { PcPipesModule } from '@pc-pipes';
import { ButtonModule } from '@pc-ui/button';
import { ModelPropListItemModule } from '@pc-ui/model-prop-list-item';

@NgModule({
  declarations: [CardPromotionMiniComponent],
  imports: [
    CommonModule,
    IconModule,
    PcPipesModule,
    ButtonModule,
    ModelPropListItemModule,
  ],
  exports: [CardPromotionMiniComponent],
})
export class CardPromotionMiniModule {}
