import {
  PC_DATE_FORMAT,
  PcDateRangeItem,
  PcMonthKey,
  PcOpeningHours,
  PcOpeningHoursFirebase,
  PcOpeningHoursWeekFirebase,
  PcOpeningHoursWeekForm,
  PcSelectOption,
  PcTsFirebase,
  PcTsMilliSeconds,
  PcTsSeconds,
  PcWeekDay,
  PcWeekDayFirebaseKey,
} from '@pc-types';
import {
  addDays,
  differenceInDays,
  differenceInSeconds,
  format,
  getHours,
  getMinutes,
  isFuture,
  isSameDay,
  isSameMinute,
  isSameSecond,
  setHours,
  setMinutes,
  setMonth,
  startOfDay,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import { de } from 'date-fns/locale';
import firebase from 'firebase/compat/app';
import { clone, first, last, uniq } from 'lodash-es';
import { RRule } from 'rrule';

export function convertDateToTsSeconds(value: Date): PcTsSeconds {
  return Math.round(value.getTime() / 1000) as PcTsSeconds;
}

export function convertDateToTsMilliSeconds(value: Date): PcTsMilliSeconds {
  return convertTsSecondsToTsMilliSeconds(convertDateToTsSeconds(value));
}

export function convertTsSecondsToDate(value: PcTsSeconds): Date {
  return new Date(convertTsSecondsToTsMilliSeconds(value) as number);
}

export function convertTsMilliSecondsToDate(value: PcTsMilliSeconds): Date {
  return convertTsSecondsToDate(convertTsMilliSecondsToTsSeconds(value));
}

export function convertTsMilliSecondsToTsSeconds(
  value: PcTsMilliSeconds
): PcTsSeconds {
  return ((value as number) / 1000) as PcTsSeconds;
}
export function convertTsSecondsToTsMilliSeconds(
  value: PcTsSeconds
): PcTsMilliSeconds {
  return ((value as number) * 1000) as PcTsMilliSeconds;
}

export function convertRangeDateToTsSeconds(
  values: PcDateRangeItem<Date>[] | undefined
): PcDateRangeItem<PcTsSeconds>[] {
  const numbers: PcDateRangeItem<PcTsSeconds>[] = [];

  values?.forEach((value) => {
    const start = convertDateToTsSeconds(value.start);
    const end = convertDateToTsSeconds(value.end);
    if (start && end) {
      numbers.push({
        start,
        end,
      });
    }
  });

  return numbers;
}

export function convertRangeSecondsToDate(
  values: PcDateRangeItem<PcTsSeconds>[]
): PcDateRangeItem<Date>[] {
  const numbers: PcDateRangeItem<Date>[] = [];

  values?.forEach((value) => {
    const start = convertTsSecondsToDate(value.start);
    const end = convertTsSecondsToDate(value.end);
    if (start && end) {
      numbers.push({
        start,
        end,
      });
    }
  });

  return numbers;
}

export function convertDateToTsFirebase(input: Date): PcTsFirebase {
  return firebase.firestore.Timestamp.fromDate(input);
}

export function convertSecondsToDate(seconds: number): Date | undefined {
  if (seconds === -1) {
    return undefined;
  }
  let minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  minutes = minutes - hours * 60;
  return setMinutes(setHours(startOfYear(new Date()), hours), minutes);
}

export function convertDateToSeconds(date: Date): number {
  return differenceInSeconds(date, startOfDay(date));
}

export function isStartOfDay(date: Date): boolean {
  return isSameSecond(date, startOfDay(date));
}

export function convertSecondsToTimeString(seconds: number): string {
  const date = convertSecondsToDate(seconds);
  if (!date) {
    return '';
  }
  return `${getHours(date).toString().padStart(2, '0')}:${getMinutes(date)
    .toString()
    .padStart(2, '0')}`;
}

export function convertOpeningDayToOpeningDayFirebase(
  item: PcOpeningHours
): PcOpeningHoursFirebase {
  return {
    from: convertDateToSeconds(item.from),
    to: convertDateToSeconds(item.to),
  };
}

export function convertOpeningWeekToOpeningWeekFirebase(
  item: PcOpeningHoursWeekForm
): PcOpeningHoursWeekFirebase {
  return {
    mon: convertOpeningDayToOpeningDayFirebase(item.mon),
    tue: convertOpeningDayToOpeningDayFirebase(item.tue),
    wed: convertOpeningDayToOpeningDayFirebase(item.wed),
    thu: convertOpeningDayToOpeningDayFirebase(item.thu),
    fri: convertOpeningDayToOpeningDayFirebase(item.fri),
    sat: convertOpeningDayToOpeningDayFirebase(item.sat),
    sun: convertOpeningDayToOpeningDayFirebase(item.sun),
    hol: item.hol
      ? convertOpeningDayToOpeningDayFirebase(item.hol)
      : { from: 0, to: 0 },
  };
}

export function sortDateRanges(
  items: PcDateRangeItem<Date>[]
): PcDateRangeItem<Date>[] {
  return items.sort((a, b) => {
    let comparison = 0;
    if (a.start > b.start) {
      comparison = 1;
    } else if (a.start < b.start) {
      comparison = -1;
    }
    return comparison;
  });
}

export function getNextDateRange(
  items: PcDateRangeItem<Date>[]
): PcDateRangeItem<Date> | undefined {
  const sortedItems = sortDateRanges(items);
  const futureItems = sortedItems.filter((item) => isFuture(item.end));
  return futureItems.length ? first(futureItems) : last(sortedItems);
}

export function getDateTimeRangeFormatted(start: Date, end: Date): string {
  let dateStr = getDateRangeFormatted(start, end);
  if (getTimeRangeFormatted(start, end)) {
    if (!isSameDay(start, end)) {
      dateStr += `<br>`;
    }
    dateStr += ` ${getTimeRangeFormatted(start, end)}`;
  }
  return dateStr;
}

export function getDateRangeFormatted(start: Date, end: Date): string {
  let dateStr = formatDate(start, PC_DATE_FORMAT.WEEKDAYDATE);

  if (!isSameDay(start, end)) {
    dateStr += ` - ${formatDate(end, PC_DATE_FORMAT.WEEKDAYDATE)}`;
  }

  return dateStr;
}

export function getTimeRangeFormatted(start: Date, end: Date): string | null {
  if (getHours(start) === 0 && getMinutes(start) === 0) {
    return null;
  }

  if (!isSameMinute(start, end)) {
    return `von ${formatDate(start, PC_DATE_FORMAT.TIME)} bis ${formatDate(
      end,
      PC_DATE_FORMAT.TIME
    )} Uhr`;
  } else {
    return `um ${formatDate(start, PC_DATE_FORMAT.TIME)} Uhr`;
  }
}

export function formatDate(date: Date, formatString: string): string {
  return hasMMM(formatString)
    ? convertOnorm(format(date, formatString, { locale: de }))
    : format(date, formatString, { locale: de });
}

function hasMMM(input: string): boolean {
  const match = input.match(/^[^\M]*\M\M\M[^\M]*$/);
  return !!match && match.length > 0;
}

function convertOnorm(input: string): string {
  return input.replace('Feb', 'Febr').replace('Sep', 'Sept');
}

export function dateFallback(date: Date | undefined): Date {
  return date ?? new Date();
}

export function convertDateToUTCDate(d: Date): Date {
  return new Date(
    Date.UTC(
      d.getFullYear(),
      d.getMonth(),
      d.getDate(),
      d.getHours(),
      d.getMinutes(),
      d.getSeconds()
    )
  );
}

export function getWeekDays(): PcWeekDay[] {
  return Array.from(Array(7)).map((e, i) => {
    return {
      label: getWeekDayLabel(i),
      key: i,
      keyFirebase: getKeyFirebase(i),
    };
  });
}

export function getWeekDayLabel(i: number): string {
  if (i < 0 || i > 6) {
    return '';
  }
  const firstDOW = startOfWeek(new Date(), { locale: de });
  return formatDate(addDays(firstDOW, i), PC_DATE_FORMAT.WEEKDAY_LONG);
}

export function getKeyFirebase(i: number): PcWeekDayFirebaseKey | undefined {
  switch (i) {
    case 0:
      return 'mon';
    case 1:
      return 'tue';
    case 2:
      return 'wed';
    case 3:
      return 'thu';
    case 4:
      return 'fri';
    case 5:
      return 'sat';
    case 6:
      return 'sun';
  }
  return undefined;
}

export function getRecurrencyLabel(rrule: RRule): string {
  return `${rrule.options.interval > 1 ? rrule.options.interval + '-' : ''}${
    rrule.options.freq === RRule.WEEKLY ? 'wöchentlich' : 'monatlich'
  } am ${
    rrule.options.freq === RRule.WEEKLY
      ? getWeekDayLabel(rrule.options.byweekday[0])
      : rrule.options.bymonthday[0] + '.'
  }`;
}

export function getConsecutiveDates(
  dates: PcDateRangeItem<Date>[]
): PcDateRangeItem<Date>[] {
  const consecuviteDates: PcDateRangeItem<Date>[] = [];

  for (const [index, date] of dates.entries()) {
    const currentDate = clone(date.start);
    const nextIndex = index + 1;
    const nextDateStart = dates[nextIndex]
      ? clone(dates[nextIndex].start)
      : null;

    const isNextDateConsecutive =
      nextDateStart &&
      Math.abs(
        differenceInDays(
          currentDate.setHours(0, 0, 0, 0),
          nextDateStart.setHours(0, 0, 0, 0)
        )
      ) === 1;

    if (isNextDateConsecutive) {
      // push the current date
      consecuviteDates.push(date);
      // push the next (compared) date
      const nextDate = dates[nextIndex];
      consecuviteDates.push(nextDate);
    } else {
      break;
    }
  }

  return uniq(consecuviteDates);
}

export function getMonths(): PcSelectOption<PcMonthKey>[] {
  const months: PcSelectOption<PcMonthKey>[] = [];
  for (let i: PcMonthKey = 0; i < 12; i++) {
    months.push({
      key: i as PcMonthKey,
      label: formatDate(setMonth(new Date(), i), PC_DATE_FORMAT.MONTH),
    });
  }

  return months;
}
