import { ElementRef } from '@angular/core';

export function getContainerMinHeight(
  outletContainerHeight: number,
  actionBar: ElementRef<HTMLElement> | undefined,
  container: ElementRef<HTMLElement> | undefined
): number {
  if (!outletContainerHeight || !actionBar || !container) {
    return 0;
  }

  const shopSettingsContainerHeight =
    getOuterHeight(container.nativeElement) +
    actionBar.nativeElement.offsetHeight;
  const missingSpaceBetweenContainer =
    outletContainerHeight - shopSettingsContainerHeight;

  return container.nativeElement.offsetHeight + missingSpaceBetweenContainer;
}

function getOuterHeight(element: HTMLElement | undefined): number {
  if (!element) {
    return 0;
  }

  const propertyList = [
    'margin-top',
    'margin-bottom',
    'border-top',
    'border-bottom',
    'padding-top',
    'padding-bottom',
    'height',
  ];

  const style = window.getComputedStyle(element);
  return propertyList
    .map((property) => parseInt(style.getPropertyValue(property), 10))
    .reduce((prevProp, curProp) => prevProp + curProp);
}
