import {
  PcFirestoreConverterWithoutUid,
  PcGlobalPermissions,
  PcModule,
  PcPermissionRole,
  PcPermissionsFirebase,
} from '@pc-types';
import firebase from 'firebase/compat/app';
import { first } from 'lodash-es';

export const userPermissionConverter: PcFirestoreConverterWithoutUid<
  PcPermissionsFirebase,
  PcGlobalPermissions
> = {
  fromFirestore: (input) => {
    const keys = input.cockpit ? Object.keys(input.cockpit) : [];

    const shopId = first(keys);
    if (!shopId) {
      return;
    }

    const modulePermissions = input.cockpit[shopId];

    // get keys of modulePermissions where value is not undefined
    const modules = Object.keys(modulePermissions).filter((key) => {
      const k = key as PcModule;
      return !!modulePermissions[k];
    }) as PcModule[];

    const role: PcPermissionRole = shopId === 'all' ? 'manager' : 'tenant';
    return role === 'manager' ? { role, modules } : { role, shopId, modules };
  },
  toFirestore: (input) => {
    if (!input) {
      return undefined;
    }

    const { role, modules } = input;
    const shopId = role === 'manager' ? 'all' : input.shopId;

    return {
      cockpit: {
        [shopId]: {
          ...modules.reduce(
            (acc, module) => ({ ...acc, [module as PcModule]: role }),
            {}
          ),
        },
      },
      modified: firebase.firestore.Timestamp.now(),
    };
  },
};
