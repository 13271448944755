import { DocumentReference } from '@angular/fire/compat/firestore';
import {
  PcDataFilterGeneral,
  PcOfferFirebase,
  PcShopFirebase,
  PcTsFirebase,
  PcUserFirebase,
} from '@pc-types';
import { PcGastroDefinition } from 'src/app/cockpit/modules/gastro/gastro.definition';
import { PcReservePickupDefinition } from 'src/app/cockpit/modules/reserve-pickup/reserve-pickup.definition';

type PcBookingPlatform = 'app' | 'website' | 'shop' | 'function';
export type PcBookingStatus =
  | 'processing'
  | 'canceled'
  | 'confirmed'
  | 'completed'
  | 'missing';

export type PcBookingNotValidReason =
  | 'canceled'
  | 'completed'
  | 'tooearly'
  | 'toolate'
  | 'notfound';

type PcBookingGeneric<DateType> = {
  readonly number: string;
  created: DateType;
  modified: DateType;
  startDate: DateType;
  endDate: DateType;
  bookingDate: DateType;
  salutation?: 'male' | 'female' | 'company' | 'other';
  firstName: string;
  lastName: string;
  persons: number;
  duration: number;
  comment?: string;
  smsConfirmation: boolean;
  emailConfirmation: boolean;
  phone?: string;
  email?: string;
  readonly confirmed?: DateType;
  statusText: PcBookingStatus;
  sourcePlatform: PcBookingPlatform;
  statusSource: PcBookingPlatform;
  offer?: DocumentReference<PcOfferFirebase>;
  shop: DocumentReference<PcShopFirebase>;
  userProfile?: DocumentReference<PcUserFirebase>;
};

export type PcBooking = PcBookingGeneric<Date> & { uid: string };
export type PcBookingFirebase = PcBookingGeneric<PcTsFirebase>;

export type PcBookingColumns = keyof PcBooking;

export type PcBookingCancelRequest = {
  shopID: string;
  bookingID: string;
  source: 'website';
  message: string;
};
export type PcBookingCancelResponse = {
  result: string;
};

export const PC_BOOKING_SYSTEM_URL = 'https://booking.pluscity.at/#/';

export type PcDataFilterReservePickup =
  PcDataFilterGeneral<PcReservePickupDefinition> & {
    offerId?: string;
  };
export type PcDataFilterGastro = PcDataFilterGeneral<PcGastroDefinition>;
