import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { PcIcon } from '@pc-types';

@Component({
  selector: 'pc-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnInit {
  @Input() icon?: PcIcon;
  @Input() size = 4;

  constructor() {}

  ngOnInit(): void {}

  public getIconSizeClasses(): string[] {
    const classes = [];

    switch (this.size) {
      case 3:
        classes.push('w-3', 'h-3');
        break;
      case 4:
        classes.push('w-4', 'h-4');
        break;
      case 5:
        classes.push('w-5', 'h-5');
        break;
      case 6:
        classes.push('w-6', 'h-6');
        break;
      case 8:
        classes.push('w-8', 'h-8');
        break;
      case 10:
        classes.push('w-10', 'h-10');
        break;
      case 16:
        classes.push('w-16', 'h-16');
        break;
      case 20:
        classes.push('w-20', 'h-20');
        break;
      case 32:
        classes.push('w-32', 'h-32');
        break;
    }

    return classes;
  }
}
