import { convertDateToTsFirebase, dateFallback } from '@pc-helpers';
import {
  PcFirestoreConverter,
  PcKidsCheckin,
  PcKidsCheckinFirebase,
} from '@pc-types';
import firebase from 'firebase/compat/app';
export const kidsConverter: PcFirestoreConverter<
  PcKidsCheckinFirebase,
  PcKidsCheckin
> = {
  fromFirestore: (input) => {
    if (!input || !input.created || !input.modified) {
      return undefined;
    }
    try {
      return {
        uid: input.uid,
        created: input.created.toDate(),
        modified: input.modified.toDate(),
        status: input.status ?? 'draft',
        env: input.env ?? 'dev',
        number: input.number,
        firstname: input.firstname ?? '',
        lastname: input.lastname ?? '',
        childrenTotal: input.childrenTotal ?? 0,
        userRef: input.userRef,
        phone: input.phone ?? '',
        arrival: input.arrival?.toDate() ?? new Date(),
        departure: input.departure?.toDate() ?? new Date(),
        contacted: input.contacted ?? null,
        date: input.date?.toDate() ?? new Date(),
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
  toFirestore: (input) => {
    if (!input) {
      return undefined;
    }
    try {
      return {
        created: firebase.firestore.Timestamp.now(),
        modified: firebase.firestore.Timestamp.now(),
        status: input.status ?? 'draft',
        env: input.env ?? 'dev',
        number: input.number,
        firstname: input.firstname ?? '',
        lastname: input.lastname ?? '',
        childrenTotal: input.childrenTotal ?? 0,
        userRef: input.userRef,
        phone: input.phone ?? '',
        arrival: convertDateToTsFirebase(dateFallback(input.arrival)),
        departure: convertDateToTsFirebase(dateFallback(input.departure)),
        contacted: input.contacted ?? null,
        date: convertDateToTsFirebase(dateFallback(input.date)),
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
};
