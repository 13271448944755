import { Injectable, NgZone } from '@angular/core';
import { AngularFirestore, Query } from '@angular/fire/compat/firestore';
import { announcementConverter } from '@pc-converter';
import { StoreService } from '@pc-services';
import {
  PcAnnouncement,
  PcAnnouncementFirebase,
  PcAnnouncemnentIndexChange,
  PcCockpitUser,
  PcEnv,
  PcStatusSimple,
  PC_COLLECTIONS,
} from '@pc-types';
import { compact, keys, pick } from 'lodash-es';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { EnvironmentService } from '../environment/environment.service';

@Injectable({
  providedIn: 'root',
})
export class AnnouncementService {
  private announcementTableHighlight = new Subject<
    PcAnnouncement | undefined
  >();

  public announcementTableHighlight$ =
    this.announcementTableHighlight.asObservable();

  constructor(
    private angularFirestore: AngularFirestore,
    private store: StoreService,
    private envService: EnvironmentService,
    private zone: NgZone
  ) {}

  public setAnnouncementTableHighlight(
    announcement: PcAnnouncement | undefined
  ): void {
    this.zone.run(() => {
      this.announcementTableHighlight.next(announcement);
    });
  }

  public fetchAll(): void {
    this.angularFirestore
      .collection<PcAnnouncementFirebase>(
        PC_COLLECTIONS.ANNOUNCEMENTS,
        (ref) => {
          let query: Query = ref;
          const env: PcEnv = this.envService.getFirebaseEnv();
          const status: PcStatusSimple = 'deleted';

          query = query.where('env', '==', env).where('status', '!=', status);

          return query;
        }
      )
      .valueChanges({ idField: 'uid' })
      .pipe(
        map((announcements) => {
          if (!announcements) {
            return undefined;
          }
          return announcements.map(announcementConverter.fromFirestore);
        }),
        distinctUntilChanged()
      )
      .subscribe((announcements) => {
        this.store.setAnnouncements(compact(announcements));
      });
  }

  public async update(
    announcementId: string,
    item: Partial<PcAnnouncement>
  ): Promise<void> {
    item.modified = new Date();
    const itemForFirebase = pick(
      announcementConverter.toFirestore(item),
      keys(item)
    );

    return this.angularFirestore
      .collection<PcAnnouncementFirebase>(PC_COLLECTIONS.ANNOUNCEMENTS)
      .doc(announcementId)
      .update(itemForFirebase);
  }

  public async create(item: Partial<PcAnnouncement>): Promise<void> {
    if (!item) {
      console.warn('item missing');
      return Promise.resolve();
    }

    const myUser = await this.store.myUser();
    if (!myUser) {
      return Promise.resolve();
    }

    item.env = this.envService.getFirebaseEnv();
    item.enabled = false;
    item.created = new Date();
    item.modified = new Date();
    item.status = 'published';
    item.author = this.angularFirestore.doc<PcCockpitUser>(
      `${PC_COLLECTIONS.COCKPIT_USER_PROFILES}/${myUser.uid}`
    ).ref;

    const itemForFirebase = announcementConverter.toFirestore(item);
    if (!itemForFirebase) {
      return Promise.resolve();
    }

    const ref = this.angularFirestore.collection<PcAnnouncementFirebase>(
      PC_COLLECTIONS.ANNOUNCEMENTS
    );

    await ref.add(itemForFirebase);
  }

  public delete(announcementId: string): Promise<void> {
    const item: Partial<PcAnnouncement> = {};
    item.status = 'deleted';
    item.modified = new Date();

    const itemForFirebase = pick(
      announcementConverter.toFirestore(item),
      keys(item)
    );

    return this.angularFirestore
      .collection<PcAnnouncementFirebase>(PC_COLLECTIONS.ANNOUNCEMENTS)
      .doc(announcementId)
      .update(itemForFirebase);
  }

  public setEnabled(
    announcementId: string,
    enabled: boolean,
    index: PcAnnouncement['index']
  ): Promise<void> {
    const item: Partial<PcAnnouncement> = {
      index,
      enabled,
      modified: new Date(),
    };

    const itemForFirebase = pick(
      announcementConverter.toFirestore(item),
      keys(item)
    );

    return this.angularFirestore
      .collection<PcAnnouncementFirebase>(PC_COLLECTIONS.ANNOUNCEMENTS)
      .doc(announcementId)
      .update(itemForFirebase);
  }

  public setIndexes(
    announcementIndexChanges: PcAnnouncemnentIndexChange[]
  ): Promise<void> {
    const batch = this.angularFirestore.firestore.batch();

    announcementIndexChanges.forEach((announcementIndexChange) => {
      const doc = this.angularFirestore
        .collection<PcAnnouncementFirebase>(PC_COLLECTIONS.ANNOUNCEMENTS)
        .doc(announcementIndexChange.uid).ref;

      const item: Partial<PcAnnouncement> = {};
      item.index = announcementIndexChange.index;
      item.modified = new Date();

      const itemForFirebase = pick(
        announcementConverter.toFirestore(item),
        keys(item)
      );

      batch.update(doc, itemForFirebase);
    });

    return batch.commit();
  }
}
