import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { convertDateToTsFirebase } from '@pc-helpers';
import { PcShopFirebase, PC_COLLECTIONS } from '@pc-types';
import { formatISO, startOfToday } from 'date-fns';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TrackingFirebaseService {
  constructor(private angularFirestore: AngularFirestore) {}

  public async trackSession(userId: string, shopId?: string): Promise<void> {
    const date = startOfToday();
    const key = formatISO(date, { representation: 'date' });
    const doc = this.angularFirestore
      .collection(PC_COLLECTIONS.USER_PROFILES)
      .doc(userId)
      .collection(PC_COLLECTIONS.USER_COCKPIT_SESSIONS)
      .doc(key);

    const docGet = await firstValueFrom(doc.get());

    if (!docGet.exists) {
      doc.set({
        date: convertDateToTsFirebase(date),
        userId,
        shopRef: shopId
          ? this.angularFirestore.doc<PcShopFirebase>(
              `${PC_COLLECTIONS.SHOPS}/${shopId}`
            ).ref
          : null,
      });
    }
  }
}
