import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { promotionFeedbackConverter } from '@pc-converter';
import { StoreService } from '@pc-services';
import {
  PcCockpitUser,
  PcPromotion,
  PcPromotionFeedback,
  PcPromotionFeedbackFirebase,
  PcShopFirebase,
  PC_COLLECTIONS,
} from '@pc-types';
import { compact } from 'lodash-es';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PromotionFeedbackService {
  constructor(
    private angularFirestore: AngularFirestore,
    private store: StoreService
  ) {}

  public fetchByPromotion(
    promotion: PcPromotion
  ): Observable<PcPromotionFeedback[]> {
    return this.angularFirestore
      .collection<PcPromotionFeedbackFirebase>(
        `${PC_COLLECTIONS.COCKPIT_PROMOTIONS}/${promotion.uid}/${PC_COLLECTIONS.COCKPIT_PROMOTIONS_FEEDBACK}`
      )
      .valueChanges({ idField: 'uid' })
      .pipe(
        map((feedbacks) => {
          return compact(
            feedbacks
              .map((feedback) => {
                return promotionFeedbackConverter.fromFirestore(feedback);
              })
              .filter((item) => !!item)
          );
        }),
        distinctUntilChanged()
      );
  }

  public fetchByPromotionAndUserId(
    promotion: PcPromotion,
    userId: string
  ): Observable<PcPromotionFeedback | undefined> {
    return this.angularFirestore
      .collection<PcPromotionFeedbackFirebase>(
        `${PC_COLLECTIONS.COCKPIT_PROMOTIONS}/${promotion.uid}/${PC_COLLECTIONS.COCKPIT_PROMOTIONS_FEEDBACK}`
      )
      .doc(userId)
      .valueChanges({ idField: 'uid' })
      .pipe(
        map((feedback) => {
          if (!feedback?.text) {
            return undefined;
          }
          return promotionFeedbackConverter.fromFirestore(feedback);
        }),
        distinctUntilChanged()
      );
  }

  public async add(
    text: string,
    promotion: PcPromotion,
    userId: string,
    shopId: string
  ): Promise<void> {
    const userRef = this.angularFirestore.doc<PcCockpitUser>(
      `${PC_COLLECTIONS.COCKPIT_USER_PROFILES}/${userId}`
    ).ref;

    const shopRef = this.angularFirestore.doc<PcShopFirebase>(
      `${PC_COLLECTIONS.SHOPS}/${shopId}`
    ).ref;

    const newFeedback: Partial<PcPromotionFeedback> = {
      userId: userRef,
      shopId: shopRef,
      text,
      created: new Date(),
      modified: new Date(),
    };

    const ref = this.angularFirestore.collection<PcPromotionFeedbackFirebase>(
      `${PC_COLLECTIONS.COCKPIT_PROMOTIONS}/${promotion.uid}/${PC_COLLECTIONS.COCKPIT_PROMOTIONS_FEEDBACK}`
    );

    await ref.doc(userId).set({
      ...(promotionFeedbackConverter.toFirestore(
        newFeedback
      ) as PcPromotionFeedbackFirebase),
    });
  }
}
