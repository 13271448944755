import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AutocompleteModule } from '@pc-ui/autocomplete';
import { ErrorModule } from '@pc-ui/error';
import { IconModule } from '@pc-ui/icon';
import { LabelModule } from '@pc-ui/label';
import { UserSelectComponent } from './user-select.component';

@NgModule({
  declarations: [UserSelectComponent],
  imports: [
    CommonModule,
    LabelModule,
    AutocompleteModule,
    IconModule,
    ErrorModule,
  ],
  exports: [UserSelectComponent],
})
export class UserSelectModule {}
