import {
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
} from '@angular/forms';

export abstract class PcErrorable {
  protected abstract parentFormGroup?: UntypedFormGroup;
  protected abstract parentFormControlName?: string;

  constructor() {}

  protected getFormControl(): UntypedFormControl | undefined {
    if (!this.parentFormControlName) {
      return undefined;
    }
    return this.parentFormGroup?.get(
      this.parentFormControlName
    ) as UntypedFormControl;
  }

  protected isTouched(): boolean {
    return !!this.getFormControl()?.touched;
  }

  public getErrors(): ValidationErrors | undefined {
    const formControl = this.getFormControl();

    if (formControl?.errors) {
      return formControl?.errors;
    }

    return undefined;
  }

  public shouldShowErrors(): boolean {
    return this.isTouched() && !!this.getErrors();
  }
}
