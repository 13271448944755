import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { neverError } from '@pc-helpers';
import { PcShopServiceType } from '@pc-types';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'pcShopServiceTypeToLabel',
})
export class PcShopServicePipe implements PipeTransform {
  constructor() {}

  public transform(
    value: PcShopServiceType | undefined | null
  ): string | undefined {
    if (!value) {
      return undefined;
    }
    switch (value) {
      case 'clickcollect':
        return 'Click & Collect';
      case 'delivery':
        return 'Lieferservice';
      case 'takeaway':
        return 'Take Away';
      default:
        throw neverError('', value);
    }
  }
}
