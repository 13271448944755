import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'pc-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent implements OnInit {
  @Input() maxWidth?: 'xs' | 'md' | 'xl';

  constructor(private dialogRef: MatDialog) {}

  ngOnInit(): void {}

  public getContentClasses(): string[] {
    const classes = this.getContainerClasses();

    return classes;
  }

  public getFooterClasses(): string[] {
    const classes = this.getContainerClasses();

    classes.push('mt-2');

    return classes;
  }

  private getContainerClasses(): string[] {
    const classes: string[] = [];

    switch (this.maxWidth) {
      case 'xs':
        classes.push('max-w-xs');
        break;
      case 'md':
        classes.push('max-w-md');
        break;
      case 'xl':
        classes.push('max-w-xl');
        break;
    }

    classes.push('mx-auto');

    return classes;
  }

  public onCloseClick(): void {
    this.dialogRef.closeAll();
  }
}
