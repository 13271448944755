import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  DocumentReference,
} from '@angular/fire/compat/firestore';
import { jobConverter } from '@pc-converter';
import { getFirestorePathBasedOnPermissions } from '@pc-helpers';
import { EnvironmentService, StoreService } from '@pc-services';
import {
  PcApprovalStatus,
  PcCockpitUser,
  PcEnv,
  PcJob,
  PcJobFirebase,
  PcModelStatusResponse,
  PC_COLLECTIONS,
} from '@pc-types';
import { compact, keys, pick } from 'lodash-es';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class JobsService {
  private referenceReadPath = PC_COLLECTIONS.JOBS;
  private referenceWritePath?: string;

  constructor(
    private angularFirestore: AngularFirestore,
    private store: StoreService,
    private envService: EnvironmentService
  ) {}

  public setEnabled(jobId: string, enabled: boolean): Promise<void> {
    if (!this.referenceWritePath) {
      return Promise.resolve();
    }

    const item: Partial<PcJob> = {
      enabled,
      modified: new Date(),
    };

    const itemForFirebase = pick(jobConverter.toFirestore(item), keys(item));

    return this.angularFirestore
      .collection<Partial<PcJobFirebase>>(this.referenceWritePath)
      .doc(jobId)
      .set(itemForFirebase, { merge: true });
  }

  public delete(jobId: string): Promise<void> {
    if (!this.referenceWritePath) {
      return Promise.resolve();
    }

    const item: Partial<PcJob> = {};
    item.status = 'deleted';
    item.modified = new Date();

    const itemForFirebase = pick(jobConverter.toFirestore(item), keys(item));

    return this.angularFirestore
      .collection<Partial<PcJobFirebase>>(this.referenceWritePath)
      .doc(jobId)
      .set(itemForFirebase, { merge: true });
  }

  public fetchAll(): void {
    this.store.myGlobalPermissions$
      .pipe(
        switchMap((globalPermissions) => {
          this.referenceWritePath = getFirestorePathBasedOnPermissions(
            globalPermissions,
            PC_COLLECTIONS.SHOP_JOBS,
            PC_COLLECTIONS.JOBS
          );

          return this.angularFirestore
            .collection<PcJobFirebase>(this.referenceReadPath, (ref) => {
              const env: PcEnv = this.envService.getFirebaseEnv();
              const validStatus: PcApprovalStatus[] = [
                'published',
                'review',
                'draft',
                'rejected',
              ];
              let query = ref
                .where('env', '==', env)
                .where('status', 'in', validStatus);

              if (globalPermissions.role === 'tenant') {
                query = query.where(
                  'shops',
                  'array-contains',
                  globalPermissions.shopId
                );
              }

              return query;
            })

            .valueChanges({ idField: 'uid' });
        }),
        map((jobs) => compact(jobs.map(jobConverter.fromFirestore))),
        distinctUntilChanged()
      )
      .subscribe((jobs) => {
        this.store.setJobs(compact(jobs));
      });
  }

  public async update(jobId: string, item: Partial<PcJob>): Promise<void> {
    if (!this.referenceWritePath) {
      console.warn('referenceWritePath missing');
      return Promise.resolve();
    }

    item.modified = new Date();

    const itemForFirebase = pick(jobConverter.toFirestore(item), keys(item));

    return this.angularFirestore
      .collection<Partial<PcJobFirebase>>(this.referenceWritePath)
      .doc(jobId)
      .set(itemForFirebase, { merge: true });
  }

  public async create(
    item: Partial<PcJob>
  ): Promise<DocumentReference<PcJobFirebase> | undefined> {
    if (!this.referenceWritePath) {
      console.warn('referenceWritePath missing');
      return;
    }

    if (!item) {
      console.warn('item missing');
      return;
    }

    item.env = this.envService.getFirebaseEnv();
    item.created = new Date();
    item.modified = new Date();

    item = await this.appendDataForCreation(item);

    const itemForFirebase = jobConverter.toFirestore(item);
    if (!itemForFirebase) {
      return;
    }

    return this.angularFirestore
      .collection<PcJobFirebase>(this.referenceWritePath)
      .add(itemForFirebase);
  }

  private async appendDataForCreation(
    item: Partial<PcJob>
  ): Promise<Partial<PcJob>> {
    const myUser = await this.store.myUser();

    if (myUser) {
      item.author = this.angularFirestore.doc<PcCockpitUser>(
        `${PC_COLLECTIONS.COCKPIT_USER_PROFILES}/${myUser.uid}`
      ).ref;
    }

    const myShopId = await this.store.myShopId();
    if (myShopId) {
      item.shops = [myShopId];
    }

    if (!item.status) {
      item.status = 'draft';
    }

    item.enabled = true;

    return item;
  }

  public setStatus(
    jobId: string,
    response: PcModelStatusResponse
  ): Promise<void> {
    if (!this.referenceWritePath) {
      return Promise.resolve();
    }

    const item: Partial<PcJob> = {
      status: response.status,
      statusFeedback: response.statusFeedback,
      modified: new Date(),
    };

    const itemForFirebase = pick(jobConverter.toFirestore(item), keys(item));

    if (!itemForFirebase) {
      return Promise.resolve();
    }

    return this.angularFirestore
      .collection<Partial<PcJobFirebase>>(this.referenceWritePath)
      .doc(jobId)
      .set(itemForFirebase, { merge: true });
  }
}
