import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxComponent } from './checkbox.component';
import { ErrorModule } from '@pc-ui/error/error.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [CheckboxComponent],
  imports: [CommonModule, ErrorModule, ReactiveFormsModule, FormsModule],
  exports: [CheckboxComponent],
})
export class CheckboxModule {}
