import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PcPipesModule } from '@pc-pipes';
import { ButtonModule } from '@pc-ui/button';
import { ErrorModule } from '@pc-ui/error/error.module';
import { IconModule } from '@pc-ui/icon';
import { ImageModule } from '@pc-ui/image/image.module';
import { SpinnerModule } from '@pc-ui/spinner';
import { ImageUploaderSingleComponent } from './image-uploader-single.component';

@NgModule({
  declarations: [ImageUploaderSingleComponent],
  imports: [
    CommonModule,
    IconModule,
    ButtonModule,
    SpinnerModule,
    PcPipesModule,
    ErrorModule,
    ImageModule,
  ],
  exports: [ImageUploaderSingleComponent],
})
export class ImageUploaderSingleModule {}
