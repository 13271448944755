import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  private contentQrCode = new Subject<string>();

  constructor() {}

  public printContentQrCode(content: string): void {
    this.contentQrCode.next(content);
    setTimeout(() => {
      this.print();
    }, 0);
  }

  private print(): void {
    try {
      if (navigator.userAgent.toLowerCase().indexOf('firefox') !== -1) {
        window.print();
      } else {
        document.execCommand('print', true, undefined);
      }
    } catch (e) {
      window.print();
    }
  }

  public contentQrCode$ = this.contentQrCode.asObservable();
}
