import { PcEnvironment } from '@pc-types';

export const PC_ENVIRONMENT: PcEnvironment = {
  production: true,
  env: 'dev',
  sentryEnv: 'next',
  websiteUrl: 'https://pluscity-next.netural.com',
  firebase: {
    apiKey: 'AIzaSyCw4ZZv_qbbaYh2je0Sm6URZyrAHjx8UiE',
    authDomain: 'metacity-369e6.firebaseapp.com',
    databaseURL: 'https://metacity-369e6.firebaseio.com',
    projectId: 'metacity-369e6',
    storageBucket: 'metacity-369e6.appspot.com',
    messagingSenderId: '1025021407726',
    appId: '1:1025021407726:web:790c27d706e47b907c9eff',
    measurementId: 'G-XJX969NF43',
  },
};
