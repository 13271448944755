<pc-label *ngIf="label" class="text-left" [ngClass]="{ 'opacity-50': isDisabled }" [isRequired]="isRequired" [htmlId]="htmlId">{{ label }}</pc-label>
<div class="-mx-4 flex">
  <div class="flex items-center px-4">
    <pc-input [shouldHideErrors]="true" class="mr-2 w-20" alignment="right" inputType="number" [parentFormGroup]="form" parentFormControlName="hours" (valueChange)="listenToValueChanges()"></pc-input>
    <p>h</p>
  </div>

  <div class="flex items-center px-4">
    <pc-input [shouldHideErrors]="true" class="mr-2 w-20" alignment="right" inputType="number" [parentFormGroup]="form" parentFormControlName="minutes" (valueChange)="listenToValueChanges()"></pc-input>
    <p>min</p>
  </div>
</div>

<div class="mt-2" *ngIf="shouldShowFormErrors()">
  <pc-error [errors]="getFormErrors()"></pc-error>
</div>
