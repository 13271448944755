import { Injectable } from '@angular/core';
import {
  PcDataFilterAll,
  PcDataFilterData,
  PcDataSort,
  PcSelectFilter,
  PcTableColumnKey,
  PcTableItem,
} from '@pc-types';
import { isAfter } from 'date-fns';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export abstract class DataService<
  TableItem extends PcTableItem,
  TableColumnKey extends PcTableColumnKey,
  Filter extends PcDataFilterAll<TableColumnKey>
> {
  public filters$ = new BehaviorSubject<PcSelectFilter<Filter>[]>([]);
  public dataSource$ = new BehaviorSubject<TableItem[]>([]);

  public activeFilter$ = new BehaviorSubject<
    PcSelectFilter<Filter> | undefined
  >(undefined);
  public abstract activeCriteria$: BehaviorSubject<Filter>;
  public currentPage$ = new BehaviorSubject<number>(1);
  public pageSize$ = new BehaviorSubject<number>(10);
  public totalPages$ = new BehaviorSubject<number>(0);
  public isSearchTermSet = false;

  constructor() {}

  public abstract getFilterData(
    items: TableItem[],
    filter: Filter,
    data?: PcDataFilterData
  ): TableItem[];

  protected abstract convertDefinitionToColumn(
    a: TableColumnKey
  ): keyof TableItem;

  public initFilters(
    filters: PcSelectFilter<Filter>[],
    rawData: TableItem[],
    data: PcDataFilterData
  ): void {
    const filtersWithCount = filters.map((filter) => {
      const count = this.getCount(rawData, filter.criteria, data);
      filter.count = count;

      return filter;
    });

    this.filters$.next(filtersWithCount);
  }

  protected sortBy(
    items: TableItem[],
    sortBy: PcDataSort<TableColumnKey>
  ): TableItem[] {
    const sortByColumn = this.convertDefinitionToColumn(sortBy.column);
    const descInvert = sortBy.direction === 'DESC' ? -1 : 1;
    return items.sort((a, b) => {
      if (sortBy) {
        const aValue = a[sortByColumn];
        const bValue = b[sortByColumn];

        if (aValue instanceof Date && bValue instanceof Date) {
          return isAfter(aValue, bValue) ? 1 * descInvert : -1 * descInvert;
        }
        let aStr = '';
        let bStr = '';
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          aStr = aValue;
          bStr = bValue;
        } else if (typeof aValue === 'number' && typeof bValue === 'number') {
          aStr = aValue.toString();
          bStr = bValue.toString();
        }

        if (!aStr || !bStr) {
          return 0;
        }
        return aStr.toLowerCase() < bStr.toLowerCase()
          ? -1 * descInvert
          : 1 * descInvert;
      }
      return 0;
    });
  }

  public getPagedData(
    items: TableItem[],
    pageSize: number,
    currentPage: number
  ): TableItem[] {
    const totalPages = pageSize
      ? Math.max(1, Math.floor((items.length - 1) / pageSize) + 1)
      : 1;

    let offset = (currentPage - 1) * pageSize;

    if (!offset) {
      offset = 0;
    }
    if (offset > 0) {
      items = items.slice(offset);
    }
    if (pageSize) {
      items = items.slice(0, pageSize);
    }

    this.totalPages$.next(totalPages);
    return items;
  }

  public getCount(
    rawData: TableItem[],
    filter: Filter,
    data: PcDataFilterData
  ): number {
    const criteria: Filter = {
      searchTerm: this.activeCriteria$.getValue().searchTerm,
      sortBy: this.activeCriteria$.getValue().sortBy,
      ...filter,
    };

    return this.getFilterData(rawData, criteria, data).length;
  }

  public isActive(filter: PcSelectFilter<Filter>): boolean {
    return filter === this.activeFilter$.getValue();
  }

  public setSearchTerm(searchTerm: string): void {
    this.isSearchTermSet = !!searchTerm;
    const criteria = { ...this.activeCriteria$.getValue() };
    criteria.searchTerm = searchTerm;
    this.activeCriteria$.next(criteria);
  }

  public setSortBy(sortBy: Filter['sortBy'] | undefined): void {
    const criteria = { ...this.activeCriteria$.getValue() };
    criteria.sortBy = sortBy;
    this.activeCriteria$.next(criteria);
  }

  public setFilter(filter: PcSelectFilter<Filter>): void {
    const criteria = {
      searchTerm: this.activeCriteria$.getValue().searchTerm,
      sortBy: this.activeCriteria$.getValue().sortBy,
      ...filter.criteria,
    };

    this.activeCriteria$.next(criteria);
    this.activeFilter$.next(filter);
  }
}
