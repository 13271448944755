import { DocumentReference } from '@angular/fire/compat/firestore';
import {
  Nullable,
  PcCockpitUser,
  PcDataFilterGeneral,
  PcEnv,
  PcImageFirebase,
  PcPastFuture,
  PcShopCategoryType,
  PcStatusSimple,
  PcTsFirebase,
} from '@pc-types';
import { PcAnnouncementDefinition } from 'src/app/cockpit/modules/announcements/announcements.definition';

type PcAnnouncementDisplayType = 'none' | 'modal' | 'alert';

type PcAnnouncementGeneric<DateType> = {
  created: DateType;
  modified: DateType;
  start: DateType;
  end: DateType;
  title: string;
  subtitle: string;
  description: string;
  enabled: boolean;
  env: PcEnv;
  displayType: PcAnnouncementDisplayType;
  images: PcImageFirebase[];
  author?: DocumentReference<PcCockpitUser>;
  index?: Nullable<number>;
  ctaUrl?: Nullable<string>;
  ctaLabel?: Nullable<string>;
  targetGroup?: PcShopCategoryType[];
  status: PcStatusSimple;
};

export type PcAnnouncement = PcAnnouncementGeneric<Date> & { uid: string };
export type PcAnnouncementFirebase = PcAnnouncementGeneric<PcTsFirebase>;

export type PcAnnouncementColumns = keyof PcAnnouncement;

export type PcAnnouncementsAcceptStorageType = string[];
export const PC_ANNOUNCEMENTS_ACCEPT_STORAGE_KEY = 'pc-announcements-seen';
export const PC_ANNOUNCEMENTS_DIALOG_SEEN_STORAGE_KEY =
  'pc-announcement-dialog-seen';

export type PcDataFilterAnnouncement =
  PcDataFilterGeneral<PcAnnouncementDefinition> & { endDate?: PcPastFuture };

export type PcAnnouncementStatus = 'active' | 'inactive' | 'past';

export type PcAnnouncemnentIndexChange = {
  uid: PcAnnouncement['uid'];
  index: PcAnnouncement['index'];
};
