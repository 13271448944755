import {
  PcCockpitUser,
  PcCockpitUserFirebase,
  PcFirestoreConverter,
} from '@pc-types';

export const cockpitUserConverter: PcFirestoreConverter<
  PcCockpitUserFirebase,
  PcCockpitUser
> = {
  fromFirestore: (input) => {
    if (!input) {
      return undefined;
    }
    return {
      uid: input.uid,
      email: input.email ?? '',
      firstname: input.firstname ?? '',
      lastname: input.lastname ?? '',
      image: input.image,
    };
  },
  toFirestore: (input) => {
    if (!input) {
      return undefined;
    }
    return {
      email: input.email ?? '',
      firstname: input.firstname ?? '',
      lastname: input.lastname ?? '',
      image: input.image,
    };
  },
};
