import {
  PcFirestoreConverter,
  PcTicketCategory,
  PcTicketCategoryFirebase,
} from '@pc-types';
import firebase from 'firebase/compat/app';

export const ticketCategoryConverter: PcFirestoreConverter<
  PcTicketCategoryFirebase,
  PcTicketCategory
> = {
  fromFirestore: (input) => {
    if (!input || !input.created || !input.key) {
      return undefined;
    }
    try {
      return {
        uid: input.uid,
        key: input.key,
        name: input.name ?? '',
        enabled: input.enabled ?? false,
        created: input.created.toDate(),
        modified: input.modified
          ? input.modified.toDate()
          : input.created.toDate(),
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
  toFirestore: (input) => {
    if (!input || !input.key) {
      return undefined;
    }
    try {
      return {
        key: input.key,
        name: input.name ?? '',
        enabled: input.enabled ?? false,
        created: firebase.firestore.Timestamp.now(),
        modified: firebase.firestore.Timestamp.now(),
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
};
