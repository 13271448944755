import { Router } from '@angular/router';
import { PcNavigationItem } from '@pc-types';

export function isActiveNavigationItem(
  item: PcNavigationItem,
  router: Router
): boolean {
  if (!item.activeUrl) {
    return false;
  }

  if (item.children && item.children.length > 0) {
    const matchingItems = item.children.find(
      (child) => router.url.indexOf(child.activeUrl ?? '') > -1
    );

    if (matchingItems) {
      return true;
    }
  } else if (router.url.indexOf(item.activeUrl ?? '') > -1) {
    return true;
  }
  return false;
}
