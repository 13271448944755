<div class="relative z-20">
  <div class="absolute top-0 right-0 mt-2 mr-2">
    <button type="button" buttonClass="p-2" pc-button variant="link" color="gray" (click)="onCloseClick()">
      <pc-icon icon="close" [size]="6"></pc-icon>
    </button>
  </div>
</div>
<div class="py-3">
  <div class="relative">
    <mat-dialog-content class="text-center">
      <div [ngClass]="getContentClasses()">
        <ng-content></ng-content>
      </div>
    </mat-dialog-content>
    <div class="absolute inset-x-0 bottom-0 h-6 bg-gradient-to-t from-white"></div>
    <div class="absolute inset-x-0 top-0 h-6 bg-gradient-to-b from-white"></div>
  </div>
  <div [ngClass]="getFooterClasses()">
    <ng-content select="#footer"></ng-content>
  </div>
</div>
