import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModelMiniComponent } from './card-model-mini.component';
import { IconModule } from '@pc-ui/icon';
import { ModelPropListModule } from '@pc-ui/model-prop-list';
import { ImageModule } from '@pc-ui/image';
import { PcPipesModule } from '@pc-pipes';

@NgModule({
  declarations: [CardModelMiniComponent],
  imports: [
    CommonModule,
    IconModule,
    ModelPropListModule,
    ImageModule,
    PcPipesModule,
  ],
  exports: [CardModelMiniComponent],
})
export class CardModelMiniModule {}
