import { PcNotification, PcNotificationFirebase } from '@pc-types';
import firebase from 'firebase/compat/app';

export const notificationConverter = {
  fromFirestoreUser: (
    input: PcNotificationFirebase & { uid: string }
  ): PcNotification | undefined => {
    const test = fromFirestore(input);
    if (!test) {
      return undefined;
    }
    test.scope = 'user';
    return test;
  },
  fromFirestoreShop: (
    input: PcNotificationFirebase & { uid: string }
  ): PcNotification | undefined => {
    const test = fromFirestore(input);
    if (!test) {
      return undefined;
    }
    test.scope = 'shop';
    return test;
  },
  toFirestore: (input: PcNotification): PcNotificationFirebase | undefined => {
    if (!input) {
      return undefined;
    }
    try {
      return {
        created: firebase.firestore.Timestamp.now(),
        modified: firebase.firestore.Timestamp.now(),
        type: input.type,
        scope: input.scope,
        title: input.title,
        message: input.message,
        read: input.read,
        ref: input.ref,
        cockpitRoute: input.cockpitRoute,
        trigger: input.trigger,
        env: input.env,
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
};

function fromFirestore(
  input: PcNotificationFirebase & { uid: string }
): PcNotification | undefined {
  if (!input) {
    return undefined;
  }
  try {
    return {
      uid: input.uid,
      created: input.created.toDate(),
      modified: input.modified.toDate(),
      type: input.type,
      scope: input.scope,
      title: input.title,
      message: input.message,
      read: input.read,
      ref: input.ref,
      cockpitRoute: input.cockpitRoute,
      trigger: input.trigger,
      env: input.env,
    };
  } catch (err) {
    console.error(err);
    console.error('Wrong Input: ', input);
    return undefined;
  }
}
