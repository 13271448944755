import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationBulletsComponent } from './pagination-bullets.component';

@NgModule({
  declarations: [PaginationBulletsComponent],
  imports: [CommonModule],
  exports: [PaginationBulletsComponent],
})
export class PaginationBulletsModule {}
