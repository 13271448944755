import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { IconModule } from '@pc-ui/icon';
import { UserManagementTableDialogComponent } from './user-management-table-dialog.component';

@NgModule({
  imports: [CommonModule, IconModule, MatDialogModule],
  declarations: [UserManagementTableDialogComponent],
  exports: [UserManagementTableDialogComponent],
})
export class UserManagementTableDialogModule {}
