import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { idGenerator } from '@pc-helpers';
import { PcErrorable } from '@pc-services';
import { Subscription } from 'rxjs';
import { inputDurationValidator } from './validators/input-duration.validator';

export type PcInputDurationValue = {
  hours: number;
  minutes: number;
};

@Component({
  selector: 'pc-input-duration',
  templateUrl: './input-duration.component.html',
  styleUrls: ['./input-duration.component.scss'],
})
export class InputDurationComponent
  extends PcErrorable
  implements OnInit, OnChanges, OnDestroy
{
  @Input() parentFormGroup?: FormGroup;
  @Input() parentFormControlName?: string;
  @Input() label?: string;
  @Input() isDisabled = false;
  @Input() isRequired = false;

  @Output() private valueChange = new EventEmitter<PcInputDurationValue>();

  public htmlId?: string;
  public form?: FormGroup<{
    [key in 'minutes' | 'hours']: FormControl<number | null>;
  }>;
  public value?: PcInputDurationValue;

  private subscriptions!: Subscription;

  constructor(private cdRef: ChangeDetectorRef) {
    super();
    this.htmlId = idGenerator();
  }

  ngOnInit(): void {
    this.initForm();
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.parentFormGroup) {
      this.subscriptions?.unsubscribe();
      this.subscriptions = new Subscription();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private init(): void {
    if (this.getFormControl()) {
      this.subscriptions.add(
        this.getFormControl()?.valueChanges.subscribe((value) => {
          this.value = value;
          this.setCurrentValue();
          this.cdRef.detectChanges();
        })
      );
    }
  }

  public listenToValueChanges(): void {
    this.patchForm(this.form?.value as PcInputDurationValue);
  }

  private initForm(): void {
    this.form = new FormGroup(
      {
        hours: new FormControl(0),
        minutes: new FormControl(0),
      },
      [inputDurationValidator]
    );

    this.value = this.getFormControl()?.value;
    this.setCurrentValue();
  }

  private setCurrentValue(): void {
    this.form?.setValue({
      hours: this.value?.hours || 0,
      minutes: this.value?.minutes || 0,
    });
  }

  private patchForm(value: PcInputDurationValue): void {
    this.getFormControl()?.patchValue(value);
    this.getFormControl()?.markAsTouched();
    this.getFormControl()?.markAsDirty();
    this.valueChange.emit(value);
  }

  protected isFormTouched(): boolean {
    return !!this.form?.touched;
  }

  public getFormErrors(): ValidationErrors | undefined {
    const formControlHours = this.form?.get('hours');
    const formControlMinutes = this.form?.get('minutes');

    if (formControlHours?.errors) {
      return formControlHours?.errors;
    }

    if (formControlMinutes?.errors) {
      return formControlMinutes?.errors;
    }

    return undefined;
  }

  public shouldShowFormErrors(): boolean {
    return this.isFormTouched() && !!this.getFormErrors();
  }
}
