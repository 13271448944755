import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const inputDurationValidator: ValidatorFn = (
  form: AbstractControl
): ValidationErrors | null => {
  const hours = form.get('hours');
  const minutes = form.get('minutes');

  if (!hours || !minutes) {
    return null;
  }

  minutes.setErrors(null);
  hours.setErrors(null);

  const hoursValue = hours.value ?? 0;
  const minutesValue = minutes.value ?? 0;

  if (hoursValue * 60 + minutesValue > 120) {
    hours.setErrors({
      custom: 'Maximal 2 Stunden',
    });
  }

  return null;
};
