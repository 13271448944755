import { registerLocaleData } from '@angular/common';
import de from '@angular/common/locales/de';
import { NgModule } from '@angular/core';
import { PcBookingRpStatusPipe } from './pc-booking-rp-status/pc-booking-rp-status.pipe';
import { PcContentCreateKindPipe } from './pc-content-create-kind/pc-content-create-kind.pipe';
import { PcDatePipe } from './pc-date/pc-date.pipe';
import { PcImagePipe } from './pc-image/pc-image.pipe';
import { PcModelKindPipe } from './pc-model-type/pc-model-type.pipe';
import { PcOpeningHoursRequestStatusPipe } from './pc-opening-hours-request-status/pc-opening-hours-request-status.pipe';
import { PcShopServicePipe } from './pc-shop-service/pc-shop-service.pipe';
import { PcTicketCategoryPipe } from './pc-ticket-category/pc-ticket-category.pipe';
import { PcTicketLocationPipe } from './pc-ticket-location/pc-ticket-location.pipe';

registerLocaleData(de);

@NgModule({
  exports: [
    PcDatePipe,
    PcModelKindPipe,
    PcContentCreateKindPipe,
    PcImagePipe,
    PcShopServicePipe,
    PcBookingRpStatusPipe,
    PcOpeningHoursRequestStatusPipe,
    PcTicketCategoryPipe,
    PcTicketLocationPipe,
  ],
  declarations: [
    PcDatePipe,
    PcModelKindPipe,
    PcContentCreateKindPipe,
    PcImagePipe,
    PcShopServicePipe,
    PcBookingRpStatusPipe,
    PcOpeningHoursRequestStatusPipe,
    PcTicketCategoryPipe,
    PcTicketLocationPipe,
  ],
  providers: [
    PcDatePipe,
    PcModelKindPipe,
    PcContentCreateKindPipe,
    PcImagePipe,
    PcShopServicePipe,
    PcBookingRpStatusPipe,
    PcOpeningHoursRequestStatusPipe,
    PcTicketCategoryPipe,
    PcTicketLocationPipe,
  ],
})
export class PcPipesModule {}
