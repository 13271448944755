import {
  AngularFirestore,
  DocumentReference,
} from '@angular/fire/compat/firestore';
import { PcGlobalPermissions, PC_COLLECTIONS } from '@pc-types';
import { firstValueFrom } from 'rxjs';

export function getFirestorePathBasedOnPermissions(
  globalPermissions: PcGlobalPermissions,
  collectionNameShop: string,
  collectionNameManager?: string
): string | undefined {
  if (globalPermissions.role === 'manager') {
    return collectionNameManager || collectionNameShop;
  }

  return globalPermissions.shopId
    ? `${PC_COLLECTIONS.SHOPS}/${globalPermissions.shopId}/${collectionNameShop}`
    : undefined;
}

export function convertReferencesToIds(
  references: DocumentReference<unknown>[] | undefined
): string[] {
  const referenceIds: string[] = [];

  if (!references) {
    return [];
  }

  references.forEach((reference) => {
    if (reference) {
      referenceIds.push(reference.id);
    }
  });

  return referenceIds;
}

export function convertIdsToReferences(
  ids: string[] | undefined,
  collectionPath: string,
  angularFirestore: AngularFirestore
): DocumentReference<any>[] {
  const references: DocumentReference<any>[] = [];

  if (!ids) {
    return [];
  }

  ids.forEach((id) => {
    if (id) {
      references.push(
        angularFirestore.doc<unknown>(collectionPath + '/' + id).ref
      );
    }
  });

  return references;
}

export async function waitUntilDocumentExists(
  target: string,
  angularFirestore: AngularFirestore
): Promise<void> {
  let doc = await firstValueFrom(angularFirestore.doc(target).get());

  while (!doc.exists) {
    await new Promise((resolve) => setTimeout(resolve, 300));
    doc = await firstValueFrom(angularFirestore.doc(target).get());
  }
}
