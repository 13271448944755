import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectComponent } from './select.component';
import { MatSelectModule } from '@angular/material/select';
import { ErrorModule } from '@pc-ui/error/error.module';
import { LabelModule } from '@pc-ui/label/label.module';

@NgModule({
  declarations: [SelectComponent],
  imports: [CommonModule, MatSelectModule, ErrorModule, LabelModule],
  exports: [SelectComponent],
})
export class SelectModule {}
