import { convertDateToTsFirebase } from '@pc-helpers';
import {
  PcFirestoreNullableConverter,
  PcStatusFeedback,
  PcStatusFeedbackFirebase,
} from '@pc-types';

export const statusFeedbackConverter: PcFirestoreNullableConverter<
  PcStatusFeedbackFirebase,
  PcStatusFeedback
> = {
  fromFirestore: (input) => {
    return !input || !input.text || !input.author || !input.date
      ? undefined
      : {
          date: input.date.toDate(),
          text: input.text,
          author: input.author,
        };
  },
  toFirestore: (input) => {
    return typeof input === 'undefined'
      ? undefined
      : input && input.text && input.author && input.date
      ? {
          date: convertDateToTsFirebase(input.date),
          text: input.text,
          author: input.author,
        }
      : null;
  },
};
