import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  DateAdapter,
  MatNativeDateModule,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PC_APP_DATE_FORMATS } from '@pc-types';
import { ButtonModule } from '@pc-ui/button';
import { ErrorModule } from '@pc-ui/error/error.module';
import { IconModule } from '@pc-ui/icon';
import { LabelModule } from '@pc-ui/label/label.module';
import { PcDateAdapter } from 'src/app/shared/adapter/date.adapter';
import { DatepickerComponent } from './datepicker.component';

@NgModule({
  declarations: [DatepickerComponent],
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    IconModule,
    ErrorModule,
    LabelModule,
    ButtonModule,
  ],
  exports: [DatepickerComponent],
  providers: [
    { provide: DateAdapter, useClass: PcDateAdapter },
    {
      provide: MAT_DATE_FORMATS,
      useValue: PC_APP_DATE_FORMATS,
    },
  ],
})
export class DatepickerModule {}
