import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { ButtonModule } from '@pc-ui/button';
import { DialogModule } from '@pc-ui/dialog';
import { TextareaModule } from '@pc-ui/textarea';
import { ReservepickupCancelDialogComponent } from './reservepickup-cancel-dialog.component';

@NgModule({
  declarations: [ReservepickupCancelDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    TextareaModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
  ],
  exports: [ReservepickupCancelDialogComponent],
})
export class ReservepickupCancelDialogModule {}
