import { Injectable } from '@angular/core';
import {
  BookingService,
  CompetitionsService,
  EventsService,
  ForecastService,
  HolidaysService,
  LoadingService,
  NewsService,
  NotificationService,
  OfferService,
  PromotionService,
  ReadService,
  ShopOpeningHoursRequestsService,
  ShopService,
  TagService,
  UserService,
} from '@pc-services';
import { AnnouncementService } from '../models/announcements.service';
import { JobsService } from '../models/jobs.service';
import { KidsService } from '../models/kids.service';
import { ReportsService } from '../models/reports.service';
import { TicketingService } from '../models/ticketing.service';
import { RouteService } from '../route/route.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  constructor(
    private userService: UserService,
    private promotionService: PromotionService,
    private shopService: ShopService,
    private shopOpeningHoursRequestService: ShopOpeningHoursRequestsService,
    private tagService: TagService,
    private competitionService: CompetitionsService,
    private eventsService: EventsService,
    private newsService: NewsService,
    private offerService: OfferService,
    private notificationService: NotificationService,
    private holidayService: HolidaysService,
    private forecastService: ForecastService,
    private readService: ReadService,
    private loadingService: LoadingService,
    private bookingService: BookingService,
    private announcementService: AnnouncementService,
    private reportsService: ReportsService,
    private jobsService: JobsService,
    private kidsService: KidsService,
    private ticketingService: TicketingService,
    private routeService: RouteService
  ) {}

  public initCockpitApplication(): void {
    this.loadingService.init();
    this.routeService.init();

    // user
    this.userService.fetchMyUser();
    this.userService.fetchMyUserFeatures();
    this.userService.fetchAllCockpitUsers();

    // model data
    this.competitionService.fetchAll();
    this.eventsService.fetchAll();
    this.newsService.fetchAll();
    this.offerService.fetchAll();

    this.competitionService.fetchForFeed();
    this.eventsService.fetchForFeed();
    this.newsService.fetchForFeed();
    this.offerService.fetchForFeed();

    // feed additional data
    this.promotionService.fetchAll();
    this.shopService.fetchAll();
    this.shopService.fetchMyShop();
    this.shopService.fetchAllShopCategories();
    this.shopService.fetchAllContracts();
    this.shopOpeningHoursRequestService.fetchMyRequest();
    this.tagService.fetchAll();
    this.holidayService.fetchAll();
    this.forecastService.fetchAll();
    this.readService.subscribeToAllModelsWithMyShop();
    this.bookingService.fetchAll();
    this.announcementService.fetchAll();
    this.reportsService.fetchAllForMyShop();
    this.jobsService.fetchAll();
    this.kidsService.fetchAll();
    this.ticketingService.fetchAllCategories();
    this.ticketingService.fetchAllLocations();
    this.ticketingService.fetchAll();

    this.notificationService.listenToShopNotifications(true);
    this.notificationService.listenToShopNotifications(false);
    this.notificationService.listenToUserNotifications(true);
    this.notificationService.listenToUserNotifications(false);
  }
}
