import { modelConverter } from '@pc-converter';
import {
  convertDateToTsFirebase,
  convertDateToTsMilliSeconds,
  convertTsMilliSecondsToDate,
  dateFallback,
} from '@pc-helpers';
import {
  PcCompetition,
  PcCompetitionFirebase,
  PcFirestoreConverter,
} from '@pc-types';

export const competitionConverter: PcFirestoreConverter<
  PcCompetitionFirebase,
  PcCompetition
> = {
  fromFirestore: (input) => {
    if (
      !input ||
      !input.participationStart ||
      typeof input.participationStart.toDate !== 'function' ||
      !input.participationEnd ||
      typeof input.participationEnd.toDate !== 'function' ||
      (input.type !== 'answers' &&
        input.type !== 'collect' &&
        input.type !== 'imageUpload' &&
        input.type !== 'participation' &&
        input.type !== 'scratch') ||
      typeof input.start !== 'number' ||
      typeof input.end !== 'number' ||
      typeof input.drawingTime !== 'number'
    ) {
      return undefined;
    }

    try {
      const model = modelConverter.fromFirestore(input);
      if (!model) {
        return undefined;
      }

      return {
        ...model,
        kind: 'competition',
        winnersEvaluated: input.winnersEvaluated
          ? input.winnersEvaluated.toDate()
          : undefined,
        participationStart: input.participationStart.toDate(),
        participationEnd: input.participationEnd.toDate(),
        drawingTime: convertTsMilliSecondsToDate(input.drawingTime),
        competitionType: input.type ?? 'participation',
        name: input.name ?? '',
        userSharing: input.userSharing ?? true,
        location: input.location ?? '',
        userAddressRequired: input.userAddressRequired ?? false,
        userDOBRequired: input.userDOBRequired ?? false,
        userEmailRequired: input.userEmailRequired ?? false,
        userPhoneRequired: input.userPhoneRequired ?? false,
        start: convertTsMilliSecondsToDate(input.start),
        end: convertTsMilliSecondsToDate(input.end),
        userMinAgeInYears: input.userMinAgeInYears,
        disclaimer: input.disclaimer,
        questionAnswerType: input.questionAnswerType,
        questionTitle: input.questionTitle,
        answers: input.answers,
        scratchDud: input.scratchDud,
        scratchOverlay: input.scratchOverlay,
        scratchDescription: input.scratchDescription,
        rewards: input.rewards?.map((reward) => {
          return {
            ...reward,
            offset:
              typeof reward.offset === 'number' ? reward.offset + 1 : undefined,
            variant: !!reward.propability ? 'instant' : 'primary',
          };
        }),
        readonlyCompetitionType: input.readonlyCompetitionType ?? false,
        collectLocations:
          input.type === 'collect' ? input.collectLocations : undefined,
        rewardPrimaryOffset:
          input.type === 'collect' ? input.rewardPrimaryOffset : undefined,
        rewardPrimaryRepeat:
          input.type === 'collect' ? input.rewardPrimaryRepeat : undefined,
        collectTitle: input.type === 'collect' ? input.collectTitle : undefined,
        collectDescription:
          input.type === 'collect' ? input.collectDescription : undefined,
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
  toFirestore: (input) => {
    if (!input) {
      return undefined;
    }

    try {
      const model = modelConverter.toFirestore(input);
      if (!model) {
        return undefined;
      }
      return {
        ...model,
        winnersEvaluated: input.winnersEvaluated
          ? convertDateToTsFirebase(input.winnersEvaluated)
          : input.winnersEvaluated,
        participationStart: convertDateToTsFirebase(
          dateFallback(input.participationStart)
        ),
        participationEnd: convertDateToTsFirebase(
          dateFallback(input.participationEnd)
        ),
        drawingTime: convertDateToTsMilliSeconds(
          dateFallback(input.drawingTime)
        ),
        start: convertDateToTsMilliSeconds(dateFallback(input.start)),
        end: convertDateToTsMilliSeconds(dateFallback(input.end)),
        type: input.competitionType ?? 'participation',
        name: input.name ?? '',
        userSharing: input.userSharing ?? true,
        location: input.location ?? '',
        userAddressRequired: input.userAddressRequired ?? false,
        userDOBRequired: input.userDOBRequired ?? false,
        userEmailRequired: input.userEmailRequired ?? false,
        userPhoneRequired: input.userPhoneRequired ?? false,
        userMinAgeInYears: input.userMinAgeInYears,
        disclaimer: input.disclaimer,
        questionAnswerType: input.questionAnswerType,
        questionTitle: input.questionTitle,
        answers: input.answers,
        scratchDud: input.scratchDud,
        scratchOverlay: input.scratchOverlay,
        scratchDescription: input.scratchDescription,
        rewards: input.rewards?.map((reward) => {
          return {
            ...reward,
            offset:
              typeof reward.offset === 'number'
                ? Math.max(reward.offset - 1, 0)
                : undefined,
            variant: undefined,
          };
        }),
        readonlyCompetitionType: input.readonlyCompetitionType ?? false,
        collectLocations:
          input.competitionType === 'collect' ? input.collectLocations : null,
        rewardPrimaryOffset:
          input.competitionType === 'collect'
            ? input.rewardPrimaryOffset
            : null,
        rewardPrimaryRepeat:
          input.competitionType === 'collect'
            ? input.rewardPrimaryRepeat
            : null,
        collectTitle:
          input.competitionType === 'collect' ? input.collectTitle : null,
        collectDescription:
          input.competitionType === 'collect' ? input.collectDescription : null,
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
};
