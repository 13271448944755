import { convertDateToTsFirebase, neverError } from '@pc-helpers';
import {
  Complete,
  PcFirestoreConverter,
  PcShop,
  PcShopFirebase,
} from '@pc-types';

export const shopConverter: PcFirestoreConverter<PcShopFirebase, PcShop> = {
  fromFirestore: (input) => {
    if (!input || !input.logo || !input.slug || !input.name) {
      return undefined;
    }
    return {
      uid: input.uid,
      services: input.services ?? [],
      name: input.name,
      logo: input.logo,
      shopCategories: input.shopCategories ?? [],
      slug: input.slug,
      shopImages: input.shopImages ?? [],
      categoryTypes: input.categoryTypes ?? [],
      bookingEnabled: input.bookingEnabled ?? false,
      reportsStart: input.reportsStart?.toDate(),
      reportComment: input.reportComment ?? '',
      gastronomyMenuFile:
        input.gastronomyMenuType === 'file' && input.gastronomyMenu
          ? {
              fileName: 'Speisekarte',
              url: input.gastronomyMenu,
            }
          : undefined,
      gastronomyMenuLink:
        input.gastronomyMenuType === 'external' && input.gastronomyMenu
          ? input.gastronomyMenu
          : undefined,
      gastronomyMenuIsExternal: input.gastronomyMenuType === 'external',
      phone: input.phone,
      website: input.website,
      email: input.email,
      facebook: input.facebook,
      instagram: input.instagram,
      twitter: input.twitter,
      tiktok: input.tiktok,
      bookingType: input.bookingType,
      openingHours: input.openingHours,
      logoBackgroundColor: input.logoBackgroundColor,
      reportBranche: input.reportBranche,
      reportSubtitle: input.reportSubtitle,
      areaTotal: input.areaTotal,
      reportEnabled: input.reportEnabled ?? true,
    };
  },
  toFirestore: (input) => {
    if (!input) {
      return undefined;
    }

    const services = input.services;

    const gastronomyMenuFile = input.gastronomyMenuFile;
    const gastronomyMenuLink = input.gastronomyMenuLink;
    const isGastronomyMenuExternal = input.gastronomyMenuIsExternal;

    const output: Complete<PcShopFirebase> = {
      name: input.name ?? '',
      logo: input.logo ?? {},
      shopCategories: input.shopCategories ?? [],
      slug: input.slug ?? '',
      shopImages: input.shopImages ?? [],
      categoryTypes: input.categoryTypes ?? [],
      bookingEnabled: input.bookingEnabled ?? false,
      reportsStart: input.reportsStart
        ? convertDateToTsFirebase(input.reportsStart)
        : null,
      reportComment: input.reportComment ?? '',
      gastronomyMenuType: isGastronomyMenuExternal
        ? 'external'
        : gastronomyMenuFile
        ? 'file'
        : null,
      gastronomyMenu:
        !isGastronomyMenuExternal && gastronomyMenuFile
          ? gastronomyMenuFile.url
          : isGastronomyMenuExternal
          ? gastronomyMenuLink
          : null,
      clickcollectEmail: null,
      clickcollectPhone: null,
      clickcollectText: null,
      clickcollectTitle: null,
      clickcollectWebsite: null,
      clickcollectType: null,
      deliveryEmail: null,
      deliveryPhone: null,
      deliveryText: null,
      deliveryTitle: null,
      deliveryWebsite: null,
      deliveryType: null,
      takeawayEmail: null,
      takeawayPhone: null,
      takeawayText: null,
      takeawayTitle: null,
      takeawayWebsite: null,
      takeawayType: null,
      services: services ?? [],
      phone: input.phone,
      website: input.website,
      email: input.email,
      facebook: input.facebook,
      instagram: input.instagram,
      twitter: input.twitter,
      tiktok: input.tiktok,
      bookingType: input.bookingType,
      openingHours: input.openingHours,
      logoBackgroundColor: input.logoBackgroundColor,
      reportBranche: input.reportBranche,
      reportSubtitle: input.reportSubtitle,
      areaTotal: input.areaTotal,
      reportEnabled: input.reportEnabled ?? true,
    };

    services?.forEach((service) => {
      switch (service.type) {
        case 'clickcollect':
          output.clickcollectTitle = service.title ? service.title : null;
          output.clickcollectType = service.linkType;
          output.clickcollectWebsite = service.website ? service.website : null;
          output.clickcollectPhone = service.phone ? service.phone : null;
          output.clickcollectEmail = service.email ? service.email : null;
          output.clickcollectText = service.text ? service.text : null;
          break;

        case 'delivery':
          output.deliveryTitle = service.title ? service.title : null;
          output.deliveryType = service.linkType;
          output.deliveryWebsite = service.website ? service.website : null;
          output.deliveryPhone = service.phone ? service.phone : null;
          output.deliveryEmail = service.email ? service.email : null;
          output.deliveryText = service.text ? service.text : null;
          break;

        case 'takeaway':
          output.takeawayTitle = service.title ? service.title : null;
          output.takeawayType = service.linkType;
          output.takeawayWebsite = service.website ? service.website : null;
          output.takeawayEmail = service.email ? service.email : null;
          output.takeawayText = service.text ? service.text : null;
          output.takeawayPhone = service.phone ? service.phone : null;
          break;

        default:
          throw neverError('', service.type);
      }
    });

    return output;
  },
};
