import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@pc-ui/icon';
import { IndicatorForecastHolidayComponent } from './indicator-forecast-holiday.component';

@NgModule({
  declarations: [IndicatorForecastHolidayComponent],
  imports: [CommonModule, IconModule],
  exports: [IndicatorForecastHolidayComponent],
})
export class IndicatorForecastHolidayModule {}
