import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PcPipesModule } from '@pc-pipes';
import { UserImageModule } from '@pc-ui/user-image';
import { UserDateItemComponent } from './user-date-item.component';

@NgModule({
  declarations: [UserDateItemComponent],
  imports: [CommonModule, UserImageModule, PcPipesModule],
  exports: [UserDateItemComponent],
})
export class UserDateItemModule {}
