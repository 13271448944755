import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  DocumentReference,
  Query,
} from '@angular/fire/compat/firestore';
import { shopContractConverter, shopConverter } from '@pc-converter';
import { EnvironmentService, StoreService } from '@pc-services';
import {
  PC_COLLECTIONS,
  PcEnv,
  PcShop,
  PcShopContractFirebase,
  PcShopFirebase,
} from '@pc-types';
import { compact, keys, pick } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { shopSimpleConverter } from '../../converter/shop-simple.converter';

@Injectable({
  providedIn: 'root',
})
export class ShopService {
  constructor(
    private angularFirestore: AngularFirestore,
    private store: StoreService,
    private envService: EnvironmentService
  ) {}

  public fetchAll(): void {
    this.angularFirestore
      .collection<PcShopFirebase>(PC_COLLECTIONS.SHOPS, (ref) => {
        const env: PcEnv = this.envService.getFirebaseEnv();

        let query: Query = ref;
        query = query.where('enabled', '==', true);
        if (env === 'prod') {
          query = query.where('env', '==', env);
        }
        return query;
      })
      .valueChanges({ idField: 'uid' })
      .pipe(
        map((shops) => shops.map(shopSimpleConverter.fromFirestore)),
        distinctUntilChanged()
      )
      .subscribe((shops) => {
        this.store.setShops(compact(shops));
      });
  }

  public fetchMyShop(): void {
    this.store.myGlobalPermissions$
      .pipe(
        switchMap((globalPermissions) => {
          if (globalPermissions.role === 'manager') {
            return of(undefined);
          }
          return this.fetchByShopId$(globalPermissions.shopId);
        }),
        distinctUntilChanged()
      )
      .subscribe((shop) => {
        this.store.setMyShop(shop ?? null);
      });
  }

  public fetchByShopId$(shopId: string): Observable<PcShop | undefined> {
    return this.angularFirestore
      .collection<PcShopFirebase>(PC_COLLECTIONS.SHOPS, (ref) => {
        const env: PcEnv = this.envService.getFirebaseEnv();

        let query: Query = ref;
        query = query.where('enabled', '==', true);
        if (env === 'prod') {
          query = query.where('env', '==', env);
        }

        return query;
      })
      .doc(shopId)
      .valueChanges({ idField: 'uid' })
      .pipe(
        map((shop) => (shop ? shopConverter.fromFirestore(shop) : undefined))
      );
  }

  public fetchAllShopCategories(): void {
    this.angularFirestore
      .collection<PcShopFirebase>(PC_COLLECTIONS.SHOP_CATEGORIES, (ref) => {
        let query: Query = ref;
        const env: PcEnv = this.envService.getFirebaseEnv();
        query = query.where('enabled', '==', true);
        if (env === 'prod') {
          query = query.where('env', '==', env);
        }
        return query;
      })
      .valueChanges({ idField: 'uid' })
      .pipe(distinctUntilChanged())
      .subscribe((shops) => {
        this.store.setShopCategories(shops);
      });
  }

  public fetchAllContracts(): void {
    this.store.myGlobalPermissions$
      .pipe(
        switchMap((globalPermissions) => {
          return globalPermissions.role === 'manager'
            ? this.angularFirestore
                .collectionGroup<PcShopContractFirebase>(
                  PC_COLLECTIONS.CONTRACTS
                )
                .valueChanges({ idField: 'uid' })
            : this.angularFirestore
                .collection(PC_COLLECTIONS.SHOPS)
                .doc(globalPermissions.shopId)
                .collection<PcShopContractFirebase>(PC_COLLECTIONS.CONTRACTS)
                .valueChanges({ idField: 'uid' });
        }),
        map((contracts) =>
          compact(contracts.map(shopContractConverter.fromFirestore))
        )
      )
      .subscribe((shopContracts) => {
        this.store.setShopContracts(shopContracts);
      });
  }

  public async updateShop(item: Partial<PcShop>): Promise<void> {
    const pickKeys = keys(item);
    if (item.services) {
      pickKeys.push(
        'clickcollectEmail',
        'clickcollectPhone',
        'clickcollectText',
        'clickcollectTitle',
        'clickcollectWebsite',
        'clickcollectType',
        'deliveryEmail',
        'deliveryPhone',
        'deliveryText',
        'deliveryTitle',
        'deliveryWebsite',
        'deliveryType',
        'takeawayEmail',
        'takeawayPhone',
        'takeawayText',
        'takeawayTitle',
        'takeawayWebsite',
        'takeawayType',
        'gastronomyMenuType',
        'gastronomyMenu'
      );
    }
    const itemForFirebase = pick(shopConverter.toFirestore(item), pickKeys);

    return this.angularFirestore
      .collection<PcShopFirebase>(PC_COLLECTIONS.SHOPS)
      .doc(item.uid)
      .update(itemForFirebase);
  }

  public getDocumentReference(id: string): DocumentReference<PcShopFirebase> {
    return this.angularFirestore
      .collection<PcShopFirebase>(PC_COLLECTIONS.SHOPS)
      .doc(id).ref;
  }
}
