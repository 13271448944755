import { Injectable } from '@angular/core';
import { StoreService, UiService } from '@pc-services';
import { combineLatest, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loadingSub!: Subscription;

  constructor(
    private storeService: StoreService,
    private uiService: UiService
  ) {}

  public init(): void {
    this.loadingSub = combineLatest([
      combineLatest([
        this.storeService.models$,
        this.storeService.feedModels$,
        this.storeService.promotionsAll$,
        this.storeService.shops$,
        this.storeService.user$,
      ]),
      combineLatest([
        this.storeService.forecast$,
        this.storeService.announcements$,
        this.storeService.reports$,
        this.storeService.jobs$,
        this.storeService.cockpitUsers$,
        this.storeService.myShop$,
      ]),
    ]).subscribe(() => {
      this.uiService.setState({
        globalSpinnerVisible: false,
      });
      this.loadingSub.unsubscribe();
    });
  }
}
