import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { PcOfferRedeemOption } from '@pc-types';
import { NavigationService } from '../services/navigation/navigation.service';

export function getFormGroupByRedeemOption(
  redeemOption?: PcOfferRedeemOption
): UntypedFormGroup {
  return new UntypedFormGroup({
    name: new UntypedFormControl(redeemOption?.name ?? '', [
      Validators.required,
    ]),
    image: new UntypedFormControl(redeemOption?.image),
    barcodeType: new UntypedFormControl(redeemOption?.barcodeType ?? ''),
    barcode: new UntypedFormControl(redeemOption?.barcode ?? ''),
  });
}

export function navigateToNewReservePickupOffer(
  router: Router,
  navigationService: NavigationService
): void {
  const options: NavigationExtras = {
    queryParams: {
      reservepickup: true,
    },
  };
  router.navigate(navigationService.getContentCreatePath('offer'), options);
}
