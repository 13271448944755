import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import {
  PcButtonColor,
  PcButtonSize,
  PcButtonVariant,
  PcIcon,
} from '@pc-types';

// type ICON_POS = 'left' | 'right' | 'center';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[pc-button], a[pc-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit {
  @Input() variant: PcButtonVariant = 'flat';
  @Input() buttonClass?: string;
  @Input() color: PcButtonColor = 'gold';
  @Input() icon?: PcIcon;
  @Input() iconSize: 4 | 6 = 4;
  // @Input() iconPos?: ICON_POS = 'left';
  @Input() iconClass?: string;
  @Input() size: PcButtonSize = 'M';
  @Input() isLoading = false;
  @HostBinding('class.is-elastic') @Input() isElastic = false;

  @HostBinding() @Input() isDisabled = false;
  @HostBinding('class.is-full') @Input() isFull = false;
  @HostBinding('class.is-small') get small(): boolean {
    return this.size === 'S';
  }
  @HostBinding('attr.disabled') get disabled(): boolean | null {
    return this.isDisabled || null;
  }

  constructor() {}

  ngOnInit(): void {}

  public getButtonClasses(): string[] {
    const classes = [];

    if (this.buttonClass) {
      classes.push(this.buttonClass);
    }

    if (this.isFull || this.isElastic) {
      classes.push(`w-full`);
    }

    classes.push(`rounded-md`);

    switch (this.variant) {
      case 'flat':
        classes.push('typo-patty whitespace-nowrap');
        break;
      case 'outline':
        classes.push('typo-patty border border-current whitespace-nowrap');
        break;
      case 'link':
        classes.push('font-medium');
        if (this.icon) {
          classes.push('whitespace-nowrap');
        }
        break;
    }

    if (this.color === 'gold') {
      switch (this.variant) {
        case 'flat':
          classes.push('bg-primary-gradient text-white');
          break;
        case 'outline':
          classes.push('text-gold-500');
          if (!this.isDisabled) {
            classes.push('hover-hover:hover:text-gold-700');
            classes.push('hover-hover:hover:border-gold-700'); // safari fix
          }
          break;
        case 'link':
          classes.push('text-gold-500');
          if (!this.isDisabled) {
            classes.push('hover-hover:hover:text-gold-700');
          }
          break;
      }
    }

    if (this.color === 'gray') {
      switch (this.variant) {
        case 'flat':
          classes.push('bg-secondary-gradient', 'text-white');
          break;
        case 'link':
          classes.push('text-black');
          if (!this.isDisabled) {
            classes.push('hover-hover:hover:text-gray-500');
          }
          break;
      }
    }

    if (this.color === 'white') {
      switch (this.variant) {
        case 'link':
          classes.push('text-white');
          if (!this.isDisabled) {
            classes.push('hover-hover:hover:text-gray-400');
          }
          break;
      }
    }
    if (this.color === 'red') {
      switch (this.variant) {
        case 'link':
          classes.push('text-red-300');
          if (!this.isDisabled) {
            classes.push('hover-hover:hover:text-red-500');
          }
          break;
      }
    }

    if (this.variant !== 'link') {
      switch (this.size) {
        case 'M':
          classes.push('typo-patty px-6 py-3 md:py-2');
          break;
        case 'S':
          classes.push('typo-lisa px-4 py-2 md:py-1');
          break;
      }
    } else {
      switch (this.size) {
        case 'S':
          classes.push('typo-bart');
          break;
      }
    }

    if (this.isDisabled) {
      classes.push(`cursor-default opacity-50`);
    } else {
      classes.push(`cursor-pointer hover-hover:hover:before:opacity-0`);
    }
    classes.push(`transition`, `duration-200`);
    classes.push(`is-loaded`);

    return classes;
  }

  public getContentClasses(): string[] {
    const classes = [];
    // if (this.icon) {
    //   if (this.iconPos === 'right') {
    //     classes.push('flex-row-reverse');
    //   }
    // }
    if (this.variant === 'flat' || this.variant === 'outline') {
      classes.push('justify-center');
    }

    if (this.variant !== 'link') {
      switch (this.size) {
        case 'M':
          classes.push('space-x-3');
          break;
        case 'S':
          classes.push('space-x-2');
          break;
      }
    } else {
      classes.push('space-x-2');
    }

    return classes;
  }

  public getIconClasses(): string[] {
    const classes = [];

    if (this.iconClass) {
      classes.push(this.iconClass);
    }

    return classes;
  }
}
