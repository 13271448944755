import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { idGenerator } from '@pc-helpers';
import { PcErrorable } from '@pc-services';
import { PcTheme } from '@pc-types';
import { Observable, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

type PcInputAlignment = 'left' | 'center' | 'right';

type PcInputType =
  | 'text'
  | 'tel'
  | 'email'
  | 'number'
  | 'url'
  | 'password'
  | 'currency';

type PcInputValue = string | number;
@Component({
  selector: 'pc-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class InputComponent extends PcErrorable implements OnInit, OnDestroy {
  @Input() parentFormGroup?: UntypedFormGroup;
  @Input() parentFormControlName?: string;

  @Input() value = '';
  @Input() placeholder? = '';
  @Input() isRequired = false;
  @Input() isReadonly = false;
  @Input() isDisabled = false;
  @Input() alignment: PcInputAlignment = 'left';
  @Input() label?: string;
  @Input() autocomplete = 'off';
  @Input() inputType: PcInputType = 'text';
  @Input() maxlength?: number;
  @Input() theme: PcTheme = 'light';
  @Input() isAutofocus = false;
  @Input() update$?: Observable<void>;
  @Input() focus$?: Observable<void>;
  @Input() shouldHideErrors = false;

  public shouldShowPassword = false;

  public internalValue?: PcInputValue;

  @Output() private valueChange = new EventEmitter<PcInputValue>();

  @ViewChild('input') private inputRef?: ElementRef<HTMLInputElement>;

  public htmlId: string;
  private subscriptions = new Subscription();

  constructor() {
    super();
    this.htmlId = idGenerator();
  }

  ngOnInit(): void {
    if (this.getFormControl()) {
      this.internalValue = this.getFormControl()?.value;
      this.subscriptions.add(
        this.getFormControl()?.valueChanges.subscribe((value) => {
          this.internalValue = value;
        })
      );
    } else {
      this.internalValue = this.value;
    }
    if (!this.placeholder && this.label) {
      this.placeholder = this.label;
    }
    if (this.isAutofocus) {
      setTimeout(() => {
        this.inputRef?.nativeElement.focus();
      }, 300);
    }
    this.listen();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private listen(): void {
    // update for autocomplete/autofill fields (e.g. password)

    this.subscriptions.add(
      this.update$?.pipe(throttleTime(10)).subscribe(() => {
        const value = this.inputRef?.nativeElement?.value;
        if (value) {
          this.onChange(value, false);
        }
      })
    );

    this.subscriptions.add(
      this.focus$?.subscribe(() => {
        this.inputRef?.nativeElement.focus();
      })
    );
  }

  public getClasses(): string[] {
    const classes = [];

    if (this.shouldShowErrors()) {
      classes.push('form-input-error');
    }

    if (this.theme === 'dark') {
      classes.push('form-input-dark');
    }

    if (this.isDisabled) {
      classes.push('opacity-50');
    }

    switch (this.alignment) {
      case 'left':
        classes.push('text-left');
        break;

      case 'center':
        classes.push('text-center');
        break;

      case 'right':
        classes.push('text-right');
        break;
    }

    return classes;
  }

  public togglePasswordVisibility(): void {
    this.shouldShowPassword = !this.shouldShowPassword;
  }

  public getFormControlName(): string {
    return this.parentFormControlName ? this.parentFormControlName : '';
  }

  public getInputType(): PcInputType {
    if (this.inputType === 'password') {
      return this.shouldShowPassword ? 'text' : 'password';
    }
    if (this.inputType === 'currency') {
      return 'number';
    }
    return this.inputType;
  }

  private getValue(value: PcInputValue | undefined): PcInputValue | undefined {
    if (typeof value === 'undefined') {
      return undefined;
    } else if (this.inputType === 'number') {
      const newValue = parseInt(value.toString(), 10);
      return isNaN(newValue) ? 0 : newValue;
    } else if (this.inputType === 'currency') {
      const newValue = Math.round(parseFloat(value.toString()) * 100) / 100;
      return isNaN(newValue) ? 0 : newValue;
    }

    return value;
  }

  public onChange(value: PcInputValue | undefined, emitEvent: boolean): void {
    const newValue = this.getValue(value);
    this.internalValue = newValue;
    this.patchForm(newValue, emitEvent);
  }

  public onBlur(value: PcInputValue): void {
    const newValue = this.getValue(value);
    this.internalValue = newValue;
  }

  private patchForm(value: PcInputValue | undefined, emitEvent = true): void {
    this.getFormControl()?.setValue(value);
    this.getFormControl()?.markAsTouched();
    this.getFormControl()?.markAsDirty();
    if (emitEvent) {
      this.valueChange.emit(value);
    }
  }

  public shouldShowErrors(): boolean {
    return this.isTouched() && !!this.getErrors() && !this.shouldHideErrors;
  }
}
