import { PcActionRefType } from '@pc-types';
import { last } from 'lodash-es';

export function getRefTypeByPath(path: string): PcActionRefType | undefined {
  const pathParts = path.split('/');
  pathParts.pop();

  switch (last(pathParts)) {
    case 'jobs':
      return 'job';
    case 'shops':
      return 'shop';
    case 'competitions':
      return 'competition';
    case 'news':
      return 'news';
    case 'events':
      return 'event';
    case 'offers':
      return 'offer';
  }
  return;
}

export const PC_STATS_TRACKING_START_DATE = new Date(2022, 2, 31);
