import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CheckboxModule } from '@pc-ui/checkbox/checkbox.module';
import { ErrorModule } from '@pc-ui/error/error.module';
import { LabelModule } from '@pc-ui/label';
import { CheckboxGroupComponent } from './checkbox-group.component';

@NgModule({
  declarations: [CheckboxGroupComponent],
  imports: [
    CommonModule,
    CheckboxModule,
    FormsModule,
    ErrorModule,
    LabelModule,
  ],
  exports: [CheckboxGroupComponent],
})
export class CheckboxGroupModule {}
