import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { ButtonModule } from '@pc-ui/button';
import { DialogModule } from '@pc-ui/dialog';
import { ContentConfirmDialogComponent } from './content-confirm-dialog.component';

@NgModule({
  declarations: [ContentConfirmDialogComponent],
  imports: [CommonModule, MatDialogModule, ButtonModule, DialogModule],
  exports: [ContentConfirmDialogComponent],
})
export class ContentConfirmDialogModule {}
