import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivationStart, NavigationEnd, Router } from '@angular/router';
import { PC_ENVIRONMENT } from '@pc-environment';
import {
  EnvironmentService,
  IconService,
  PrintService,
  StoreService,
  TourService,
  TrackingFirebaseService,
  TrackingService,
} from '@pc-services';
import { PcAcceptedCookies, PcUser } from '@pc-types';
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock';
import { combineLatest, Subscription } from 'rxjs';
import { debounceTime, filter, first } from 'rxjs/operators';
import { CookiesService } from './shared/services/application/cookies/cookies.service';
import { SentryService } from './shared/services/sentry/sentry.service';

@Component({
  selector: 'pc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private iconService: IconService,
    public printService: PrintService,
    private envService: EnvironmentService,
    private router: Router,
    private titleService: Title,
    private tourService: TourService,
    private cdRef: ChangeDetectorRef,
    private trackingService: TrackingService,
    private cookiesService: CookiesService,
    private sentryService: SentryService,
    private trackingFirebaseService: TrackingFirebaseService,
    private store: StoreService
  ) {}

  private isCookieBarHidden = false;
  private isCookieBarAvailableForCurrentPage = false;

  private subscriptions = new Subscription();

  private isScrollingDisabled = false;

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    clearAllBodyScrollLocks();
  }

  private async init(): Promise<void> {
    this.setDocumentTitle();
    this.iconService.initRegistry();
    this.envService.setEnv(PC_ENVIRONMENT);

    this.listenForAcceptedCookies();
    this.listenForCookiesBar();
    this.listenForIsCookieBarAvailableForCurrentPage();

    this.cookiesService.init();

    this.listenAndTrackPageViews();
    this.trackSession();
    this.listenForTourScrollLock();
  }

  private listenForAcceptedCookies(): void {
    this.subscriptions.add(
      this.cookiesService.acceptedCookies$
        .pipe(
          filter(
            (acceptedCookies): acceptedCookies is PcAcceptedCookies =>
              !!acceptedCookies
          )
        )
        .subscribe((acceptedCookies) => {
          if (acceptedCookies.statistics) {
            if (this.envService.getSentryEnv() !== 'local') {
              this.sentryService.enable(this.envService.getSentryEnv());
            }
            if (this.envService.getFirebaseEnv() === 'prod') {
              this.trackingService.enable();
            }
          } else {
            this.trackingService.disable();
          }
        })
    );
  }

  private listenForCookiesBar(): void {
    this.subscriptions.add(
      this.cookiesService.isCookieBarHidden$.subscribe((isCookieBarHidden) => {
        this.isCookieBarHidden = isCookieBarHidden;
        this.cdRef.detectChanges();
      })
    );
  }

  private trackSession(): void {
    this.subscriptions.add(
      combineLatest([
        this.store.user$.pipe(filter((user): user is PcUser => !!user)),
        this.store.myGlobalPermissions$,
      ])
        .pipe(debounceTime(1000), first())
        .subscribe(([user, globalPermissions]) => {
          this.trackingFirebaseService.trackSession(
            user.uid,
            globalPermissions?.role === 'tenant'
              ? globalPermissions.shopId
              : undefined
          );
        })
    );
  }

  private listenForIsCookieBarAvailableForCurrentPage(): void {
    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        this.isCookieBarAvailableForCurrentPage =
          item.url !== '/cookie-einstellungen';
        this.cdRef.detectChanges();
      }
    });
  }

  private listenAndTrackPageViews(): void {
    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        this.trackingService.pushTag({
          event: 'page',
          pageName: item.url,
        });
      }
    });
  }

  private setDocumentTitle(): void {
    this.subscriptions.add(
      this.router.events.subscribe((route) => {
        if (route instanceof ActivationStart) {
          if (route.snapshot.data['title']) {
            this.titleService.setTitle(
              `${route.snapshot.data['title']} - PlusCity Cockpit`
            );
          }
        }
      })
    );
  }

  private listenForTourScrollLock(): void {
    this.subscriptions.add(
      this.tourService.state$.subscribe((tourState) => {
        const currentStep = tourState.steps.find(
          (step) => step.step === tourState.currentStep
        );
        const isScrollingDisabled =
          tourState.active && !!currentStep && !currentStep.scrollable;
        if (isScrollingDisabled !== this.isScrollingDisabled) {
          if (isScrollingDisabled) {
            disableBodyScroll(document.body, { reserveScrollBarGap: true });
          } else {
            enableBodyScroll(document.body);
          }
          this.isScrollingDisabled = isScrollingDisabled;
        }
      })
    );
  }

  public isCookieBarVisible(): boolean {
    return !this.isCookieBarHidden && this.isCookieBarAvailableForCurrentPage;
  }
}
