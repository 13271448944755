import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { PcPipesModule } from '@pc-pipes';
import { ButtonModule } from '@pc-ui/button';
import { DialogModule } from '@pc-ui/dialog';
import { InputModule } from '@pc-ui/input';
import { LabelModule } from '@pc-ui/label';
import { ReportDialogComponent } from './report-dialog.component';

@NgModule({
  declarations: [ReportDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    ButtonModule,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    InputModule,
    PcPipesModule,
    LabelModule,
  ],
})
export class ReportDialogModule {}
