import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IndicatorForecastVisitorsComponent } from './indicator-forecast-visitors.component';

@NgModule({
  declarations: [IndicatorForecastVisitorsComponent],
  imports: [CommonModule],
  exports: [IndicatorForecastVisitorsComponent],
})
export class IndicatorForecastVisitorsModule {}
