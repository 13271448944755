import { convertDateToTsFirebase, dateFallback } from '@pc-helpers';
import {
  PcFirestoreConverter,
  PcShopContract,
  PcShopContractFirebase,
} from '@pc-types';

export const shopContractConverter: PcFirestoreConverter<
  PcShopContractFirebase,
  PcShopContract
> = {
  fromFirestore: (input) => {
    try {
      if (!input || !input.ref) {
        return undefined;
      }

      return {
        uid: input.uid,
        debitorId: input.debitorId ?? null,
        label: input.label ?? '',
        ref: input.ref,
        start: input.start?.toDate() ?? null,
        end: input.end?.toDate() ?? null,
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
  toFirestore: (input) => {
    if (!input || !input.ref) {
      return undefined;
    }
    try {
      return {
        uid: input.uid,
        debitorId: input.debitorId ?? null,
        label: input.label ?? '',
        ref: input.ref,
        start: convertDateToTsFirebase(dateFallback(input.start ?? undefined)),
        end: convertDateToTsFirebase(dateFallback(input.end ?? undefined)),
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
};
