import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { PcPipesModule } from '@pc-pipes';
import { ButtonModule } from '@pc-ui/button';
import { DialogModule } from '@pc-ui/dialog';
import { UserDateItemModule } from '@pc-ui/user-date-item';
import { UserImageModule } from '@pc-ui/user-image';
import { RejectInfoDialogComponent } from './reject-info-dialog.component';

@NgModule({
  declarations: [RejectInfoDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    ButtonModule,
    DialogModule,
    UserImageModule,
    PcPipesModule,
    UserDateItemModule,
  ],
})
export class RejectInfoDialogModule {}
