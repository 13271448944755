import {
  convertDateToTsFirebase,
  dateFallback,
  weatherIconConverter,
} from '@pc-helpers';
import {
  Complete,
  PcFirestoreConverterWithEnv,
  PcForecastDay,
  PcForecastDayFirebase,
  PcForecastOverrideFirebase,
} from '@pc-types';
import firebase from 'firebase/compat/app';

export const forecastConverter: PcFirestoreConverterWithEnv<
  PcForecastDayFirebase,
  PcForecastDay
> = {
  fromFirestore: (input, env) => {
    if (!input || !input.date) {
      return undefined;
    }

    const override = env === 'prod' ? input.override : input.overrideDev;

    try {
      return {
        uid: input.uid,
        date: input.date.toDate(),
        modified: input.modified ? input.modified.toDate() : undefined,
        visitors: input.visitors ?? 0,
        override: override
          ? {
              ...override,
              modified: override?.modified
                ? override.modified.toDate()
                : undefined,
            }
          : undefined,
        visitorsFinal: override?.visitors ?? input.visitors ?? 0,
        weather: input.weather
          ? {
              icon: weatherIconConverter(input.weather.code),
              label: input.weather.label,
              temperature: input.weather.temperature,
            }
          : undefined,
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
  toFirestore: (input, env) => {
    if (!input) {
      return undefined;
    }

    try {
      const override: Complete<PcForecastOverrideFirebase> = {
        visitors: input.override?.visitors ?? 0,
        modified: input.override
          ? convertDateToTsFirebase(dateFallback(input.override.modified))
          : undefined,
        user: input.override?.user,
      };

      return {
        date: convertDateToTsFirebase(dateFallback(input.date)),
        modified: firebase.firestore.Timestamp.now(),
        visitors: input.visitors ?? 0,
        weather: undefined,
        override: env === 'prod' ? override : undefined,
        overrideDev: env === 'dev' ? override : undefined,
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
};
