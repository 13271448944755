import { Injectable } from '@angular/core';
import { AngularFirestore, Query } from '@angular/fire/compat/firestore';
import { EnvironmentService, StoreService } from '@pc-services';
import {
  PcEnv,
  PcTicketCategoryFirebase,
  PcTicketFirebase,
  PC_COLLECTIONS,
} from '@pc-types';
import firebase from 'firebase/compat/app';
import { compact } from 'lodash-es';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { ticketCategoryConverter } from '../../converter/ticket-category.converter';
import { ticketLocationConverter } from '../../converter/ticket-location.converter';
import { ticketConverter } from '../../converter/ticket.converter';

@Injectable({
  providedIn: 'root',
})
export class TicketingService {
  constructor(
    private angularFirestore: AngularFirestore,
    private store: StoreService,
    private envService: EnvironmentService
  ) {}

  public fetchAll(): void {
    this.store.myGlobalPermissions$
      .pipe(
        filter(
          (globalPermissions) =>
            globalPermissions.role === 'manager' &&
            globalPermissions.modules.includes('ticketing')
        ),
        switchMap(() => {
          return this.angularFirestore
            .collection<PcTicketFirebase>(PC_COLLECTIONS.TICKETING, (ref) => {
              let query: Query = ref;
              const env: PcEnv = this.envService.getFirebaseEnv();
              query = query.where('env', '==', env);
              query = query.where('enabled', '==', true);
              return query;
            })
            .valueChanges({ idField: 'uid' });
        }),
        map((tickets) =>
          tickets.map((ticket) => ticketConverter.fromFirestore(ticket))
        ),
        distinctUntilChanged()
      )
      .subscribe((tickets) => {
        this.store.setTickets(compact(tickets));
      });
  }

  public fetchAllCategories(): void {
    this.store.myGlobalPermissions$
      .pipe(
        filter(
          (globalPermissions) =>
            globalPermissions.role === 'manager' &&
            globalPermissions.modules.includes('ticketing')
        ),
        switchMap(() => {
          return this.angularFirestore
            .collection<PcTicketCategoryFirebase>(
              PC_COLLECTIONS.TICKETING_CATEGORIES,
              (ref) => {
                let query: Query = ref;
                query = query.where('enabled', '==', true);
                return query;
              }
            )
            .valueChanges({ idField: 'uid' });
        }),
        map((categories) =>
          categories.map((category) =>
            ticketCategoryConverter.fromFirestore(category)
          )
        ),
        distinctUntilChanged()
      )
      .subscribe((categories) => {
        this.store.setTicketCategories(compact(categories));
      });
  }

  public fetchAllLocations(): void {
    this.store.myGlobalPermissions$
      .pipe(
        filter(
          (globalPermissions) =>
            globalPermissions.role === 'manager' &&
            globalPermissions.modules.includes('ticketing')
        ),
        switchMap(() => {
          return this.angularFirestore
            .collection<PcTicketCategoryFirebase>(
              PC_COLLECTIONS.TICKETING_LOCATIONS,
              (ref) => {
                let query: Query = ref;
                query = query.where('enabled', '==', true);
                return query;
              }
            )
            .valueChanges({ idField: 'uid' });
        }),
        map((locations) =>
          locations.map((location) =>
            ticketLocationConverter.fromFirestore(location)
          )
        ),
        distinctUntilChanged()
      )
      .subscribe((locations) => {
        this.store.setTicketLocations(compact(locations));
      });
  }

  public checkTicket(ticketId: string): Promise<void> {
    const item: Partial<PcTicketFirebase> = {
      status: 'completed',
      modified: firebase.firestore.Timestamp.now(),
    };

    return this.angularFirestore
      .collection<PcTicketFirebase>(PC_COLLECTIONS.TICKETING)
      .doc(ticketId)
      .update(item);
  }

  public archiveTicket(ticketId: string): Promise<void> {
    const item: Partial<PcTicketFirebase> = {
      status: 'archived',
      modified: firebase.firestore.Timestamp.now(),
    };

    return this.angularFirestore
      .collection<PcTicketFirebase>(PC_COLLECTIONS.TICKETING)
      .doc(ticketId)
      .update(item);
  }
}
