import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { neverError } from '@pc-helpers';
import { PcModelKind } from '@pc-types';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'pcModelKind',
})
export class PcModelKindPipe implements PipeTransform {
  constructor() {}

  public transform(value: PcModelKind | undefined | null): string | undefined {
    if (!value) {
      return undefined;
    }

    switch (value) {
      case 'competition':
        return 'Gewinnspiel';
      case 'event':
        return 'Event';
      case 'offer':
        return 'Angebot';
      case 'news':
        return 'News';
      default:
        throw neverError('', value);
    }
  }
}
