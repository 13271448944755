import { PcFirestoreConverter, PcTicket, PcTicketFirebase } from '@pc-types';
import firebase from 'firebase/compat/app';

export const ticketConverter: PcFirestoreConverter<PcTicketFirebase, PcTicket> =
  {
    fromFirestore: (input) => {
      if (!input || !input.created || !input.env || !input.category) {
        return undefined;
      }
      try {
        return {
          uid: input.uid,
          created: input.created.toDate(),
          modified: input.modified
            ? input.modified.toDate()
            : input.created.toDate(),
          title: input.title ?? '',
          description: input.description ?? '',
          category: input.category,
          image: input.image ?? '',
          author: input.author,
          location: input.location ?? undefined,
          level: input.level,
          enabled: input.enabled ?? false,
          ticketRef: input.ticketRef ?? undefined,
          status: input.status ?? 'processing',
          env: input.env,
        };
      } catch (err) {
        console.error(err);
        console.error('Wrong Input: ', input);
        return undefined;
      }
    },
    toFirestore: (input) => {
      if (!input || !input.category) {
        return undefined;
      }
      try {
        return {
          created: firebase.firestore.Timestamp.now(),
          modified: firebase.firestore.Timestamp.now(),
          title: input.title ?? '',
          description: input.description ?? '',
          category: input.category,
          image: input.image ?? '',
          author: input.author,
          location: input.location ?? undefined,
          level: input.level,
          enabled: input.enabled ?? false,
          ticketRef: input.ticketRef ?? undefined,
          status: input.status ?? 'processing',
          env: input.env ?? 'dev',
        };
      } catch (err) {
        console.error(err);
        console.error('Wrong Input: ', input);
        return undefined;
      }
    },
  };
