import {
  PcFirestoreFromConverter,
  PcShopFirebase,
  PcShopSimple,
} from '@pc-types';

export const shopSimpleConverter: PcFirestoreFromConverter<
  PcShopFirebase,
  PcShopSimple
> = {
  fromFirestore: (input) => {
    if (!input || !input.logo || !input.name) {
      return undefined;
    }
    return {
      uid: input.uid,
      name: input.name,
      logo: input.logo,
      shopCategories: input.shopCategories ?? [],
      logoBackgroundColor: input.logoBackgroundColor,
      categoryTypes: input.categoryTypes,
      reportsStart: input.reportsStart?.toDate(),
      reportBranche: input.reportBranche,
      reportSubtitle: input.reportSubtitle,
      areaTotal: input.areaTotal,
      reportComment: input.reportComment,
      reportEnabled: input.reportEnabled ?? true,
    };
  },
};
