import { Injectable } from '@angular/core';
import { PcNavigationItem } from '@pc-types';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  public visibleNavSub$ = new BehaviorSubject<PcNavigationItem | undefined>(
    undefined
  );

  constructor() {}
}
