import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { neverError } from '@pc-helpers';
import { PcBookingStatus } from '@pc-types';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'pcBookingRpStatus',
})
export class PcBookingRpStatusPipe implements PipeTransform {
  constructor() {}

  public transform(
    value: PcBookingStatus | undefined | null
  ): string | undefined {
    if (!value) {
      return undefined;
    }
    switch (value) {
      case 'confirmed':
        return 'Reserviert';
      case 'canceled':
        return 'Storniert';
      case 'completed':
        return 'Eingelöst';
      case 'missing':
        return 'Abgelaufen';
      case 'processing':
        return 'Reserviert';
      default:
        throw neverError('', value);
    }
  }
}
