import { PcIcon } from '@pc-types';

export function weatherIconConverter(weatherCode: number): PcIcon | undefined {
  switch (weatherCode) {
    case 113:
      return 'weather_sun';
    case 116:
      return 'weather_sun-cloud';
    case 119:
    case 122:
      return 'weather_cloud';
    case 143:
    case 248:
    case 260:
      return 'weather_fog';
    case 284:
    case 302:
    case 305:
    case 308:
    case 359:
    case 356:
      return 'weather_rain';
    case 176:
    case 185:
    case 263:
    case 266:
    case 281:
    case 293:
    case 296:
    case 299:
    case 353:
      return 'weather_rain-light';
    case 227:
    case 230:
    case 371:
    case 338:
    case 335:
    case 332:
    case 395:
    case 392:
      return 'weather_snow';
    case 179:
    case 182:
    case 311:
    case 368:
    case 329:
    case 326:
    case 323:
      return 'weather_snow-light';
    case 200:
    case 389:
    case 386:
      return 'weather_thunderstorm';
    case 317:
    case 362:
    case 374:
      return 'weather_rain-snow-light'; // Eis, Regen, Schnee "light"
    case 365:
    case 320:
    case 377:
    case 314:
    case 350:
      return 'weather_rain-snow'; // Eis, Regen, Schnee
  }
  console.warn('weatherCode ' + weatherCode + ' not recognized');
  return undefined;
}
