import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
import { formatDate } from '@pc-helpers';

@Injectable()
export class PcDateAdapter extends NativeDateAdapter {
  public getFirstDayOfWeek(): number {
    return 1;
  }
  public format(date: Date, displayFormat: unknown): string {
    if (typeof displayFormat === 'string') {
      return formatDate(date, displayFormat);
    }
    return date.toDateString();
  }
}
