import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  DocumentReference,
} from '@angular/fire/compat/firestore';
import {
  competitionConverter,
  competitionParticipantConverter,
} from '@pc-converter';
import {
  convertDateToTsMilliSeconds,
  getFetchEndDateForModels,
  getFirestorePathBasedOnPermissions,
} from '@pc-helpers';
import { EnvironmentService, StoreService } from '@pc-services';
import {
  PcCompetition,
  PcCompetitionFirebase,
  PcCompetitionParticipant,
  PcCompetitionParticipantFirebase,
  PcCompetitionReward,
  PcTsMilliSeconds,
  PC_COLLECTIONS,
} from '@pc-types';
import { compact, keys, nth, pick, shuffle } from 'lodash-es';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { ModelExtension } from './model.extension';

type PcRewardWinner = {
  uid: string;
  reward: PcCompetitionReward;
  userID?: string;
};

@Injectable({
  providedIn: 'root',
})
export class CompetitionsService extends ModelExtension<
  PcCompetition,
  PcCompetitionFirebase
> {
  protected referenceMainCollection = PC_COLLECTIONS.COMPETITIONS;
  protected referenceShopCollection = PC_COLLECTIONS.SHOP_COMPETITIONS;

  constructor(
    protected angularFirestore: AngularFirestore,
    protected store: StoreService,
    protected envService: EnvironmentService
  ) {
    super({
      angularFirestore,
      store,
      modelConverter: competitionConverter,
      envService,
    });
  }

  public fetchForFeed(): void {
    this.fetchItemsForFeed().subscribe((items) => {
      this.store.setFeedCompetitions(items);
    });
  }

  public fetchAll(): void {
    combineLatest([this.fetchItems(), this.store.myGlobalPermissions$])
      .pipe(distinctUntilChanged())
      .subscribe(([competitions, myGlobalPermissions]) => {
        // remove "collect" competitions for tenants (temporary)
        const filteredCompetitions =
          myGlobalPermissions?.role === 'tenant'
            ? competitions.filter((competition) => {
                return competition.competitionType !== 'collect';
              })
            : competitions;

        this.store.setCompetitions(filteredCompetitions);
      });
  }

  public getEndDateForFetch(): PcTsMilliSeconds {
    return convertDateToTsMilliSeconds(getFetchEndDateForModels());
  }

  public getEndDateForFetchFeed(): PcTsMilliSeconds {
    return convertDateToTsMilliSeconds(new Date());
  }

  public async update(
    modelId: string,
    item: Partial<PcCompetition>
  ): Promise<DocumentReference<PcCompetitionFirebase>> {
    if (!this.referenceWritePath || !this.referenceReadPath) {
      console.warn('referenceWritePath/referenceReadPath missing');
      return Promise.reject();
    }

    const firebaseReadDoc = this.angularFirestore
      .collection<PcCompetitionFirebase>(this.referenceReadPath)
      .doc(modelId);
    const firebaseWriteDoc = this.angularFirestore
      .collection<PcCompetitionFirebase>(this.referenceWritePath)
      .doc(modelId);

    try {
      await this.angularFirestore.firestore.runTransaction(
        async (transaction) => {
          const docSnapshot = await transaction.get(firebaseReadDoc.ref);
          if (!docSnapshot.exists) {
            throw 'Document does not exist!';
          }

          const docData = docSnapshot.data();

          item.modified = new Date();

          const itemForFirebase = pick(
            competitionConverter.toFirestore(item),
            keys(item)
          );

          // don't change the "assigned" property
          itemForFirebase.rewards = itemForFirebase.rewards?.map((reward) => {
            const assigned = docData?.rewards?.find(
              (r) => r.cockpitKey === reward.cockpitKey
            )?.assigned;

            return { ...reward, assigned };
          });

          transaction.set(firebaseWriteDoc.ref, itemForFirebase, {
            merge: true,
          });
        }
      );
    } catch (e) {
      console.warn('Transaction failed: ', e);
    }

    return firebaseReadDoc.ref;
  }

  public winners$(
    competitionId: string
  ): Observable<PcCompetitionParticipant[]> {
    return this.fetchParticipants(competitionId).pipe(
      map((participants) =>
        compact(participants.filter((participant) => !!participant.winner))
      )
    );
  }

  private getCalcPossibleWinnners(
    competition: PcCompetition
  ): Observable<PcCompetitionParticipant[] | undefined> {
    return this.fetchParticipants(competition.uid).pipe(
      map((participants) => {
        let possibleWinners: PcCompetitionParticipant[] = [];
        if (competition.competitionType === 'imageUpload') {
          possibleWinners = participants.filter((participant) => {
            return !!participant.imageUpload;
          });
        }
        if (competition.competitionType === 'answers') {
          const correctAnswers = competition.answers
            ?.filter((answer) => answer.correct)
            .map((answer) => answer.value);
          if (!correctAnswers || !correctAnswers.length) {
            console.warn('Gewinnspiel hat keine korrekte Antwort.');
            return;
          }

          possibleWinners = participants.filter((participant) => {
            if (!participant.answer) {
              return false;
            }
            return correctAnswers.indexOf(participant.answer) > -1;
          });
        }
        if (competition.competitionType === 'participation') {
          possibleWinners = participants;
        }
        if (competition.competitionType === 'collect') {
          possibleWinners = participants
            .map((participant) => {
              const collectedCount = participant.collected?.length ?? 0;
              const rewardPrimaryOffset = competition.rewardPrimaryOffset ?? 1;
              const rewardPrimaryRepeat = competition.rewardPrimaryRepeat ?? 1;

              const lotTotal = Math.ceil(
                (collectedCount - rewardPrimaryOffset + 1) / rewardPrimaryRepeat
              );

              const lots: PcCompetitionParticipant[] = [];

              for (let i = 0; i < lotTotal; i++) {
                lots.push(participant);
              }

              return lots;
            })
            .flat();
        }

        possibleWinners = possibleWinners.filter(
          (participant) => !participant.winner
        );

        return possibleWinners;
      })
    );
  }

  private getRewardWinners(
    possibleWinners: PcCompetitionParticipant[],
    rewards: PcCompetitionReward[]
  ): PcRewardWinner[] {
    let winnerIndex = 0;
    const winners: PcRewardWinner[] = [];

    const possibleWinnersShuffled = shuffle(possibleWinners);

    rewards.forEach((reward) => {
      for (let i = 0; i < reward.amount; i++) {
        let nextPossibleWinner: PcCompetitionParticipant | undefined;
        let isNewWinner = false;

        if (winnerIndex < possibleWinnersShuffled.length) {
          do {
            nextPossibleWinner = nth(possibleWinnersShuffled, winnerIndex);

            isNewWinner =
              !!nextPossibleWinner &&
              !winners.some(
                (winner) =>
                  nextPossibleWinner && winner.uid === nextPossibleWinner.uid
              );

            if (!isNewWinner) {
              nextPossibleWinner = undefined;
            }

            winnerIndex++;
          } while (
            !isNewWinner &&
            winnerIndex < possibleWinnersShuffled.length
          );
        }

        if (nextPossibleWinner) {
          winners.push({
            uid: nextPossibleWinner.uid,
            userID: nextPossibleWinner.userID,
            reward: { ...reward, amount: 1 },
          });
        }
      }
    });
    return winners;
  }

  public calculateWinners(
    competition: PcCompetition,
    rewards: PcCompetitionReward[],
    increaseRewards = false
  ): Observable<number> {
    const batch = this.angularFirestore.firestore.batch();

    const relevantRewards = rewards.filter(
      (reward) => reward.variant !== 'instant'
    );

    if (!relevantRewards.length) {
      return of(0);
    }

    return this.store.myGlobalPermissions$.pipe(
      switchMap((globalPermissions) => {
        // calculate winners order
        return this.getCalcPossibleWinnners(competition).pipe(
          map((possibleWinners) => {
            if (!possibleWinners?.length) {
              return 0;
            }

            // calculate rewards for winners
            const rewardWinners = this.getRewardWinners(
              possibleWinners,
              relevantRewards
            );

            // save rewards to winners

            rewardWinners.forEach((rewardWinner) => {
              const doc = this.angularFirestore
                .collection<PcCompetitionParticipantFirebase>(
                  `${PC_COLLECTIONS.USER_PROFILES}/${rewardWinner.userID}/${PC_COLLECTIONS.COMPETITIONS}`
                )
                .doc(competition.uid).ref;

              const participant: Partial<PcCompetitionParticipant> = {
                rewards: [{ ...rewardWinner.reward, amount: 1 }],
                winner: new Date(),
                modified: new Date(),
              };

              const participantFirebase = pick(
                competitionParticipantConverter.toFirestore(participant),
                keys(participant)
              );

              if (participantFirebase) {
                batch.set(doc, participantFirebase, { merge: true });
              }
            });

            // mark competition as evaluated

            const collection = getFirestorePathBasedOnPermissions(
              globalPermissions,
              PC_COLLECTIONS.SHOP_COMPETITIONS,
              PC_COLLECTIONS.COMPETITIONS
            );

            const competitionDoc = this.angularFirestore
              .collection<PcCompetitionFirebase>(`${collection}`)
              .doc(competition.uid).ref;

            const competitionUpdate: Partial<PcCompetition> = {
              winnersEvaluated: new Date(),
              modified: new Date(),
            };

            if (increaseRewards) {
              const competitionRewards = competition.rewards;
              const newRewards = relevantRewards;
              if (competitionRewards) {
                competitionUpdate.rewards = competitionRewards.map((reward) => {
                  const newAmount = newRewards.find(
                    (r) => r.name === reward.name
                  )?.amount;
                  return {
                    ...reward,
                    amount: newAmount
                      ? reward.amount + newAmount
                      : reward.amount,
                  };
                });
              }
            }

            const competitionUpdateFirebase = pick(
              competitionConverter.toFirestore(competitionUpdate),
              keys(competitionUpdate)
            );
            if (competitionUpdateFirebase) {
              batch.set(competitionDoc, competitionUpdateFirebase, {
                merge: true,
              });
            }

            batch.commit();

            return rewardWinners.length;
          })
        );
      })
    );
  }

  private fetchParticipants(
    competitionId: string
  ): Observable<PcCompetitionParticipant[]> {
    return this.store.myGlobalPermissions$.pipe(
      switchMap((globalPermissions) => {
        const collection = getFirestorePathBasedOnPermissions(
          globalPermissions,
          PC_COLLECTIONS.SHOP_COMPETITIONS,
          PC_COLLECTIONS.COMPETITIONS
        );
        return this.angularFirestore
          .collection<PcCompetitionParticipantFirebase>(
            `${collection}/${competitionId}/${PC_COLLECTIONS.COMPETITION_PARTICIPANTS}`
          )
          .valueChanges({ idField: 'uid' })
          .pipe(
            map((participants) => {
              return compact(
                participants
                  .map((participant) => {
                    return competitionParticipantConverter.fromFirestore(
                      participant
                    );
                  })
                  .filter((participant) => !!participant)
              );
            })
          );
      })
    );
  }
}
