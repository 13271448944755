import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, Query } from '@angular/fire/compat/firestore';
import { FirebaseError } from '@firebase/util';
import {
  cockpitUserConverter,
  userConverter,
  userPermissionConverter,
} from '@pc-converter';
import { StorageService, StoreService } from '@pc-services';
import {
  PcCockpitUser,
  PcCockpitUserAssociateRequest,
  PcCockpitUserFirebase,
  PcErrorFirebase,
  PcFirebaseFunctionDefaultResponse,
  PcGlobalPermissions,
  PcGlobalPermissionUsers,
  PcMapFirebase,
  PcPermissionsFirebase,
  PcUser,
  PcUserCreateFirebase,
  PcUserFirebase,
  PcUserManagementUser,
  PC_COCKPIT_REDIRECT_ROUTE_STORAGE_KEY,
  PC_COLLECTIONS,
  PC_FIREBASE_API_KEY,
  PC_FIREBASE_HTTP_FUNCTIONS,
} from '@pc-types';
import firebase from 'firebase/compat/app';
import { compact, first as _first, keys, pick } from 'lodash-es';
import { firstValueFrom, Observable } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  switchMap,
} from 'rxjs/operators';
import { SentryService } from '../sentry/sentry.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private auth: AngularFireAuth,
    private store: StoreService,
    private angularFirestore: AngularFirestore,
    private sentryService: SentryService,
    private storageService: StorageService,
    private httpClient: HttpClient
  ) {}

  private readonly errors: PcErrorFirebase[] = [
    {
      code: 'auth/invalid-email',
      message:
        'Der Benutzer wurde nicht gefunden. Überprüfen Sie ob die E-Mail Adresse gültig ist.',
    },
    {
      code: 'auth/user-not-found',
      message:
        'Der Benutzer wurde nicht gefunden. Überprüfen Sie ob die E-Mail Adresse gültig ist.',
    },
    {
      code: 'auth/wrong-password',
      message:
        'Das Passwort ist inkorrekt. Wenn Sie Ihr Passwort vergessen haben, nutzen Sie die "Passwort vergessen" Funktion.',
    },
  ];

  private getFirebaseErrorMessage(code: string): string | undefined {
    const foundError = this.errors.find((error) => {
      return code === error.code;
    });

    return foundError ? foundError.message : undefined;
  }

  public async login(email: string, pwd: string): Promise<void> {
    let userCredential = undefined;
    try {
      userCredential = await this.auth.signInWithEmailAndPassword(email, pwd);
    } catch (e) {
      if (!userCredential?.user) {
        return Promise.reject(e);
      }

      if (e instanceof FirebaseError) {
        const message = this.getFirebaseErrorMessage(e.code);
        if (message) {
          return Promise.reject(message);
        }
        return Promise.reject('Fehlercode: 743');
      }
      return Promise.reject('Fehlercode: 744');
    }

    const permissionsFirebase = await firstValueFrom(
      this.angularFirestore
        .collection<PcPermissionsFirebase>(PC_COLLECTIONS.USER_PERMISSIONS)
        .doc(userCredential?.user?.uid)
        .get()
        .pipe(first())
    );

    if (!permissionsFirebase?.exists) {
      this.auth.signOut();
      return Promise.reject(
        'Dieser Benutzer hat keine Cockpit-Berechtigung. Treten Sie mit dem Support in Kontakt.'
      );
    }

    return Promise.resolve();
  }

  public async logout(): Promise<void> {
    await this.auth.signOut();

    this.storageService.removeSession(PC_COCKPIT_REDIRECT_ROUTE_STORAGE_KEY);
    window.location.reload();
  }

  public async sendPasswordResetEmail(email: string): Promise<void> {
    try {
      await this.auth.sendPasswordResetEmail(email);
    } catch (e) {
      if (e instanceof FirebaseError) {
        const message = this.getFirebaseErrorMessage(e.code);
        if (message) {
          return Promise.reject(message);
        }
        return Promise.reject('Fehlercode: 743');
      }
      return Promise.reject('Fehlercode: 744');
    }

    return Promise.resolve();
  }

  private filterUser(user: firebase.User | null): boolean {
    return !!user;
  }

  public fetchMyUser(): void {
    this.auth.user
      .pipe(
        filter(this.filterUser),
        switchMap((authUser: firebase.User | null) =>
          this.angularFirestore
            .collection<PcUserFirebase>(PC_COLLECTIONS.USER_PROFILES)
            .doc(authUser?.uid)
            .valueChanges({ idField: 'uid' })
        ),
        map((user) => (user ? userConverter.fromFirestore(user) : undefined))
      )
      .subscribe((user) => {
        this.store.setUser(user);
        this.sentryService.setUser(
          user
            ? {
                uid: user.uid,
                email: user.email,
                firstname: user.firstname,
                lastname: user.lastname,
              }
            : null
        );
      });
  }

  public fetchMyUserFeatures(): void {
    this.auth.user
      .pipe(
        filter(this.filterUser),
        switchMap((authUser: firebase.User | null) =>
          this.angularFirestore
            .collection<PcPermissionsFirebase>(PC_COLLECTIONS.USER_PERMISSIONS)
            .doc(authUser?.uid)
            .valueChanges({ idField: 'uid' })
        )
      )
      .subscribe((permissions) => {
        const permissionsFirebase = permissions?.cockpit;
        this.store.setMyPermissionsFirebase(permissions ?? null);
        this.sentryService.setContext(
          'permissions',
          permissionsFirebase
            ? {
                global: permissionsFirebase,
                modules: Object.values(permissionsFirebase)[0],
              }
            : null
        );
        if (!permissionsFirebase) {
          this.logout();
        }
      });
  }

  public fetchAllCockpitUsers(): void {
    this.angularFirestore
      .collection<PcCockpitUserFirebase>(
        PC_COLLECTIONS.COCKPIT_USER_PROFILES,
        (ref) => {
          const query: Query = ref;
          return query;
        }
      )
      .valueChanges({ idField: 'uid' })
      .pipe(
        distinctUntilChanged(),
        map((users) => users.map(cockpitUserConverter.fromFirestore))
      )
      .subscribe((cockpitUsers) => {
        this.store.setCockpitUsers(compact(cockpitUsers));
      });
  }

  public deletePermissionsForUser(userId: string): Promise<void> {
    return this.angularFirestore
      .collection(PC_COLLECTIONS.USER_PERMISSIONS)
      .doc(userId)
      .delete();
  }

  // get all cockpit users with their permissions
  get getGlobalPermissionsOfAllUsers$(): Observable<PcGlobalPermissionUsers> {
    return this.angularFirestore
      .collection<PcPermissionsFirebase>(PC_COLLECTIONS.USER_PERMISSIONS)
      .valueChanges({ idField: 'userId' })
      .pipe(
        map((permissions) => {
          const permissionUsers: PcGlobalPermissionUsers = new Map();

          permissions.forEach((permission) => {
            const userId = permission.userId;
            const modulePermissions =
              userPermissionConverter.fromFirestore(permission);
            if (modulePermissions) {
              permissionUsers.set(userId, modulePermissions);
            }
          });

          return permissionUsers;
        })
      );
  }

  public getUsersByShopId$(shopId: string): Observable<PcCockpitUser[]> {
    return this.getGlobalPermissionsOfAllUsers$.pipe(
      map((users) => {
        const userIds: string[] = [];
        users.forEach((user, userId) => {
          if (user?.role === 'tenant' && user.shopId === shopId) {
            userIds.push(userId);
          }
        });
        return userIds;
      }),
      switchMap((userIds) => {
        return this.store.cockpitUsers$.pipe(
          map((users) => {
            return users
              ? users.filter((user) => user.uid && userIds.includes(user.uid))
              : [];
          })
        );
      })
    );
  }

  // get permissions of a single user by uid
  public getGlobalPermissionsByUserId$(
    userId: string
  ): Observable<PcGlobalPermissions | undefined> {
    return this.angularFirestore
      .collection<PcPermissionsFirebase>(PC_COLLECTIONS.USER_PERMISSIONS)
      .doc(userId)
      .get()
      .pipe(
        map((permissions) => {
          const permissionsFirebase = permissions.data();
          const modulePermissions = permissionsFirebase
            ? userPermissionConverter.fromFirestore(permissionsFirebase)
            : undefined;
          return modulePermissions;
        })
      );
  }

  // get permissions of a single user by uid
  public getUserById$(uid: string): Observable<PcUser | undefined> {
    return this.angularFirestore
      .collection<PcUserFirebase>(PC_COLLECTIONS.USER_PROFILES)
      .doc(uid)
      .get()
      .pipe(
        map((permissions) => {
          const userFirebase = permissions.data();
          const user = userFirebase
            ? userConverter.fromFirestore({ ...userFirebase, uid })
            : undefined;
          return user;
        })
      );
  }

  // get permissions of a single user by uid
  public getUserByEmail$(email: string): Observable<PcUser | undefined> {
    return this.angularFirestore
      .collection<PcUserFirebase>(PC_COLLECTIONS.USER_PROFILES, (ref) => {
        let query: Query = ref;
        query = query.where('email', '==', email);
        return query;
      })
      .valueChanges({ idField: 'uid' })
      .pipe(
        map((users) => _first(users)),
        map((user) => (user ? userConverter.fromFirestore(user) : undefined))
      );
  }

  public async updatePassword(newPassword: string): Promise<Error | void> {
    const authUser = await this.auth.currentUser;
    try {
      await authUser?.updatePassword(newPassword);
    } catch (e) {
      return e as Error;
    }
  }

  public async updateNotificationsSettings(
    notificationSettings: PcMapFirebase
  ): Promise<Error | void> {
    const user = await this.store.myUser();

    return this.angularFirestore
      .collection<PcUserFirebase>(PC_COLLECTIONS.USER_PROFILES)
      .doc(user?.uid)
      .update({ settingsCockpitNotificationMails: notificationSettings });
  }

  public async updateEmail(email: string): Promise<Error | void> {
    const authUser = await this.auth.currentUser;
    const userId = authUser?.uid;
    if (!userId || !authUser) {
      return Promise.reject('No userId');
    }
    try {
      await authUser.updateEmail(email);
      await this.updateProfile(userId, { email });
    } catch (e) {
      return e as Error;
    }
  }

  public async updateProfile(id: string, item: Partial<PcUser>): Promise<void> {
    const itemForFirebase = pick(userConverter.toFirestore(item), keys(item));

    return this.angularFirestore
      .collection<PcUserFirebase>(PC_COLLECTIONS.USER_PROFILES)
      .doc(id)
      .update(itemForFirebase);
  }

  public async createUser(
    user: PcUserManagementUser
  ): Promise<string | undefined> {
    return new Promise(async (resolve) => {
      const myUser = await this.store.myUser();
      if (!myUser) {
        console.warn('userId missing');
        resolve(undefined);
        return;
      }

      this.angularFirestore
        .collection<PcUserCreateFirebase>(PC_COLLECTIONS.USER_CREATE_REQUESTS)
        .add({
          ...user,
          author: this.angularFirestore.doc<PcCockpitUser>(
            `${PC_COLLECTIONS.COCKPIT_USER_PROFILES}/${myUser.uid}`
          ).ref,
          created: firebase.firestore.Timestamp.now(),
        });

      const sub = this.angularFirestore
        .collection<PcUserFirebase>(PC_COLLECTIONS.USER_PROFILES, (ref) => {
          let query: Query = ref;
          query = query.where('email', '==', user.email);
          return query;
        })
        .valueChanges({ idField: 'uid' })
        .subscribe((firebaseUsers) => {
          const firebaseUser = _first(firebaseUsers);
          if (firebaseUser) {
            sub.unsubscribe();
            resolve(firebaseUser.uid);
          }
        });
    });
  }

  public async updateGlobalPermissions(
    email: string,
    userId: string,
    globalPermissions: PcGlobalPermissions
  ): Promise<void> {
    const permissionsFirebase =
      userPermissionConverter.toFirestore(globalPermissions);

    if (!permissionsFirebase) {
      return;
    }

    if (globalPermissions.role === 'manager') {
      const firebaseUserSnapshot = await firstValueFrom(
        this.angularFirestore
          .collection<PcPermissionsFirebase>(
            PC_COLLECTIONS.USER_PERMISSIONS,
            (ref) => {
              let query: Query = ref;

              query = query.where('email', '==', email);

              return query;
            }
          )
          .get()
      );

      const firebaseUserDoc = _first(firebaseUserSnapshot.docs);

      if (firebaseUserDoc?.exists) {
        firebaseUserDoc.ref.update(permissionsFirebase);
      } else {
        this.angularFirestore
          .collection<PcPermissionsFirebase>(PC_COLLECTIONS.USER_PERMISSIONS)
          .doc(userId)
          .set(permissionsFirebase);
      }
    } else {
      const data: PcCockpitUserAssociateRequest = {
        key: PC_FIREBASE_API_KEY,
        email,
        shopID: globalPermissions.shopId,
        modules: globalPermissions.modules,
      };

      await firstValueFrom(
        this.httpClient.post<PcFirebaseFunctionDefaultResponse | undefined>(
          PC_FIREBASE_HTTP_FUNCTIONS.COCKPIT_USER_PERMISSIONS,
          {
            data,
          }
        )
      );
    }
  }
}
