import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { newsStatusConverter } from '@pc-converter';
import { StoreService } from '@pc-services';
import {
  PcModel,
  PcNewsStatus,
  PcNewsStatusFirebase,
  PcReadListItem,
  PcReadStatus,
  PcShop,
  PcShopSimple,
  PC_COLLECTIONS,
} from '@pc-types';
import { compact, difference, uniqBy } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class ReadService {
  private readList = new BehaviorSubject<PcReadListItem[]>([]);
  private subscribedNewsIds = new Set<string>();

  constructor(
    private angularFirestore: AngularFirestore,
    private store: StoreService
  ) {}

  get readList$(): Observable<PcReadListItem[]> {
    return this.readList.asObservable();
  }

  public subscribeToAllModelsWithMyShop(): void {
    this.store.myShop$
      .pipe(
        filter((myShop): myShop is PcShop => !!myShop),
        first()
      )
      .subscribe((myShop) => {
        combineLatest([
          this.store.feedNews$,
          this.store.news$,
          this.readList$,
        ]).subscribe(([feedNews, news, readList]) => {
          if (!feedNews || !news || !readList) {
            return;
          }
          const combinedNews = [...feedNews, ...news].filter((model) => {
            return model.readRequired;
          });

          const newsReadRequired = uniqBy(combinedNews, 'uid');

          const newsNotReadIds = difference(
            newsReadRequired.map((item) => item.uid),
            readList.map((item) => item.modelId)
          );

          newsNotReadIds.forEach((newsId) => {
            this.subscribeToModelWithShop(newsId, myShop);
          });
        });
      });
  }

  public getReadStatus$(model: PcModel): Observable<PcReadStatus> {
    return this.readList.pipe(
      map((readList) => {
        const foundInReadList = readList.find((m) => m.modelId === model.uid);
        if (foundInReadList) {
          return foundInReadList.readStatus;
        }
        return 'loading';
      })
    );
  }

  private subscribeToModelWithShop(newsId: string, shop: PcShop): void {
    if (this.subscribedNewsIds.has(newsId)) {
      return;
    }
    this.subscribedNewsIds.add(newsId);

    const readInfoList = this.readList.getValue();
    readInfoList.push({
      modelId: newsId,
      readStatus: 'loading',
    });
    this.readList.next(readInfoList);

    this.angularFirestore
      .collection<PcNewsStatusFirebase>(
        `${PC_COLLECTIONS.NEWS}/${newsId}/${PC_COLLECTIONS.NEWS_STATUS}`
      )
      .doc(shop.uid)
      .valueChanges({ idField: 'uid' })
      .pipe(
        map((newsStatus) =>
          newsStatus ? newsStatusConverter.fromFirestore(newsStatus) : undefined
        )
      )
      .subscribe((newsStatus) => {
        const readItem: PcReadListItem = {
          modelId: newsId,
          readStatus: newsStatus?.read ? 'read' : 'unread',
        };

        const readInfoList = this.readList.getValue();
        const foundIndex = readInfoList.findIndex((r) => r.modelId === newsId);
        if (foundIndex > -1) {
          readInfoList[foundIndex] = readItem;
        } else {
          readInfoList.push(readItem);
        }
        this.readList.next(readInfoList);
      });
  }

  public async markAsRead(model: PcModel): Promise<void> {
    const myShopId = await this.store.myShopId();
    if (!myShopId) {
      return;
    }
    const newsStatus: Partial<PcNewsStatus> = {
      read: true,
      created: new Date(),
      modified: new Date(),
      modelId: model.uid,
    };
    const newsStatusFirebase = newsStatusConverter.toFirestore(newsStatus);
    if (!newsStatusFirebase) {
      return;
    }

    const ref = this.angularFirestore.collection<PcNewsStatusFirebase>(
      `${PC_COLLECTIONS.NEWS}/${model.uid}/${PC_COLLECTIONS.NEWS_STATUS}`
    );
    await ref.doc(myShopId).set({
      ...newsStatusFirebase,
    });
  }

  public getAllReadShopsByModel(model: PcModel): Observable<PcShopSimple[]> {
    return combineLatest([
      this.angularFirestore
        .collection<PcNewsStatusFirebase>(
          `${PC_COLLECTIONS.NEWS}/${model.uid}/${PC_COLLECTIONS.NEWS_STATUS}`,
          (ref) => ref.where('read', '==', true)
        )
        .valueChanges({ idField: 'uid' }),
      this.store.shops$,
    ]).pipe(
      map(([newsStatus, shops]) => {
        return compact(
          newsStatus.map((doc) => {
            return shops?.find((shop) => shop.uid === doc.uid);
          })
        );
      })
    );
  }
}
