import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorModule } from '@pc-ui/error';
import { InputModule } from '@pc-ui/input/input.module';
import { LabelModule } from '@pc-ui/label';
import { InputDurationComponent } from './input-duration.component';

@NgModule({
  imports: [
    CommonModule,
    InputModule,
    LabelModule,
    FormsModule,
    ReactiveFormsModule,
    ErrorModule,
  ],
  declarations: [InputDurationComponent],
  exports: [InputDurationComponent],
})
export class InputDurationModule {}
