import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@pc-ui/button';
import { IconModule } from '@pc-ui/icon';
import { IndicatorForecastPromotionsComponent } from './indicator-forecast-promotions.component';

@NgModule({
  declarations: [IndicatorForecastPromotionsComponent],
  imports: [CommonModule, IconModule, ButtonModule],
  exports: [IndicatorForecastPromotionsComponent],
})
export class IndicatorForecastPromotionsModule {}
