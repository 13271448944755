import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { PcPipesModule } from '@pc-pipes';
import { ButtonModule } from '@pc-ui/button';
import { CheckboxModule } from '@pc-ui/checkbox';
import { DialogModule } from '@pc-ui/dialog';
import { IconModule } from '@pc-ui/icon';
import { InputModule } from '@pc-ui/input';
import { InputDurationModule } from '@pc-ui/input-duration/input-duration.module';
import { InputStepperModule } from '@pc-ui/input-stepper';
import { SpinnerModule } from '@pc-ui/spinner';
import { TimepickerModule } from '@pc-ui/timepicker';
import { KidsCheckinDialogComponent } from './kids-checkin-dialog.component';

@NgModule({
  declarations: [KidsCheckinDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    DialogModule,
    ButtonModule,
    InputModule,
    IconModule,
    SpinnerModule,
    CheckboxModule,
    InputStepperModule,
    TimepickerModule,
    InputDurationModule,
    PcPipesModule,
  ],
  exports: [KidsCheckinDialogComponent],
})
export class KidsCheckinDialogModule {}
