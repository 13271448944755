import {
  convertArrayToFirebaseMap,
  convertFirebaseMapToArray,
  convertTsSecondsToDate,
} from '@pc-helpers';
import {
  PcFirestoreConverter,
  PcNotificationOptionKey,
  PcUser,
  PcUserFirebase,
} from '@pc-types';
import firebase from 'firebase/compat/app';

export const userConverter: PcFirestoreConverter<PcUserFirebase, PcUser> = {
  fromFirestore: (input) => {
    if (!input) {
      return undefined;
    }

    return {
      uid: input.uid,
      country: input.country ?? '',
      email: input.email ?? '',
      firstname: input.firstname ?? '',
      lastname: input.lastname ?? '',
      gender: input.gender ?? '',
      image: input.image,
      modified: input.modified
        ? typeof input.modified === 'number'
          ? convertTsSecondsToDate(input.modified)
          : input.modified.toDate()
        : undefined,
      settingsCockpitNotificationMails: convertFirebaseMapToArray(
        input.settingsCockpitNotificationMails
      ),
    };
  },
  toFirestore: (input) => {
    if (!input) {
      return undefined;
    }
    return {
      country: input.country ?? '',
      email: input.email ?? '',
      firstname: input.firstname ?? '',
      lastname: input.lastname ?? '',
      gender: input.gender ?? '',
      image: input.image,
      created: firebase.firestore.Timestamp.now(),
      modified: firebase.firestore.Timestamp.now(),
      settingsCockpitNotificationMails:
        convertArrayToFirebaseMap<PcNotificationOptionKey>(
          input.settingsCockpitNotificationMails
        ),
    };
  },
};
