import { PcCockpitUser, PcModule, PcPermissionRole } from '@pc-types';

export function getFullName(user: PcCockpitUser): string {
  return `${user.firstname} ${user.lastname}`;
}

type PcModuleInfo = {
  key: PcModule;
  label: string;
  availableFor: PcPermissionRole[];
  showInUserManagementTable: boolean;
};

export const PC_MODULES_ALL: PcModuleInfo[] = [
  {
    key: 'content',
    label: 'Contentverwaltung',
    availableFor: ['manager', 'tenant'],
    showInUserManagementTable: false,
  },
  {
    key: 'reports',
    label: 'Umsatzmeldungen',
    availableFor: ['tenant', 'manager'],
    showInUserManagementTable: true,
  },
  {
    key: 'jobs',
    label: 'Jobs',
    availableFor: ['manager', 'tenant'],
    showInUserManagementTable: true,
  },
  {
    key: 'reservepickup',
    label: 'Reserve & Pickup',
    availableFor: ['tenant'],
    showInUserManagementTable: true,
  },
  {
    key: 'shop',
    label: 'Shop-Einstellungen',
    availableFor: ['manager', 'tenant'],
    showInUserManagementTable: true,
  },
  {
    key: 'usermanagement',
    label: 'Benutzerverwaltung',
    availableFor: ['manager'],
    showInUserManagementTable: true,
  },
  {
    key: 'insights',
    label: 'Insights',
    availableFor: ['manager', 'tenant'],
    showInUserManagementTable: true,
  },
  {
    key: 'announcements',
    label: 'Announcements',
    availableFor: ['manager'],
    showInUserManagementTable: true,
  },
  {
    key: 'forecastmanagement',
    label: 'Besucherprognose',
    availableFor: ['manager'],
    showInUserManagementTable: true,
  },
  {
    key: 'kids',
    label: 'Kinderland',
    availableFor: ['manager', 'tenant'],
    showInUserManagementTable: true,
  },
  {
    key: 'ticketing',
    label: 'Ticketing',
    availableFor: ['manager'],
    showInUserManagementTable: true,
  },
];

export function getUserManagementTableModules(): PcModuleInfo[] {
  return PC_MODULES_ALL.filter((module) => module.showInUserManagementTable);
}

export function getModulesByRole(role: PcPermissionRole): PcModuleInfo[] {
  return PC_MODULES_ALL.filter((module) => module.availableFor.includes(role));
}
