import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModelPropListItemComponent } from './model-prop-list-item.component';
import { IconModule } from '@pc-ui/icon';

@NgModule({
  declarations: [ModelPropListItemComponent],
  imports: [CommonModule, IconModule],
  exports: [ModelPropListItemComponent],
})
export class ModelPropListItemModule {}
