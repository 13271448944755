import { Injectable } from '@angular/core';
import { PcSentryEnv } from '@pc-types';
import {
  configureScope,
  init,
  instrumentAngularRouting,
  setContext,
  setUser,
  User,
} from '@sentry/angular';
import { Integrations as TracingIntegrations } from '@sentry/tracing';

@Injectable({
  providedIn: 'root',
})
export class SentryService {
  private isInitialized = false;
  constructor() {}

  private init(env: PcSentryEnv): void {
    init({
      environment: env,
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      release: require('package.json').version,
      dsn: 'https://9490cad95f304cc58d0879d5a5a450e1@o1071428.ingest.sentry.io/6069535',
      integrations: [
        new TracingIntegrations.BrowserTracing({
          tracingOrigins: ['cockpit-dev.pluscity.at', 'cockpit.pluscity.at'],
          routingInstrumentation: instrumentAngularRouting,
        }),
      ],
      tracesSampleRate: env === 'prod' ? 0.2 : 1.0,
    });
  }

  public enable(env: PcSentryEnv): void {
    if (!this.isInitialized) {
      this.init(env);
      this.isInitialized = true;
    }
  }

  public setUser(user: User | null): void {
    if (user) {
      setUser(user);
    } else {
      configureScope((scope) => scope.setUser(null));
    }
  }

  public setContext(
    name: string,
    context: { [key: string]: any } | null
  ): void {
    setContext(name, context);
  }

  public clear(): void {
    configureScope((scope) => scope.clear());
  }
}
