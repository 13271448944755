<div class="container-max container fixed bottom-0 left-0 right-0 z-120 bg-gray-900 py-4 xs:py-6">
  <div class="flex flex-col items-center justify-center xl:flex-row xl:space-x-10">
    <div class="editor-content">
      <span>
        Wir verwenden Cookies, um ein optimales Cockpit Erlebnis zu bieten. Wenn Sie auf “Akzeptieren” klicken, stimmen Sie der Verwendung aller Cookies gemäß unserer
        <button class="link-gold" type="button" (click)="onDataProtectionClicked()">Datenschutzerklärung</button>
        zu. Sie können jedoch die <button class="link-gold" type="button" (click)="onCookieSettingsClicked()">Cookie-Einstellungen</button> besuchen, um eine Zustimmung zu erteilen.
      </span>
    </div>

    <div class="mt-6 flex flex-col items-center space-y-2 xs:flex-row xs:space-y-0 xs:space-x-4 xl:mt-0">
      <button pc-button type="button" variant="outline" (click)="onDeclineAllClick()">Alles ablehnen</button>
      <button pc-button type="button" (click)="onAcceptAllClick()">Alles akzeptieren</button>
    </div>
  </div>
</div>
