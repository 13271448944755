<pc-dialog>
  <p class="typo-skinner mx-auto max-w-xs">{{ data.text }}</p>

  <div class="mt-4 flex items-center justify-center space-x-4">
    <button mat-dialog-close pc-button variant="link" type="button">
      {{ data.cancelText }}
    </button>
    <button pc-button [mat-dialog-close]="true" cdkFocusInitial type="button">
      {{ data.confirmText }}
    </button>
  </div>
</pc-dialog>
