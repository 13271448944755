import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { ButtonModule } from '@pc-ui/button';
import { DialogModule } from '@pc-ui/dialog';
import { IconModule } from '@pc-ui/icon';
import { ImageUploaderSingleModule } from '@pc-ui/image-uploader-single/image-uploader-single.module';
import { InputModule } from '@pc-ui/input';
import { MessageModule } from '@pc-ui/message';
import { ShopSelectModule } from '@pc-ui/shop-select';
import { CompetitionCollectLocationDialogComponent } from './competition-collect-location-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    DialogModule,
    MatDialogModule,
    InputModule,
    ImageUploaderSingleModule,
    IconModule,
    MessageModule,
    ButtonModule,
    ShopSelectModule,
  ],
  declarations: [CompetitionCollectLocationDialogComponent],
})
export class CompetitionCollectLocationDialogModule {}
