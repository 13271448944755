import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AutocompleteModule } from '@pc-ui/autocomplete';
import { ErrorModule } from '@pc-ui/error/error.module';
import { IconModule } from '@pc-ui/icon';
import { LabelModule } from '@pc-ui/label';
import { MessageModule } from '@pc-ui/message';
import { ShopLogoModule } from '@pc-ui/shop-logo/shop-logo.module';
import { ShopSelectComponent } from './shop-select.component';

@NgModule({
  declarations: [ShopSelectComponent],
  imports: [
    CommonModule,
    AutocompleteModule,
    IconModule,
    ErrorModule,
    ShopLogoModule,
    LabelModule,
    MessageModule,
  ],
  exports: [ShopSelectComponent],
})
export class ShopSelectModule {}
