import { convertSecondsToTimeString } from '@pc-helpers';
import { PcOpeningHoursFirebase } from '@pc-types';

export function getOpeningHoursLabel(
  openingHoursOfDay: PcOpeningHoursFirebase
): string {
  if (openingHoursOfDay.from === openingHoursOfDay.to) {
    return 'geschlossen';
  }

  return `${convertSecondsToTimeString(
    openingHoursOfDay.from
  )} - ${convertSecondsToTimeString(openingHoursOfDay.to)} Uhr`;
}
