import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { IconModule } from '@pc-ui/icon';
import { ImageModule } from '@pc-ui/image/image.module';
import { MessageModule } from '@pc-ui/message';
import { AutocompleteComponent } from './autocomplete.component';
@NgModule({
  declarations: [AutocompleteComponent],
  imports: [
    CommonModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    ImageModule,
    IconModule,
    MessageModule,
  ],
  exports: [AutocompleteComponent],
})
export class AutocompleteModule {}
