import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PcPipesModule } from '@pc-pipes';
import { ButtonModule } from '@pc-ui/button';
import { ContentcreateMenuModule } from '@pc-ui/contentcreate-menu';
import { IconModule } from '@pc-ui/icon';
import { NotificationsModule } from '@pc-ui/notifications/notifications.module';
import { SlideToggleModule } from '@pc-ui/slide-toggle';
import { UserItemModule } from '@pc-ui/user-item';
import { DirectivesModule } from '../../directives/directives.module';
import { AnnouncementEditHeaderComponent } from './components/announcement-edit-header/announcement-edit-header.component';
import { ContentEditHeaderComponent } from './components/content-edit-header/content-edit-header.component';
import { CookiebarComponent } from './components/cookiebar/cookiebar.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderNotificationItemComponent } from './components/header-notification-item/header-notification-item.component';
import { HeaderUserItemComponent } from './components/header-user-item/header-user-item.component';
import { HeaderComponent } from './components/header/header.component';
import { JobEditHeaderComponent } from './components/job-edit-header/job-edit-header.component';
import { NavListItemComponent } from './components/nav-list-item/nav-list-item.component';
import { NavListComponent } from './components/nav-list/nav-list.component';
import { PromotionEditHeaderComponent } from './components/promotion-edit-header/promotion-edit-header.component';
import { SubNavListComponent } from './components/sub-nav-list/sub-nav-list.component';
import { SupportBadgeComponent } from './components/support-badge/support-badge.component';

@NgModule({
  declarations: [
    HeaderComponent,
    NavListComponent,
    NavListItemComponent,
    HeaderUserItemComponent,
    HeaderNotificationItemComponent,
    ContentEditHeaderComponent,
    PromotionEditHeaderComponent,
    JobEditHeaderComponent,
    FooterComponent,
    AnnouncementEditHeaderComponent,
    CookiebarComponent,
    SubNavListComponent,
    SupportBadgeComponent,
  ],
  imports: [
    CommonModule,
    IconModule,
    ButtonModule,
    UserItemModule,
    PcPipesModule,
    ContentcreateMenuModule,
    NotificationsModule,
    SlideToggleModule,
    DirectivesModule,
    RouterModule,
  ],
  exports: [
    HeaderComponent,
    NavListComponent,
    NavListItemComponent,
    HeaderUserItemComponent,
    HeaderNotificationItemComponent,
    ContentEditHeaderComponent,
    PromotionEditHeaderComponent,
    JobEditHeaderComponent,
    FooterComponent,
    CookiebarComponent,
    SubNavListComponent,
    SupportBadgeComponent,
  ],
})
export class LayoutModule {}
