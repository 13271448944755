import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImageModule } from '@pc-ui/image';
import { UserImageComponent } from './user-image.component';

@NgModule({
  declarations: [UserImageComponent],
  imports: [CommonModule, ImageModule],
  exports: [UserImageComponent],
})
export class UserImageModule {}
