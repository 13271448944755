import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  PcActionRefType,
  PcActionRequest,
  PcActionType,
  PC_FIREBASE_HTTP_FUNCTIONS,
} from '@pc-types';
import { first, firstValueFrom } from 'rxjs';
import { StoreService } from '../store/store.service';

@Injectable({
  providedIn: 'root',
})
export class ActionService {
  constructor(private store: StoreService, private httpClient: HttpClient) {}

  public async create(
    action: PcActionType,
    refType: PcActionRefType,
    targetPath: string
  ): Promise<PcActionRequest | undefined> {
    const myUser = await this.store.myUser();
    const myShop = await firstValueFrom(this.store.myShop$.pipe(first()));

    if (!myUser) {
      return;
    }

    const data: PcActionRequest = {
      action,
      refType,
      target: targetPath,
      platform: 'cockpit',
      userID: myUser.uid,
      shopID: myShop?.uid,
    };

    return firstValueFrom(
      this.httpClient.post<PcActionRequest | undefined>(
        PC_FIREBASE_HTTP_FUNCTIONS.ACTIONS_LOG,
        {
          data,
        }
      )
    );
  }
}
