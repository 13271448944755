import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@pc-ui/button';
import { DialogModule } from '@pc-ui/dialog';
import { KidsCheckoutDialogComponent } from './kids-checkout-dialog.component';

@NgModule({
  imports: [CommonModule, DialogModule, ButtonModule],
  declarations: [KidsCheckoutDialogComponent],
  exports: [KidsCheckoutDialogComponent],
})
export class KidsCheckoutDialogModule {}
