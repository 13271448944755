import { DocumentReference } from '@angular/fire/compat/firestore';
import { convertDateToTsFirebase, dateFallback } from '@pc-helpers';
import {
  PcFirestoreConverter,
  PcReport,
  PcReportFirebase,
  PcShopFirebase,
} from '@pc-types';
import firebase from 'firebase/compat/app';
export const reportConverter: PcFirestoreConverter<PcReportFirebase, PcReport> =
  {
    fromFirestore: (input) => {
      if (
        !input ||
        !input.created ||
        !input.modified ||
        !input.date ||
        !input.shop ||
        !input.salesBrutto ||
        !input.contractId
      ) {
        return undefined;
      }
      try {
        return {
          uid: input.uid,
          created: input.created.toDate(),
          modified: input.modified?.toDate(),
          date: input.date.toDate(),
          salesBrutto: input.salesBrutto,
          salesNetto: input.salesNetto ?? 0,
          billsTotal: input.billsTotal ?? 0,
          shop: input.shop,
          env: input.env ?? 'dev',
          contractId: input.contractId,
        };
      } catch (err) {
        console.error(err);
        console.error('Wrong Input: ', input);
        return undefined;
      }
    },
    toFirestore: (input) => {
      if (!input) {
        return undefined;
      }
      try {
        return {
          created: firebase.firestore.Timestamp.now(),
          modified: firebase.firestore.Timestamp.now(),
          date: convertDateToTsFirebase(dateFallback(input.date)),
          salesBrutto: input.salesBrutto ?? 0,
          salesNetto: input.salesNetto ?? 0,
          billsTotal: input.billsTotal ?? 0,
          shop: input.shop as DocumentReference<PcShopFirebase>,
          env: input.env ?? 'dev',
          contractId: input.contractId ?? '',
        };
      } catch (err) {
        console.error(err);
        console.error('Wrong Input: ', input);
        return undefined;
      }
    },
  };
