import { Injectable } from '@angular/core';
import * as Papa from 'papaparse';
import { utils, writeFile } from 'xlsx';

@Injectable({
  providedIn: 'root',
})
export class CsvService {
  constructor() {}

  public exportCsv(data: unknown[], fileName: string): void {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', fileName + '.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  public exportXls(data: unknown[], fileName: string): void {
    const wb = utils.book_new();
    const ws = utils.json_to_sheet(data);
    const wscols = [{ wch: 30 }, { wch: 15 }, { wch: 30 }, { wch: 15 }];

    ws['!cols'] = wscols;

    utils.book_append_sheet(wb, ws, fileName);
    writeFile(wb, fileName + '.xlsx');
  }
}
