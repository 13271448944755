import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  PcDynamicLinkRequest,
  PcDynamicLinkResponse,
  PC_FIREBASE_HTTP_FUNCTIONS,
} from '@pc-types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LinkService {
  constructor(private httpClient: HttpClient) {}

  public getDynamicLink(
    data: PcDynamicLinkRequest
  ): Observable<PcDynamicLinkResponse | undefined> {
    return this.httpClient.post<PcDynamicLinkResponse | undefined>(
      PC_FIREBASE_HTTP_FUNCTIONS.DYNAMIC_LINKS_GENERATE,
      {
        data,
      }
    );
  }
}
