import { Params, Router } from '@angular/router';
import {
  PcNotification,
  PcNotificationGroup,
  PcNotificationOption,
} from '@pc-types';

export function navigateToCockpitRoute(
  router: Router,
  cockpitRoute: string
): void {
  const indexOfMark = cockpitRoute.indexOf('?');
  if (indexOfMark > -1) {
    const route = cockpitRoute.substring(0, indexOfMark);

    const params = new URLSearchParams(cockpitRoute.substring(indexOfMark));
    const queryParams: Params = [];
    params.forEach((b, a) => {
      queryParams[a] = b;
    });

    router.navigate([route], { queryParams });
  } else {
    router.navigate([cockpitRoute]);
  }
}

export function getNotificationGroups(
  notifications: PcNotification[]
): PcNotificationGroup[] {
  return [
    ...notifications
      .reduce((map, notification) => {
        const key = notification.ref.id + '-' + notification.trigger;

        const emptyGroup: PcNotificationGroup = {
          ...notification,
          unreadItems: [],
        };

        const group: PcNotificationGroup = map.get(key) || emptyGroup;

        if (!notification.read) {
          group.unreadItems.push(notification);
        }

        return map.set(key, group);
      }, new Map())
      .values(),
  ];
}

export const PC_NOTIFICATION_OPTIONS: PcNotificationOption[] = [
  {
    key: 'ticketCreated',
    label: 'Neues Ticket',
    description: 'E-Mail erhalten, sobald ein Ticket erstellt wurde.',
    permission: {
      role: 'manager',
    },
  },
  {
    key: 'bookingCreated',
    label: 'Neue Reservierung',
    description:
      'E-Mail erhalten, wenn eine Reservierung in ihrem Betrieb getätigt wurde.',
    permission: {
      isGastroBookingEnabled: true,
    },
  },
  {
    key: 'contentPublishedManager',
    label: 'Beitrag zur Freigabe',
    description: 'E-Mail erhalten, sobald ein Beitrag zur Freigabe bereit ist.',
    permission: {
      role: 'manager',
    },
  },
  {
    key: 'contentPublishedTenant',
    label: 'Beitrag freigegeben',
    description:
      'E-Mail erhalten, sobald einer Ihrer Beiträge freigegeben wurde.',
    permission: {
      role: 'tenant',
    },
  },
  {
    key: 'contentRejected',
    label: 'Beitrag abgelehnt',
    description:
      'E-Mail erhalten, sobald einer Ihrer Beiträge abgelehnt wurde.',
    permission: {
      role: 'tenant',
    },
  },
  {
    key: 'reservepickupReserved',
    label: 'Reserve & Pickup Kontingent',
    description:
      'E-Mail erhalten, sobald das Kontingent der Aktion beinahe verbraucht ist.',
    permission: {
      role: 'tenant',
      module: 'reservepickup',
    },
  },
  {
    key: 'promotionfeedbackSubmitted',
    label: 'Themenschwerpunkt Feedback erhalten',
    description:
      'E-Mail erhalten, sobald Feedback zu einem Themenschwerpunkt abgegeben wurde.',
    permission: {
      role: 'manager',
    },
  },
  {
    key: 'promotionInvited',
    label: 'Themenschwerpunkt Einladung',
    description:
      'E-Mail erhalten, sobald Sie zu einem Themenschwerpunkt eingeladen werden.',
    permission: {
      role: 'tenant',
    },
  },
  {
    key: 'feedbackInvited',
    label: 'Themenschwerpunkt Feedback abgeben',
    description:
      'E-Mail erhalten, sobald Sie Feedback zu einem Themenschwerpunkt abgeben können',
    permission: {
      role: 'tenant',
    },
  },
  {
    key: 'reportDue',
    label: 'Umsatzmeldung Erinnerung',
    description:
      'E-Mail erhalten, sobald die Umsatzmeldungen erstellt werden können',
    permission: {
      module: 'reports',
    },
  },
  {
    key: 'reportCreated',
    label: 'Neue Umsatzmeldung',
    description: 'E-Mail erhalten, sobald eine Umsatzmeldung erstellt wurde.',
    permission: {
      role: 'manager',
    },
  },
];
