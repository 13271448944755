import { PcCockpitUserDatasourceRow, PcModel } from '@pc-types';

export enum PC_DIALOG_SIZE {
  S = '25rem',
  M = '45rem',
}

export enum PC_DIALOG_MAX_SIZE {
  M = '94vw',
}

export type PcQuickactionMenuDialogData = PcCockpitUserDatasourceRow | PcModel;
