import {
  PcFirestoreConverter,
  PcPromotionFeedback,
  PcPromotionFeedbackFirebase,
} from '@pc-types';
import firebase from 'firebase/compat/app';

export const promotionFeedbackConverter: PcFirestoreConverter<
  PcPromotionFeedbackFirebase,
  PcPromotionFeedback
> = {
  fromFirestore: (input) => {
    if (!input || !input.created || !input.modified || !input.userId) {
      return undefined;
    }
    try {
      return {
        uid: input.uid,
        created: input.created.toDate(),
        modified: input.modified.toDate(),
        userId: input.userId,
        text: input.text ?? '',
        shopId: input.shopId,
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
  toFirestore: (input) => {
    if (!input) {
      return undefined;
    }
    try {
      return {
        created: firebase.firestore.Timestamp.now(),
        modified: firebase.firestore.Timestamp.now(),
        text: input.text ?? '',
        shopId: input.shopId,
        userId: input.userId,
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
};
