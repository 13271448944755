import { modelConverter } from '@pc-converter';
import {
  convertDateToTsSeconds,
  convertRangeDateToTsSeconds,
  convertRangeSecondsToDate,
  convertTsSecondsToDate,
  dateFallback,
} from '@pc-helpers';
import { PcEvent, PcEventFirebase, PcFirestoreConverter } from '@pc-types';

export const eventConverter: PcFirestoreConverter<PcEventFirebase, PcEvent> = {
  fromFirestore: (input) => {
    if (
      !input ||
      typeof input.start !== 'number' ||
      typeof input.end !== 'number'
    ) {
      return undefined;
    }
    try {
      const model = modelConverter.fromFirestore(input);
      if (!model) {
        return undefined;
      }
      return {
        ...model,
        dates: input.dates ? convertRangeSecondsToDate(input.dates) : [],
        kind: 'event',
        name: input.name ?? '',
        archive: input.archive ?? false,
        start: convertTsSecondsToDate(input.start),
        end: convertTsSecondsToDate(input.end),
        location: input.location,
        disclaimer: input.disclaimer,
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
  toFirestore: (input) => {
    if (!input) {
      return undefined;
    }
    try {
      const model = modelConverter.toFirestore(input);
      if (!model) {
        return undefined;
      }
      return {
        ...model,
        dates: convertRangeDateToTsSeconds(input.dates),
        name: input.name ?? '',
        archive: input.archive ?? false,
        start: convertDateToTsSeconds(dateFallback(input.start)),
        end: convertDateToTsSeconds(dateFallback(input.end)),
        location: input.location,
        disclaimer: input.disclaimer,
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
};
