import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { bookingConverter } from '@pc-converter';
import { StoreService } from '@pc-services';
import {
  PcBooking,
  PcBookingCancelRequest,
  PcBookingCancelResponse,
  PcBookingFirebase,
  PcShop,
  PcShopFirebase,
  PC_COLLECTIONS,
  PC_FIREBASE_HTTP_FUNCTIONS,
} from '@pc-types';
import { compact, keys, pick } from 'lodash-es';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  constructor(
    private angularFirestore: AngularFirestore,
    private store: StoreService,
    private httpClient: HttpClient
  ) {}

  public fetchAll(): void {
    this.store.myShop$
      .pipe(
        filter((myShop): myShop is PcShop => !!myShop),
        switchMap((myShop) => {
          const shopId = myShop.uid;
          return this.angularFirestore
            .collection<PcShopFirebase>(PC_COLLECTIONS.SHOPS)
            .doc(shopId)
            .collection<PcBookingFirebase>(PC_COLLECTIONS.BOOKINGS)
            .valueChanges({ idField: 'uid' });
        }),
        map((bookings) => {
          return bookings.map(bookingConverter.fromFirestore);
        }),
        distinctUntilChanged()
      )
      .subscribe((bookings) => {
        this.store.setBookings(compact(bookings));
      });
  }

  public async update(
    bookingInfo: { bookingId: string; shopId: string },
    item: Partial<PcBooking>
  ): Promise<void> {
    item.modified = new Date();

    const itemForFirebase = pick(
      bookingConverter.toFirestore(item),
      keys(item)
    );

    return this.angularFirestore
      .collection<PcShopFirebase>(PC_COLLECTIONS.SHOPS)
      .doc(bookingInfo.shopId)
      .collection<PcBookingFirebase>(PC_COLLECTIONS.BOOKINGS)
      .doc(bookingInfo.bookingId)
      .update(itemForFirebase);
  }

  public cancelBooking(
    data: PcBookingCancelRequest
  ): Observable<PcBookingCancelResponse | undefined> {
    return this.httpClient.post<PcBookingCancelResponse | undefined>(
      PC_FIREBASE_HTTP_FUNCTIONS.BOOKING_CANCEL,
      {
        data,
      }
    );
  }
}
