import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@pc-ui/icon';
import { IndicatorForecastWeatherComponent } from './indicator-forecast-weather.component';

@NgModule({
  declarations: [IndicatorForecastWeatherComponent],
  imports: [CommonModule, IconModule],
  exports: [IndicatorForecastWeatherComponent],
})
export class IndicatorForecastWeatherModule {}
