import {
  convertArrayToFirebaseMap,
  convertDateToTsFirebase,
  convertFirebaseMapToArray,
  dateFallback,
  getFormValue,
} from '@pc-helpers';
import {
  PcFirestoreConverter,
  PcFormConverter,
  PcPromotion,
  PcPromotionFirebase,
} from '@pc-types';
import firebase from 'firebase/compat/app';

export const promotionConverter: PcFirestoreConverter<
  PcPromotionFirebase,
  PcPromotion
> = {
  fromFirestore: (input) => {
    if (
      !input ||
      !input.start ||
      !input.end ||
      !input.created ||
      !input.modified ||
      !input.env
    ) {
      return undefined;
    }
    try {
      return {
        uid: input.uid,
        start: input.start.toDate(),
        end: input.end.toDate(),
        created: input.created.toDate(),
        modified: input.modified.toDate(),
        contentCounter: input.contentCounter ?? 0,
        contentList: input.contentList ?? [],
        title: input.title ?? '',
        description: input.description ?? '',
        enabled: input.enabled ?? false,
        shops: input.shops ?? [],
        env: input.env,
        feedbackEnabled: input.feedbackEnabled ?? false,
        tags: input.tags ? convertFirebaseMapToArray(input.tags) : [],
        feedbackDelivered: input.feedbackDelivered?.toDate(),
        feedbackDeliveredDates: input.feedbackDeliveredDates?.map((date) =>
          date.toDate()
        ),
        url: input.url,
        status: input.status ?? 'published',
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
  toFirestore: (input) => {
    if (!input) {
      return undefined;
    }
    try {
      return {
        created: firebase.firestore.Timestamp.now(),
        modified: firebase.firestore.Timestamp.now(),
        start: convertDateToTsFirebase(dateFallback(input.start)),
        end: convertDateToTsFirebase(dateFallback(input.end)),
        contentCounter: input.contentCounter ?? 0,
        contentList: input.contentList ?? [],
        title: input.title ?? '',
        description: input.description ?? '',
        enabled: input.enabled ?? false,
        shops: input.shops ?? [],
        env: input.env ?? 'dev',
        feedbackEnabled: input.feedbackEnabled ?? false,
        tags: input.tags ? convertArrayToFirebaseMap(input.tags) : {},
        feedbackDelivered:
          input.feedbackDelivered instanceof Date
            ? convertDateToTsFirebase(input.feedbackDelivered)
            : input.feedbackDelivered,
        feedbackDeliveredDates: input.feedbackDeliveredDates?.map((date) =>
          convertDateToTsFirebase(date)
        ),
        url: input.url,
        status: input.status ?? 'published',
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
};

export const promotionFormConverter: PcFormConverter<PcPromotion> = {
  fromForm: (form) => {
    const promotion: Partial<PcPromotion> = {};

    const title = getFormValue(form, 'title');
    if (typeof title === 'string') {
      promotion.title = title;
    }

    const description = getFormValue(form, 'description');
    if (typeof description === 'string') {
      promotion.description = description;
    }

    const start = getFormValue(form, 'start');
    if (start instanceof Date) {
      promotion.start = start;
    }

    const end = getFormValue(form, 'end');
    if (end instanceof Date) {
      promotion.end = end;
    }

    return promotion;
  },
};
