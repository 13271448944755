import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { eventConverter } from '@pc-converter';
import { convertDateToTsSeconds, getFetchEndDateForModels } from '@pc-helpers';
import { EnvironmentService, StoreService } from '@pc-services';
import {
  PcEvent,
  PcEventFirebase,
  PcTsSeconds,
  PC_COLLECTIONS,
} from '@pc-types';
import { ModelExtension } from './model.extension';

@Injectable({
  providedIn: 'root',
})
export class EventsService extends ModelExtension<PcEvent, PcEventFirebase> {
  protected referenceMainCollection = PC_COLLECTIONS.EVENTS;
  protected referenceShopCollection = PC_COLLECTIONS.SHOP_EVENTS;

  constructor(
    protected angularFirestore: AngularFirestore,
    protected store: StoreService,
    protected envService: EnvironmentService
  ) {
    super({
      angularFirestore,
      store,
      modelConverter: eventConverter,
      envService,
    });
  }

  public fetchAll(): void {
    this.fetchItems().subscribe((events) => {
      this.store.setEvents(events);
    });
  }

  public fetchForFeed(): void {
    this.fetchItemsForFeed().subscribe((events) => {
      this.store.setFeedEvents(events);
    });
  }

  public getEndDateForFetch(): PcTsSeconds {
    return convertDateToTsSeconds(getFetchEndDateForModels());
  }

  public getEndDateForFetchFeed(): PcTsSeconds {
    return convertDateToTsSeconds(new Date());
  }
}
