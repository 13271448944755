import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PcPipesModule } from '@pc-pipes';
import { ButtonModule } from '@pc-ui/button';
import { DialogModule } from '@pc-ui/dialog';
import { ForecastLegendModule } from '@pc-ui/forecast-legend';
import { ForecastVisitorsModule } from '@pc-ui/forecast-visitors';
import { IconModule } from '@pc-ui/icon';
import { IndicatorForecastHolidayModule } from '@pc-ui/indicator-forecast-holiday';
import { IndicatorForecastPromotionsModule } from '@pc-ui/indicator-forecast-promotions';
import { IndicatorForecastVisitorsModule } from '@pc-ui/indicator-forecast-visitors';
import { IndicatorForecastWeatherModule } from '@pc-ui/indicator-forecast-weather';
import { InputModule } from '@pc-ui/input';
import { UserDateItemModule } from '@pc-ui/user-date-item';
import { ForecastOverwriteDialogComponent } from './forecast-overwrite-dialog.component';

@NgModule({
  declarations: [ForecastOverwriteDialogComponent],
  imports: [
    CommonModule,
    DialogModule,
    IconModule,
    IndicatorForecastWeatherModule,
    IndicatorForecastVisitorsModule,
    IndicatorForecastPromotionsModule,
    IndicatorForecastHolidayModule,
    InputModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    ForecastLegendModule,
    ForecastVisitorsModule,
    PcPipesModule,
    UserDateItemModule,
  ],
})
export class ForecastOverwriteDialogModule {}
