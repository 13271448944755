import {
  PcFirestoreConverter,
  PcTicketLocation,
  PcTicketLocationFirebase,
} from '@pc-types';
import firebase from 'firebase/compat/app';

export const ticketLocationConverter: PcFirestoreConverter<
  PcTicketLocationFirebase,
  PcTicketLocation
> = {
  fromFirestore: (input) => {
    if (!input || !input.created) {
      return undefined;
    }
    try {
      return {
        uid: input.uid,
        name: input.name ?? '',
        enabled: input.enabled ?? false,
        geoMin: input.geoMin,
        geoMax: input.geoMax,
        geoArea: input.geoArea ?? '',
        created: input.created.toDate(),
        modified: input.modified
          ? input.modified.toDate()
          : input.created.toDate(),
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
  toFirestore: (input) => {
    if (!input) {
      return undefined;
    }
    try {
      return {
        name: input.name ?? '',
        enabled: input.enabled ?? false,
        geoMin: input.geoMin,
        geoMax: input.geoMax,
        geoArea: input.geoArea ?? '',
        created: firebase.firestore.Timestamp.now(),
        modified: firebase.firestore.Timestamp.now(),
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
};
