import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ErrorModule } from '@pc-ui/error/error.module';
import { IconModule } from '@pc-ui/icon';
import { LabelModule } from '@pc-ui/label';
import { TimepickerComponent } from './timepicker.component';

@NgModule({
  declarations: [TimepickerComponent],
  imports: [CommonModule, IconModule, FormsModule, ErrorModule, LabelModule],
  exports: [TimepickerComponent],
})
export class TimepickerModule {}
