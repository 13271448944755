import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModelPropListItemModule } from '@pc-ui/model-prop-list-item';
import { ModelPropListComponent } from './model-prop-list.component';

@NgModule({
  declarations: [ModelPropListComponent],
  imports: [CommonModule, ModelPropListItemModule],
  exports: [ModelPropListComponent],
})
export class ModelPropListModule {}
