import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { NavigationService, StorageService, StoreService } from '@pc-services';
import { PC_COCKPIT_REDIRECT_ROUTE_STORAGE_KEY } from '@pc-types';
import firebase from 'firebase/compat/app';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: StoreService,
    private auth: AngularFireAuth,
    private navigationService: NavigationService,
    private storageService: StorageService
  ) {}

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.auth.user.pipe(
      map((user: firebase.User | null) => {
        const url = state.url;
        if (user && url.startsWith('/start/')) {
          this.router.navigate(['/']);
        }
        if (!user && !url.startsWith('/start/')) {
          if (!window.history.state) {
            this.storageService.setSession(
              PC_COCKPIT_REDIRECT_ROUTE_STORAGE_KEY,
              url
            );
          }

          this.router.navigate(this.navigationService.getLoginPath());
        }

        return true;
      }),
      switchMap((value: boolean) => {
        if (!value) {
          return this.store.myGlobalPermissions$.pipe(
            map((hasPermission) => {
              if (!hasPermission) {
                this.auth.signOut().then(() => {
                  this.router.navigate(this.navigationService.getLoginPath());
                });
              }
              return true;
            })
          );
        } else {
          return of(true);
        }
      })
    );
  }
}
