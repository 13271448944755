import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackComponent } from './feedback.component';
import { IconModule } from '@pc-ui/icon';

@NgModule({
  declarations: [FeedbackComponent],
  imports: [CommonModule, IconModule],
  exports: [FeedbackComponent],
})
export class FeedbackModule {}
