import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { neverError } from '@pc-helpers';
import { PcShopOpeningHoursRequestStatus } from '@pc-types';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'pcOpeningHoursRequestStatusStatus',
})
export class PcOpeningHoursRequestStatusPipe implements PipeTransform {
  constructor() {}

  public transform(
    value: PcShopOpeningHoursRequestStatus | undefined | null
  ): string | undefined {
    if (!value) {
      return undefined;
    }
    switch (value) {
      case 'draft':
      case 'rejected':
        return 'Abgelehnt';
      case 'review':
        return 'Wartet auf Freigabe';
      case 'published':
        return 'Freigegeben';
      case 'completed':
        return 'Verarbeitet';
      case 'deleted':
        return 'Storniert';
      default:
        throw neverError('', value);
    }
  }
}
