import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { StoreService } from '@pc-services';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'pcTicketCategory',
})
export class PcTicketCategoryPipe implements PipeTransform {
  constructor(private store: StoreService) {}

  public transform(categoryId: string | undefined | null): string | undefined {
    if (!categoryId) {
      return undefined;
    }

    return this.store.ticketCategories
      .getValue()
      ?.find((category) => category.uid === categoryId)?.name;
  }
}
