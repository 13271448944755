import { MatDateFormats } from '@angular/material/core';

export enum PC_DATE_FORMAT {
  TIME = 'HH:mm',
  WEEKDAY = 'eeeeee',
  WEEKDAY_LONG = 'eeee',
  DAY = 'd',
  DATE_SHORT = 'dd.MM.',
  DATE = 'dd. MMM yyyy',
  MONTH_YEAR = 'MMMM yyyy',
  MONTH = 'MMMM',
  WEEKDAYDATE = 'eeeeee., dd. MMM yyyy',
  WEEKDAYDATE_LONG = 'eeeeee., dd. MMMM yyyy',
  AMUM = "'am' eeeeee., dd. MMM yyyy 'um' HH:mm 'Uhr'",
}

export const PC_APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'dd-MM-yyyy',
  },
  display: {
    dateInput: PC_DATE_FORMAT.WEEKDAYDATE,
    monthYearLabel: PC_DATE_FORMAT.MONTH_YEAR,
    dateA11yLabel: 'LL',
    monthYearA11yLabel: PC_DATE_FORMAT.MONTH_YEAR,
  },
};

export type PcMonthKey = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
