import { IntroJs } from 'intro.js';

export type PcTourState = {
  active: boolean;
  currentStep: number;
  boxHeight: number;
  steps: PcTourStep[];
};

export type PcTourStep = {
  step: number;
  elem?: Element;
  scrollable: boolean;
};

export const PC_TOUR_SEEN_STORAGE_KEY = 'pc-tour-seen';
export type PcTourSeenStorageType = boolean;

export type PcIntroJsExtended = IntroJs & {
  _introItems: PcIntroJsItem[];
};

export type PcIntroJsItem = {
  element: Element | null;
  position: 'bottom';
};
