import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentTableDialogMobileComponent } from './content-table-dialog-mobile.component';
import { ButtonModule } from '@pc-ui/button';
import { IconModule } from '@pc-ui/icon';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [ContentTableDialogMobileComponent],
  imports: [CommonModule, IconModule, ButtonModule, MatDialogModule],
  exports: [ContentTableDialogMobileComponent],
})
export class ContentTableDialogMobileModule {}
