import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClipboardCopyComponent } from './clipboard-copy.component';
import { InputModule } from '@pc-ui/input';
import { ButtonModule } from '@pc-ui/button';
import { FeedbackModule } from '@pc-ui/feedback';
import { IconModule } from '@pc-ui/icon';

@NgModule({
  declarations: [ClipboardCopyComponent],
  imports: [
    CommonModule,
    InputModule,
    ButtonModule,
    FeedbackModule,
    IconModule,
  ],
  exports: [ClipboardCopyComponent],
})
export class ClipboardCopyModule {}
