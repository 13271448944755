import { convertDateToTsFirebase, dateFallback } from '@pc-helpers';
import {
  PcFirestoreConverter,
  PcShopOpeningHoursRequest,
  PcShopOpeningHoursRequestFirebase,
} from '@pc-types';
import firebase from 'firebase/compat/app';
import { statusFeedbackConverter } from './status-feedback.converter';

export const shopOpeningHoursRequestConverter: PcFirestoreConverter<
  PcShopOpeningHoursRequestFirebase,
  PcShopOpeningHoursRequest
> = {
  fromFirestore: (input) => {
    if (!input || !input.created || !input.author) {
      return undefined;
    }
    try {
      return {
        uid: input.uid,
        created: input.created.toDate(),
        modified: input.modified
          ? input.modified.toDate()
          : input.created.toDate(),
        startDate: input.startDate?.toDate(),
        holidays: input.holidays
          ? input.holidays.map((holiday) => {
              return {
                ...holiday,
                date: holiday.date.toDate(),
              };
            })
          : [],
        status: input.status ?? 'draft',
        statusFeedback: statusFeedbackConverter.fromFirestore(
          input.statusFeedback
        ),
        env: input.env ?? 'dev',
        openingHours: input.openingHours,
        author: input.author,
        comment: input.comment,
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
  toFirestore: (input) => {
    if (!input) {
      return undefined;
    }

    try {
      return {
        created: firebase.firestore.Timestamp.now(),
        modified: firebase.firestore.Timestamp.now(),
        startDate: convertDateToTsFirebase(dateFallback(input.startDate)),
        holidays: input.holidays
          ? input.holidays.map((holiday) => {
              return {
                ...holiday,
                date: convertDateToTsFirebase(dateFallback(holiday.date)),
              };
            })
          : [],
        status: input.status ?? 'draft',
        statusFeedback: statusFeedbackConverter.toFirestore(
          input.statusFeedback
        ),
        env: input.env ?? 'dev',
        openingHours: input.openingHours,
        author: input.author,
        comment: input.comment,
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
};
