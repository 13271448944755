import { PcContentcreateMenuItem } from '@pc-types';

export function getContentCreateMenuItems(
  isOfferMenuVisible: boolean
): PcContentcreateMenuItem[] {
  const items: PcContentcreateMenuItem[] = [
    {
      label: 'News anlegen',
      icon: 'content_news',
      data: 'news',
    },
    {
      label: 'Gewinnspiel anlegen',
      icon: 'content_competition',
      data: 'competition',
    },
    {
      label: 'Angebot anlegen',
      icon: 'content_offer',
      data: 'offer',
    },
    {
      label: 'Event anlegen',
      icon: 'content_event',
      data: 'event',
    },
  ];

  if (isOfferMenuVisible) {
    items.push({
      label: 'Wochenmenü anlegen',
      icon: 'offer-menu',
      data: 'offer_menu',
    });
  }

  items.push({
    label: 'Gutschein anlegen',
    icon: 'offer-coupon',
    data: 'offer_coupon',
  });

  return items;
}
