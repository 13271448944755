import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { logEmailConverter } from '@pc-converter';
import { PcLogEmail, PcLogEmailFirebase, PC_COLLECTIONS } from '@pc-types';

@Injectable({
  providedIn: 'root',
})
export class MailService {
  constructor(private angularFirestore: AngularFirestore) {}

  public async send(email: PcLogEmail): Promise<void> {
    email.created = new Date();
    email.modified = new Date();

    const itemForFirebase = logEmailConverter.toFirestore(email);
    if (!itemForFirebase) {
      return Promise.reject();
    }

    const ref = this.angularFirestore.collection<PcLogEmailFirebase>(
      PC_COLLECTIONS.LOG_EMAIL
    );

    await ref.add(itemForFirebase);
    return Promise.resolve();
  }
}
