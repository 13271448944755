import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { PC_ENVIRONMENT } from '@pc-environment';
import { AppModule } from './app/app.module';

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    introJs: any;
  }
}

if (PC_ENVIRONMENT.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule, { ngZoneEventCoalescing: true })
  .catch((err) => console.error(err));

// if (environment.env === 'dev') {
//   var script = document.createElement('script');
//   script.type = 'text/javascript';
//   script.src = 'https://cdn.tailwindcss.com';
//   document.head.appendChild(script);
// }
