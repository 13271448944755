import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { ButtonModule } from '@pc-ui/button';
import { DatepickerModule } from '@pc-ui/datepicker';
import { DialogModule } from '@pc-ui/dialog';
import { TextareaModule } from '@pc-ui/textarea';
import { ShopOpeningHoursRequestConfirmDialogComponent } from './shop-opening-hours-request-confirm-dialog.component';

@NgModule({
  declarations: [ShopOpeningHoursRequestConfirmDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    DatepickerModule,
    TextareaModule,
  ],
  exports: [ShopOpeningHoursRequestConfirmDialogComponent],
})
export class ShopOpeningHoursRequestConfirmDialogModule {}
