import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ErrorModule } from '@pc-ui/error/error.module';
import { LabelModule } from '@pc-ui/label';
import { RadioModule } from '@pc-ui/radio/radio.module';
import { RadioGroupComponent } from './radio-group.component';

@NgModule({
  declarations: [RadioGroupComponent],
  imports: [CommonModule, RadioModule, FormsModule, ErrorModule, LabelModule],
  exports: [RadioGroupComponent],
})
export class RadioGroupModule {}
