import {
  PcVisitorForecastItem,
  PcVisitorForecastType,
  PC_VISITORS_FORECAST_TYPES,
} from '@pc-types';
import { isToday } from 'date-fns';
import { reverse, sortBy } from 'lodash-es';

export function getVisitorForecastText(
  visitorForecast: number,
  day: Date
): string {
  return `Die ${
    isToday(day) ? 'für heute' : ''
  } prognostizierte Auslastung ist ${
    getVisitorForecastItemByVisitors(visitorForecast).label
  }.`;
}

export const PC_VISITOR_FORECAST_ITEMS: {
  [key in PcVisitorForecastType]: PcVisitorForecastItem;
} = {
  normal: {
    label: 'normal',

    gradient: 'from-green-100 to-green-400',
    start: 0,
  },
  high: {
    label: 'hoch',

    gradient: 'from-yellow-100 to-yellow-500',
    start: 0.5,
  },
  veryhigh: {
    label: 'besonders hoch',
    gradient: 'from-red-100 to-red-300',
    start: 0.9,
  },
};

export const PC_VISTORS_FORECAST_ITEMS_ARRAY = PC_VISITORS_FORECAST_TYPES.map(
  (type) => PC_VISITOR_FORECAST_ITEMS[type]
);

export function getVisitorForecastItemByVisitors(
  visitorForecast: number
): PcVisitorForecastItem {
  const types = PC_VISITORS_FORECAST_TYPES;
  const items = types.map((type) => PC_VISITOR_FORECAST_ITEMS[type]);

  let foundItem: PcVisitorForecastItem | undefined;

  reverse(sortBy(items, 'start')).forEach((item) => {
    if (!foundItem && visitorForecast >= item.start) {
      foundItem = item;
    }
  });

  return foundItem ?? PC_VISITOR_FORECAST_ITEMS['normal'];
}

export const PC_VISITORS_TOTAL = 47000;

export function getVisitorForecastNumber(visitorsPercent: number): number {
  return Math.round(PC_VISITORS_TOTAL * visitorsPercent);
}

export function getPercentageByVisitorNumber(visitorNumber: number): number {
  return visitorNumber / PC_VISITORS_TOTAL;
}
