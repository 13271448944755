import {
  PcAnnouncement,
  PcAnnouncementStatus,
  PcShopCategoryType,
} from '@pc-types';
import { isFuture, isPast } from 'date-fns';
import { neverError } from './never.helper';

export function isAnnouncementActive(announcement: PcAnnouncement): boolean {
  return (
    isPast(announcement.start) &&
    isFuture(announcement.end) &&
    announcement.enabled
  );
}

export function getAnnouncementStatus(
  announcement: PcAnnouncement
): PcAnnouncementStatus {
  if (isPast(announcement.end)) {
    return 'past';
  } else {
    return announcement.enabled ? 'active' : 'inactive';
  }
}

export function getShopCategoryTypeLabel(
  categoryType: PcShopCategoryType
): string {
  switch (categoryType) {
    case 'shops':
      return 'Shops';
    case 'entertainment':
      return 'Entertainment';
    case 'gastronomy':
      return 'Gastro-Betriebe';
    case 'kids':
      return 'Kids & Family';
    case 'health':
      return 'Fitness & Health';
    default:
      throw neverError('', categoryType);
  }
}
