import { convertTsMilliSecondsToDate } from '@pc-helpers';
import {
  PcFirestoreFromConverter,
  PcHoliday,
  PcHolidayFirebase,
} from '@pc-types';

export const holidayConverter: PcFirestoreFromConverter<
  PcHolidayFirebase,
  PcHoliday
> = {
  fromFirestore: (input) => {
    if (
      !input ||
      typeof input.from === 'undefined' ||
      typeof input.to === 'undefined' ||
      !input.name
    ) {
      return undefined;
    }
    try {
      if (!input.date) {
        return undefined;
      }
      return {
        uid: input.uid,
        from: input.from,
        to: input.to,
        date: convertTsMilliSecondsToDate(input.date),
        name: input.name,
        description: input.description,
        openedShops: input.openedShops,
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
};
