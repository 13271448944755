import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SelectionListDialogModule } from '@pc-ui/selection-list-dialog';
import { TourDialogModule } from '@pc-ui/tour-dialog/tour-dialog.module';
import { AccountRequestDialogModule } from './account-request-dialog';
import { AnnouncementDialogModule } from './announcement-dialog';
import { BookingCancelDialogModule } from './booking-cancel-dialog';
import { CalendardayDialogModule } from './calendarday-dialog';
import { CompetitionCollectLocationDialogModule } from './competition-collect-location-dialog';
import { CompetitionRewardsDialogModule } from './competition-rewards-dialog';
import { ConfirmDialogModule } from './confirm-dialog/confirm-dialog.module';
import { ContentConfirmDialogModule } from './content-confirm-dialog';
import { ContentTableDialogMobileModule } from './content-table-dialog-mobile';
import { DynamiclinkDialogModule } from './dynamiclink-dialog';
import { ForecastOverwriteDialogModule } from './forecast-overwrite-dialog/forecast-overwrite-dialog.module';
import { ImagePreviewDialogModule } from './image-preview-dialog/image-preview-dialog.module';
import { KidsCheckinDialogModule } from './kids-checkin-dialog';
import { KidsCheckoutDialogModule } from './kids-checkout-dialog/kids-checkout-dialog.module';
import { LoginDialogModule } from './login-dialog';
import { RejectDialogModule } from './reject-dialog';
import { RejectInfoDialogModule } from './reject-info-dialog';
import { ReportDialogModule } from './report-dialog/report-dialog.module';
import { ReservepickupCancelDialogModule } from './reservepickup-cancel-dialog';
import { ReservepickupCapacityDialogModule } from './reservepickup-capacity-dialog';
import { ReservepickupRedeemDialogModule } from './reservepickup-redeem-dialog';
import { SalesReportDialogModule } from './sales-report-dialog/sales-report-dialog.module';
import { ShopOpeningHoursRequestConfirmDialogModule } from './shop-opening-hours-request-confirm-dialog';
import { UserManagementCreateDialogModule } from './user-management-create-dialog';
import { UserManagementTableDialogModule } from './user-management-table-dialog';

@NgModule({
  imports: [
    AccountRequestDialogModule,
    AnnouncementDialogModule,
    BookingCancelDialogModule,
    CalendardayDialogModule,
    CommonModule,
    CompetitionRewardsDialogModule,
    ConfirmDialogModule,
    ContentConfirmDialogModule,
    ContentTableDialogMobileModule,
    UserManagementTableDialogModule,
    DynamiclinkDialogModule,
    ForecastOverwriteDialogModule,
    ImagePreviewDialogModule,
    LoginDialogModule,
    RejectDialogModule,
    RejectInfoDialogModule,
    ReportDialogModule,
    ReservepickupCancelDialogModule,
    ReservepickupCapacityDialogModule,
    ReservepickupRedeemDialogModule,
    SelectionListDialogModule,
    TourDialogModule,
    ShopOpeningHoursRequestConfirmDialogModule,
    UserManagementCreateDialogModule,
    KidsCheckinDialogModule,
    KidsCheckoutDialogModule,
    CompetitionCollectLocationDialogModule,
    SalesReportDialogModule,
  ],
})
export class DialogAllModule {}
