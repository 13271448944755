import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IndicatorForecastVisitorsModule } from '@pc-ui/indicator-forecast-visitors';
import { ForecastLegendComponent } from './forecast-legend.component';

@NgModule({
  declarations: [ForecastLegendComponent],
  imports: [CommonModule, IndicatorForecastVisitorsModule],
  exports: [ForecastLegendComponent],
})
export class ForecastLegendModule {}
