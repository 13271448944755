import { Injectable } from '@angular/core';
import { DocumentReference } from '@angular/fire/compat/firestore';
import { getDynamicLinkRequestForModel, neverError } from '@pc-helpers';
import {
  CompetitionsService,
  EnvironmentService,
  EventsService,
  LinkService,
  NewsService,
  OfferService,
} from '@pc-services';
import {
  PcModel,
  PcModelFirebase,
  PcModelForDynamicLink,
  PcModelPartialWithKind,
  PcModelStatusResponse,
} from '@pc-types';
import { firstValueFrom } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ModelService {
  constructor(
    private newsService: NewsService,
    private competitionService: CompetitionsService,
    private eventService: EventsService,
    private offerService: OfferService,
    private envService: EnvironmentService,
    private linkService: LinkService
  ) {}

  public delete(item: PcModel): Promise<void> {
    switch (item.kind) {
      case 'competition':
        return this.competitionService.delete(item.uid);
      case 'event':
        return this.eventService.delete(item.uid);
      case 'news':
        return this.newsService.delete(item.uid);
      case 'offer':
        return this.offerService.delete(item.uid);
      default:
        throw neverError('', item);
    }
  }

  public setEnabled(item: PcModel, enabled: boolean): Promise<void> {
    switch (item.kind) {
      case 'competition':
        return this.competitionService.setEnabled(item.uid, enabled);
      case 'event':
        return this.eventService.setEnabled(item.uid, enabled);
      case 'news':
        return this.newsService.setEnabled(item.uid, enabled);
      case 'offer':
        return this.offerService.setEnabled(item.uid, enabled);
      default:
        throw neverError('', item);
    }
  }

  public setStatus(
    model: PcModel,
    status: PcModelStatusResponse
  ): Promise<DocumentReference<Partial<PcModelFirebase>>> {
    const item: PcModelPartialWithKind = {
      kind: model.kind,
    };
    item.status = status.status;
    item.statusFeedback = status.statusFeedback;
    item.modified = new Date();

    if (item.status === 'published') {
      if (model.kind === 'offer' && model.type === 'reservepickup') {
        item.readonly = true;
      }

      if (item.kind === 'competition') {
        item.readonlyCompetitionType = true;
      }
    }

    const kind = item.kind;
    switch (kind) {
      case 'competition':
        return this.competitionService.update(model.uid, item);
      case 'event':
        return this.eventService.update(model.uid, item);
      case 'news':
        return this.newsService.update(model.uid, item);
      case 'offer':
        return this.offerService.update(model.uid, item);
      default:
        throw neverError('', kind);
    }
  }

  public async update(
    modelId: string,
    item: PcModelPartialWithKind
  ): Promise<DocumentReference<Partial<PcModelFirebase>>> {
    switch (item.kind) {
      case 'competition':
        return this.competitionService.update(modelId, item);
      case 'event':
        return this.eventService.update(modelId, item);
      case 'news':
        return this.newsService.update(modelId, item);
      case 'offer':
        return this.offerService.update(modelId, item);
      default:
        throw neverError('', item);
    }
  }

  public create(
    item: PcModelPartialWithKind
  ): Promise<DocumentReference<PcModelFirebase>> {
    switch (item.kind) {
      case 'competition':
        return this.competitionService.create(item);
      case 'event':
        return this.eventService.create(item);
      case 'news':
        return this.newsService.create(item);
      case 'offer':
        return this.offerService.create(item);
      default:
        throw neverError('', item);
    }
  }

  public async generateDynamicLink(
    model: PcModelForDynamicLink
  ): Promise<string | undefined> {
    const request = getDynamicLinkRequestForModel(model, this.envService);
    const linkResponse = await firstValueFrom(
      this.linkService.getDynamicLink(request).pipe(first())
    );
    const result = linkResponse?.result;

    if (result) {
      const item: PcModelPartialWithKind = {
        kind: model.kind,
        url: result,
      };
      await this.update(model.uid, item);
    }

    return result;
  }
}
