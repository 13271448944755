import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { ButtonModule } from '@pc-ui/button';
import { DialogModule } from '@pc-ui/dialog';
import { InputModule } from '@pc-ui/input';
import { ReservepickupCapacityDialogComponent } from './reservepickup-capacity-dialog.component';

@NgModule({
  declarations: [ReservepickupCapacityDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    ButtonModule,
    InputModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
  ],
  exports: [ReservepickupCapacityDialogComponent],
})
export class ReservepickupCapacityDialogModule {}
