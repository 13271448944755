import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';

export function getFormValue(form: UntypedFormGroup, name: string): unknown {
  return form.get(name)?.value;
}

export function scrollToError(formContainer: HTMLElement): void {
  const errorElement = formContainer.querySelector('.js-error-scroll');
  if (errorElement) {
    errorElement.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }
}

type PcErrorMessage = {
  control: string;
  controlValue: unknown;
  error: string;
  errorValue: string;
};

export function getFormValidationErrors(
  form: UntypedFormGroup
): PcErrorMessage[] {
  const result: PcErrorMessage[] = [];

  Object.keys(form.controls).forEach((key) => {
    const formProperty = form.get(key);

    if (formProperty instanceof UntypedFormGroup) {
      result.push(...getFormValidationErrors(formProperty));
    } else if (formProperty instanceof UntypedFormArray) {
      formProperty.controls.forEach((element) => {
        if (element.errors) {
          result.push(...getFormValidationErrors(element as UntypedFormGroup));
        }
      });
    }

    const controlErrors = formProperty?.errors;

    if (controlErrors) {
      Object.keys(controlErrors).forEach((keyError) => {
        result.push({
          control: key,
          controlValue: formProperty.value,
          error: keyError,
          errorValue: controlErrors[keyError],
        });
      });
    }
  });

  return result;
}
