import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { neverError } from '@pc-helpers';
import { PcCompetitionCollectLocation, PcCompetitionType } from '@pc-types';

export function getCompetitionTypeLabel(level: PcCompetitionType): string {
  switch (level) {
    case 'participation':
      return 'Verlosung';
    case 'scratch':
      return 'Rubbelgewinnspiel';
    // case 'text':
    //   return 'Schätzspiel';
    case 'answers':
      return 'Fragenspiel';
    case 'imageUpload':
      return 'Uploadspiel';
    case 'collect':
      return 'Sammelpass';
    default:
      throw neverError('', level);
  }
}

export function getFormGroupForCollectLocation(
  collectLocation: PcCompetitionCollectLocation | undefined
): UntypedFormGroup {
  return new UntypedFormGroup(
    {
      code: new UntypedFormControl(collectLocation?.code, []),
      kind: new UntypedFormControl(collectLocation?.kind, []),
      title: new UntypedFormControl(
        collectLocation?.kind === 'custom' ? collectLocation.title : undefined,
        []
      ),
      image: new UntypedFormControl(
        collectLocation?.kind === 'custom' ? collectLocation?.image : undefined,
        []
      ),
      imageBgColor: new UntypedFormControl(
        collectLocation?.kind === 'custom'
          ? collectLocation.imageBgColor
          : undefined
      ),
      shopRef: new UntypedFormControl(
        collectLocation?.kind === 'shop' ? collectLocation.shopRef : undefined
      ),
    },
    []
  );
}
