import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { ButtonModule } from '@pc-ui/button';
import { DialogModule } from '@pc-ui/dialog';
import { InputModule } from '@pc-ui/input';
import { MessageModule } from '@pc-ui/message';
import { SelectionListDialogListModule } from '@pc-ui/selection-list-dialog-list';
import { SelectionListDialogComponent } from './selection-list-dialog.component';

@NgModule({
  declarations: [SelectionListDialogComponent],
  imports: [
    CommonModule,
    ButtonModule,
    MatDialogModule,
    InputModule,
    SelectionListDialogListModule,
    DialogModule,
    MessageModule,
  ],
  exports: [SelectionListDialogComponent],
})
export class SelectionListDialogModule {}
