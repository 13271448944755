import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { PcPipesModule } from '@pc-pipes';
import { ButtonModule } from '@pc-ui/button';
import { CardModelMiniModule } from '@pc-ui/card-model-mini';
import { CardPromotionMiniModule } from '@pc-ui/card-promotion-mini';
import { DialogModule } from '@pc-ui/dialog';
import { ForecastVisitorsModule } from '@pc-ui/forecast-visitors';
import { IndicatorForecastVisitorsModule } from '@pc-ui/indicator-forecast-visitors';
import { IndicatorForecastWeatherModule } from '@pc-ui/indicator-forecast-weather';
import { MessageModule } from '@pc-ui/message';
import { CalendardayDialogComponent } from './calendarday-dialog.component';

@NgModule({
  declarations: [CalendardayDialogComponent],
  imports: [
    ButtonModule,
    CardModelMiniModule,
    CardPromotionMiniModule,
    CommonModule,
    DialogModule,
    ForecastVisitorsModule,
    IndicatorForecastVisitorsModule,
    IndicatorForecastWeatherModule,
    MatDialogModule,
    MessageModule,
    PcPipesModule,
  ],
})
export class CalendardayDialogModule {}
