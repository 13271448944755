import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { StoreService } from '@pc-services';
import { PcEnv, PcTag, PcTagFirebase, PC_COLLECTIONS } from '@pc-types';
import { compact } from 'lodash-es';
import { distinctUntilChanged } from 'rxjs/operators';
import { EnvironmentService } from '../environment/environment.service';

@Injectable({
  providedIn: 'root',
})
export class TagService {
  constructor(
    private angularFirestore: AngularFirestore,
    private store: StoreService,
    private envService: EnvironmentService
  ) {}

  public fetchAll(): void {
    this.angularFirestore
      .collection<PcTag>(PC_COLLECTIONS.TAGS, (ref) => {
        const env: PcEnv = this.envService.getFirebaseEnv();
        return ref.where('enabled', '==', true).where('env', '==', env);
      })
      .valueChanges({ idField: 'uid' })
      .pipe(distinctUntilChanged())
      .subscribe((tags) => {
        this.store.setTags(compact(tags));
      });
  }

  public async create(tag: PcTagFirebase): Promise<void> {
    await this.angularFirestore
      .collection<PcTagFirebase>(PC_COLLECTIONS.TAGS)
      .doc(tag.name)
      .set(tag);
  }
}
