import {
  AngularFirestore,
  DocumentReference,
} from '@angular/fire/compat/firestore';
import { getFirestorePathBasedOnPermissions } from '@pc-helpers';
import { EnvironmentService, StoreService } from '@pc-services';
import {
  Complete,
  PC_COLLECTIONS,
  PcApprovalStatus,
  PcCockpitUser,
  PcEnv,
  PcFirestoreConverter,
  PcGlobalPermissions,
  PcModel,
  PcModelFirebase,
} from '@pc-types';
import { compact, keys, pick } from 'lodash-es';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

export type PcModelExtensionConstructor<TF, T> = {
  angularFirestore: AngularFirestore;
  store: StoreService;
  modelConverter: PcFirestoreConverter<TF, T>;
  envService: EnvironmentService;
};

export abstract class ModelExtension<
  T extends PcModel,
  TF extends PcModelFirebase
> {
  protected abstract readonly referenceMainCollection: PC_COLLECTIONS;
  protected abstract readonly referenceShopCollection: PC_COLLECTIONS;
  protected referenceReadPath!: string;
  protected referenceWritePath?: string;
  private globalPermissions?: PcGlobalPermissions;

  private firestore!: AngularFirestore;
  protected store: StoreService;
  protected envService: EnvironmentService;

  private modelConverter: PcFirestoreConverter<TF, T>;

  constructor(args: PcModelExtensionConstructor<TF, T>) {
    this.firestore = args.angularFirestore;
    this.store = args.store;
    this.modelConverter = args.modelConverter;
    this.envService = args.envService;
  }

  public async update(
    modelId: string,
    item: Partial<T>
  ): Promise<DocumentReference<Partial<TF>>> {
    if (!this.referenceWritePath) {
      console.warn('referenceCollectionPath missing');
      return Promise.reject();
    }

    item.modified = new Date();

    const itemForFirebase = pick(
      this.modelConverter.toFirestore(item),
      keys(item)
    );

    const firebaseDoc = this.firestore
      .collection<Partial<TF>>(this.referenceWritePath)
      .doc(modelId);

    await firebaseDoc.set(itemForFirebase, { merge: true });

    return firebaseDoc.ref;
  }

  public async create(
    item: Partial<T>
  ): Promise<DocumentReference<Complete<TF>>> {
    if (!this.referenceWritePath) {
      console.warn('referenceCollectionPath missing');
      return Promise.reject();
    }

    item.env = this.envService.getFirebaseEnv();
    item.created = new Date();
    item.modified = new Date();

    item = await this.appendDataForCreation(item);

    const itemForFirebase = this.modelConverter.toFirestore(item);
    if (!itemForFirebase) {
      return Promise.reject();
    }

    return this.firestore
      .collection<Complete<TF>>(this.referenceWritePath)
      .add(itemForFirebase);
  }

  private async appendDataForCreation(item: Partial<T>): Promise<Partial<T>> {
    const myUser = await this.store.myUser();

    if (myUser) {
      item.author = this.firestore.doc<PcCockpitUser>(
        `${PC_COLLECTIONS.COCKPIT_USER_PROFILES}/${myUser.uid}`
      ).ref;
    }

    const myShopId = await this.store.myShopId();
    if (myShopId) {
      item.shops = [myShopId];
    }

    if (!item.status) {
      item.status = 'draft';
    }

    item.enabled = true;

    return item;
  }

  public delete(modelId: string): Promise<void> {
    if (!this.referenceWritePath) {
      return Promise.resolve();
    }

    const item: Partial<T> = {};
    item.status = 'deleted';
    item.modified = new Date();

    const itemForFirebase = pick(
      this.modelConverter.toFirestore(item),
      keys(item)
    );

    return this.firestore
      .collection<Partial<TF>>(this.referenceWritePath)
      .doc(modelId)
      .set(itemForFirebase, { merge: true });
  }

  public setEnabled(modelId: string, enabled: boolean): Promise<void> {
    if (!this.referenceWritePath) {
      return Promise.resolve();
    }

    const item: Partial<T> = {};
    item.enabled = enabled;
    item.modified = new Date();

    const itemForFirebase = pick(
      this.modelConverter.toFirestore(item),
      keys(item)
    );

    return this.firestore
      .collection<Partial<TF>>(this.referenceWritePath)
      .doc(modelId)
      .set(itemForFirebase, { merge: true });
  }

  public fetchItems(): Observable<Complete<T>[]> {
    this.referenceReadPath = this.referenceMainCollection;

    return this.store.myGlobalPermissions$.pipe(
      tap((globalPermissions) => {
        this.globalPermissions = globalPermissions;
        this.referenceWritePath = getFirestorePathBasedOnPermissions(
          globalPermissions,
          this.referenceShopCollection,
          this.referenceMainCollection
        );
      }),
      switchMap((globalPermissions) => {
        return this.firestore
          .collection<TF>(this.referenceReadPath, (ref) => {
            const env: PcEnv = this.envService.getFirebaseEnv();
            const validStatus: PcApprovalStatus[] = [
              'published',
              'review',
              'draft',
              'rejected',
            ];
            const query = ref
              .where('env', '==', env)
              .where('end', '>', this.getEndDateForFetch())
              .where('status', 'in', validStatus);

            // if (globalPermissions.role === 'tenant') {
            //   query = query.where(
            //     `shopIds`,
            //     'in',
            //     globalPermissions.shopId
            //   );
            // }

            return query;
          })
          .valueChanges({ idField: 'uid' });
      }),
      map((firebaseModels) => {
        const items = compact(
          firebaseModels.map(this.modelConverter.fromFirestore)
        );

        // temp: this can be removed, after fetching via shopId is possible (see few lines above)
        if (this.globalPermissions?.role === 'tenant') {
          const myShopId = this.globalPermissions.shopId;
          return items.filter((item) =>
            item.shops?.some((shopId) => shopId === myShopId)
          );
        }
        return items;
      })
    );
  }

  public fetchItemsForFeed(): Observable<Complete<T>[]> {
    return this.firestore
      .collection<TF>(this.referenceMainCollection, (ref) => {
        const status: PcApprovalStatus = 'published';
        const env: PcEnv = this.envService.getFirebaseEnv();
        return ref
          .where('enabled', '==', true)
          .where('status', '==', status)
          .where('env', '==', env)
          .where('end', '>', this.getEndDateForFetchFeed());
      })
      .valueChanges({ idField: 'uid' })
      .pipe(
        map((firebaseModels) =>
          firebaseModels.filter((model) => model.scope !== 'private')
        ),
        map((firebaseModels) =>
          compact(firebaseModels.map(this.modelConverter.fromFirestore))
        )
      );
  }

  public abstract getEndDateForFetch(): TF['end'];
  public abstract getEndDateForFetchFeed(): TF['end'];
}
