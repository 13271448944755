import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { AnnouncementModule } from '@pc-ui/announcement/announcement.module';
import { ButtonModule } from '@pc-ui/button';
import { DialogModule } from '@pc-ui/dialog';
import { PaginationModule } from '@pc-ui/pagination';
import { AnnouncementDialogComponent } from './announcement-dialog.component';

@NgModule({
  declarations: [AnnouncementDialogComponent],
  imports: [
    CommonModule,
    ButtonModule,
    MatDialogModule,
    AnnouncementModule,
    DialogModule,
    PaginationModule,
  ],
  exports: [AnnouncementDialogComponent],
})
export class AnnouncementDialogModule {}
