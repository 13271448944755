import { neverError } from '@pc-helpers';
import { PcShop, PcShopCategoryType } from '@pc-types';
import { first } from 'lodash-es';

export function isCategoryTypeInShop(
  category: PcShopCategoryType,
  shop: PcShop
): boolean {
  return shop.categoryTypes.some((categoryType) => categoryType === category);
}

function isBookingEnabled(shop: PcShop): boolean {
  return shop.bookingEnabled === true && shop.bookingType === 'default';
}

export function isGastroModuleEnabled(shop: PcShop): boolean {
  return isCategoryTypeInShop('gastronomy', shop) && isBookingEnabled(shop);
}

export function getShopPreSlug(shop: PcShop): string | undefined {
  const categoryType = first(shop.categoryTypes);
  if (!categoryType) {
    return undefined;
  }
  switch (categoryType) {
    case 'shops':
      return 'shops';
    case 'gastronomy':
      return 'gastronomie';
    case 'entertainment':
      return 'entertainment';
    case 'health':
      return 'fitness-health';
    case 'kids':
      return 'kids-family';
    default:
      throw neverError('', categoryType);
  }
}
