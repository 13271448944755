import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnnouncementComponent } from './announcement.component';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';

@NgModule({
  declarations: [AnnouncementComponent],
  imports: [CommonModule, DirectivesModule],
  exports: [AnnouncementComponent],
})
export class AnnouncementModule {}
