import {
  convertDateToTsFirebase,
  dateFallback,
  getFormValue,
} from '@pc-helpers';
import {
  PcAnnouncement,
  PcAnnouncementFirebase,
  PcFirestoreConverter,
  PcFormConverter,
} from '@pc-types';
import firebase from 'firebase/compat/app';

export const announcementConverter: PcFirestoreConverter<
  PcAnnouncementFirebase,
  PcAnnouncement
> = {
  fromFirestore: (input) => {
    if (!input || !input.start || !input.end || !input.created || !input.env) {
      return undefined;
    }
    try {
      return {
        uid: input.uid,
        created: input.created.toDate(),
        modified: input.modified
          ? input.modified.toDate()
          : input.created.toDate(),
        start: input.start.toDate(),
        end: input.end.toDate(),
        title: input.title ?? '',
        subtitle: input.subtitle ?? '',
        enabled: input.enabled ?? false,
        env: input.env,
        description: input.description ?? '',
        displayType: input.displayType ?? 'none',
        images: input.images ?? [],
        ctaLabel: input.ctaLabel ?? '',
        ctaUrl: input.ctaUrl ?? '',
        author: input.author,
        index: input.index,
        targetGroup: input.targetGroup,
        status: input.status ?? 'published',
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
  toFirestore: (input) => {
    if (!input) {
      return undefined;
    }
    try {
      return {
        created: firebase.firestore.Timestamp.now(),
        modified: firebase.firestore.Timestamp.now(),
        start: convertDateToTsFirebase(dateFallback(input.start)),
        end: convertDateToTsFirebase(dateFallback(input.end)),
        title: input.title ?? '',
        subtitle: input.subtitle ?? '',
        enabled: input.enabled ?? false,
        env: input.env ?? 'dev',
        description: input.description ?? '',
        displayType: input.displayType ?? 'none',
        images: input.images ?? [],
        ctaLabel: input.ctaLabel ?? '',
        ctaUrl: input.ctaUrl ?? '',
        author: input.author,
        index: input.index,
        targetGroup: input.targetGroup,
        status: input.status ?? 'published',
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
};

export const announcementFormConverter: PcFormConverter<PcAnnouncement> = {
  fromForm: (form) => {
    const announcement: Partial<PcAnnouncement> = {};

    const title = getFormValue(form, 'title');
    if (typeof title === 'string') {
      announcement.title = title;
    }

    const subtitle = getFormValue(form, 'subtitle');
    if (typeof subtitle === 'string') {
      announcement.subtitle = subtitle;
    }

    const description = getFormValue(form, 'description');
    if (typeof description === 'string') {
      announcement.description = description;
    }

    const start = getFormValue(form, 'start');
    if (start instanceof Date) {
      announcement.start = start;
    }

    const end = getFormValue(form, 'end');
    if (end instanceof Date) {
      announcement.end = end;
    }

    const ctaLabel = getFormValue(form, 'ctaLabel') as string;
    announcement.ctaLabel = ctaLabel;

    return announcement;
  },
};
