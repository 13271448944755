import { convertFirebaseImageToString } from '@pc-helpers';
import { EnvironmentService } from '@pc-services';
import {
  PcContentCreateKind,
  PcDataFilterModel,
  PcDynamicLinkPrefixes,
  PcDynamicLinkRequest,
  PcFilterTab,
  PcModel,
  PcModelForDynamicLink,
  PcModelKind,
  PcModelStatusResponse,
  PcReadonlyKind,
  PC_COLLECTIONS,
} from '@pc-types';
import { startOfWeek, subMonths } from 'date-fns';
import { first } from 'lodash-es';
import { neverError } from './never.helper';

export function getModelKindColor(
  kind: PcContentCreateKind,
  config: { isCrew?: boolean } = {}
): string {
  if (config.isCrew) {
    return 'text-red-500';
  }
  switch (kind) {
    case 'competition':
      return 'text-brown-500';
    case 'event':
      return 'text-turquoise-500';
    case 'news':
      return 'text-green-500';
    case 'offer':
    case 'offer_menu':
    case 'offer_coupon':
      return 'text-pink-500';
  }
}

export function getModelKindLabel(
  kind: PcContentCreateKind,
  config:
    | { isCrew: boolean; isRp: boolean; isPrivate: boolean; isPreview: boolean }
    | undefined
): string {
  let crew = '';
  let rp = '';
  let scope = '';
  let label = '';
  if (config?.isCrew) {
    crew = 'Crew ';
  }
  if (config?.isRp) {
    rp = 'R&P ';
  }
  if (config?.isPrivate && !config.isPreview) {
    scope = ' (Privat)';
  }

  switch (kind) {
    case 'competition':
      label = 'Gewinnspiele';
      break;
    case 'event':
      label = 'Events';
      break;
    case 'news':
      label = 'News';
      break;
    case 'offer':
      label = 'Angebote';
      break;
    case 'offer_menu':
      label = 'Wochenmenü';
      break;
    case 'offer_coupon':
      label = 'Gutscheine';
      break;
  }

  return crew + rp + label + scope;
}

export function getCollectionByKind(kind: PcModelKind): PC_COLLECTIONS {
  switch (kind) {
    case 'competition':
      return PC_COLLECTIONS.COMPETITIONS;
    case 'event':
      return PC_COLLECTIONS.EVENTS;
    case 'news':
      return PC_COLLECTIONS.NEWS;
    case 'offer':
      return PC_COLLECTIONS.OFFERS;
    default:
      throw neverError('', kind);
  }
}

export function getFeedFilters(): PcFilterTab<PcDataFilterModel>[] {
  const activeCriteria: PcDataFilterModel = {
    startDate: 'past',
    endDate: 'future',
  };
  let filters: PcFilterTab<PcDataFilterModel>[] = [
    {
      key: 'all',
      label: 'Übersicht',
      criteria: {
        ...activeCriteria,
      },
    },
  ];

  filters = filters.concat([
    {
      key: 'crew',
      label: 'Crew',
      criteria: {
        ...activeCriteria,
        crew: true,
      },
    },
    {
      key: 'news',
      label: 'News',
      criteria: {
        ...activeCriteria,
        kind: 'news',
      },
    },
    {
      key: 'offer',
      label: 'Angebote',
      criteria: {
        ...activeCriteria,
        kind: 'offer',
      },
    },
    {
      key: 'event',
      label: 'Events',
      criteria: {
        ...activeCriteria,
        kind: 'event',
      },
    },
    {
      key: 'competition',
      label: 'Gewinnspiele',
      criteria: {
        ...activeCriteria,
        kind: 'competition',
      },
    },
  ]);

  return filters;
}

export function getContentCreateKindByModel(
  model: PcModel
): PcContentCreateKind {
  if (model.kind === 'offer' && model.type === 'menu') {
    return 'offer_menu';
  }

  if (model.kind === 'offer' && model.type === 'coupon') {
    return 'offer_coupon';
  }

  return model.kind;
}

export function isContentCreateKind(
  input: string
): input is PcContentCreateKind {
  if (
    input === 'event' ||
    input === 'news' ||
    input === 'offer' ||
    input === 'competition' ||
    input === 'offer_menu' ||
    input === 'offer_coupon'
  ) {
    // just for type checking
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const output: PcContentCreateKind = input;
    return true;
  }
  return false;
}

export function getFetchEndDateForModels(): Date {
  return startOfWeek(subMonths(new Date(), 18));
}

export function getFolderNameByModel(kind: PcModelKind): string {
  switch (kind) {
    case 'competition':
      return 'competitions';
    case 'news':
      return 'news';
    case 'event':
      return 'events';
    case 'offer':
      return 'offers';
  }
}

export function shouldConfirmPublish(
  item: PcModel,
  response: PcModelStatusResponse
): boolean {
  return (
    (response.status === 'review' || response.status === 'published') &&
    ((item.kind === 'offer' &&
      item.type === 'reservepickup' &&
      !item.readonly) ||
      item.kind === 'competition')
  );
}

export function getDialogHeadline(itemKind: PcReadonlyKind): string {
  switch (itemKind) {
    case 'reserve-pickup':
      return 'Sobald ein Reserve & Pickup”-Angebot freigegeben wurde, kann es nicht mehr bearbeitet werden.';

    case 'competition':
      return 'Sobald ein Gewinnspiel freigegeben wurde, können die Teilnahmebedingungen und der Gewinnspiel-Typ nicht mehr bearbeitet werden.';

    default:
      return 'Sobald dieser Beitrag freigegeben wurde, kann er nicht mehr bearbeitet werden.';
  }
}

export function isModelDeleteable(model: PcModel): boolean {
  return !hasOpenBookingsInReservePickup(model);
}

export function canManagerRemovePublish(model: PcModel): boolean {
  return !hasOpenBookingsInReservePickup(model);
}

export function isEnableDisabled(model: PcModel): boolean {
  return hasOpenBookingsInReservePickup(model);
}

function hasOpenBookingsInReservePickup(item: PcModel): boolean {
  return (
    item.kind === 'offer' &&
    item.type === 'reservepickup' &&
    !!item.bookings &&
    item.bookings.filter(
      (booking) =>
        booking.statusText === 'processing' ||
        booking.statusText === 'confirmed'
    ).length > 0
  );
}

export function getDynamicLinkRequestForModel(
  model: PcModelForDynamicLink,
  envService: EnvironmentService
): PcDynamicLinkRequest {
  const prefixes = getDynamicLinkPrefixes(model.kind);
  const websiteHost = envService.getWebsiteUrl();
  const directLink = model.websiteUrl;

  const data: PcDynamicLinkRequest = {
    appLink: `/${prefixes.app}/${model.uid}`,
    webURL: directLink
      ? directLink
      : `${websiteHost}/${prefixes.website}/${model.slug}`,
    title: model.title,
    description: model.description,
    secure: false,
  };
  const image = first(model.images);

  if (image) {
    data.image = convertFirebaseImageToString(image);
  }
  return data;
}

function getDynamicLinkPrefixes(kind: PcModelKind): PcDynamicLinkPrefixes {
  switch (kind) {
    case 'competition':
      return {
        app: 'competition',
        website: 'gewinnspiele',
      };
    case 'event':
      return {
        app: 'event',
        website: 'events',
      };
    case 'offer':
      return {
        app: 'offer',
        website: 'angebote',
      };
    case 'news':
      return {
        app: 'news',
        website: 'news',
      };
  }
}
