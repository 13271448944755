import {
  PcCalendarModelItem,
  PcCalendarPromotionItem,
  PcModel,
  PcPromotion,
} from '@pc-types';

export const calendarConverter = {
  fromModel: (input: PcModel): PcCalendarModelItem | undefined => {
    try {
      const calModel: PcCalendarModelItem = {
        model: input,
        type: input.kind,
        dates: [],
      };
      switch (input.kind) {
        case 'competition':
          calModel.dates.push({
            from: input.participationStart,
            to: input.participationEnd,
          });
          break;
        case 'event':
          input.dates.forEach((modelDate) => {
            calModel.dates.push({
              from: modelDate.start,
              to: modelDate.end,
            });
          });
          break;
        case 'offer':
          calModel.dates.push({
            from: input.validStart,
            to: input.end,
          });
          break;
        case 'news':
          calModel.dates.push({
            from: input.start,
            to: input.end,
          });
          break;
      }
      return calModel;
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
  fromPromotion: (input: PcPromotion): PcCalendarPromotionItem | undefined => {
    try {
      return {
        model: input,
        type: 'promotion',
        dates: [
          {
            from: input.start,
            to: input.end,
          },
        ],
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
};
