import {
  convertArrayToFirebaseMap,
  convertDateToTsFirebase,
  convertFirebaseMapToArray,
  dateFallback,
  getFormValue,
} from '@pc-helpers';
import {
  PcFirestoreConverter,
  PcFormConverter,
  PcJob,
  PcJobFirebase,
} from '@pc-types';
import firebase from 'firebase/compat/app';
import { statusFeedbackConverter } from './status-feedback.converter';
export const jobConverter: PcFirestoreConverter<PcJobFirebase, PcJob> = {
  fromFirestore: (input) => {
    if (!input || !input.created || !input.modified) {
      return undefined;
    }
    try {
      return {
        uid: input.uid,
        created: input.created.toDate(),
        modified: input.modified.toDate(),
        start: input.start?.toDate(),
        end: input.end?.toDate(),
        updated: input.updated?.toDate(),
        title: input.title ?? '',
        status: input.status ?? 'draft',
        description: input.description ?? '',
        enabled: input.enabled ?? false,
        env: input.env ?? 'dev',
        images: input.images ?? [],
        level: input.level ?? 'fulltime',
        shops: input.shops ?? [],
        tags: input.tags ? convertFirebaseMapToArray(input.tags) : [],
        websiteUrl: input.websiteUrl,
        statusFeedback: statusFeedbackConverter.fromFirestore(
          input.statusFeedback
        ),
        kind: 'job',
        applicationUrl: input.applicationUrl,
        applicationEmail: input.applicationEmail,
        categories: input.categories,
        logo: input.logo,
        author: input.author,
        forceNotification: input.forceNotification,
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
  toFirestore: (input) => {
    if (!input) {
      return undefined;
    }
    try {
      return {
        created: firebase.firestore.Timestamp.now(),
        modified: firebase.firestore.Timestamp.now(),
        start: convertDateToTsFirebase(dateFallback(input.start)),
        end: convertDateToTsFirebase(dateFallback(input.end)),
        updated: convertDateToTsFirebase(dateFallback(input.updated)),
        title: input.title ?? '',
        status: input.status ?? 'draft',
        description: input.description ?? '',
        enabled: input.enabled ?? false,
        env: input.env ?? 'dev',
        images: input.images ?? [],
        level: input.level ?? 'fulltime',
        shops: input.shops ?? [],
        tags: input.tags ? convertArrayToFirebaseMap(input.tags) : {},
        websiteUrl: input.websiteUrl,
        statusFeedback: statusFeedbackConverter.toFirestore(
          input.statusFeedback
        ),
        applicationUrl: input.applicationUrl,
        applicationEmail: input.applicationEmail,
        categories: input.categories,
        logo: input.logo,
        author: input.author,
        forceNotification: input.forceNotification,
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
};

export const jobFormConverter: PcFormConverter<PcJob> = {
  fromForm: (form) => {
    const job: Partial<PcJob> = {};

    const title = getFormValue(form, 'title');
    if (typeof title === 'string') {
      job.title = title;
    }

    const description = getFormValue(form, 'description');
    if (typeof description === 'string') {
      job.description = description;
    }

    const start = getFormValue(form, 'start');
    if (start instanceof Date) {
      job.start = start;
    }

    const end = getFormValue(form, 'end');
    if (end instanceof Date) {
      job.end = end;
    }

    return job;
  },
};
