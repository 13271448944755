import { Injectable } from '@angular/core';
import { AngularFirestore, Query } from '@angular/fire/compat/firestore';
import { forecastConverter } from '@pc-converter';
import { StoreService } from '@pc-services';
import {
  getForecastEnd,
  getForecastStart,
  PcCockpitUser,
  PcForecastDayFirebase,
  PC_COLLECTIONS,
} from '@pc-types';
import { compact } from 'lodash-es';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { EnvironmentService } from '../environment/environment.service';

@Injectable({
  providedIn: 'root',
})
export class ForecastService {
  constructor(
    private store: StoreService,
    private angularFirestore: AngularFirestore,
    private envService: EnvironmentService
  ) {}

  public fetchAll(): void {
    this.angularFirestore
      .collection<PcForecastDayFirebase>(
        PC_COLLECTIONS.VISITORS_FORECAST,
        (ref) => {
          let query: Query = ref;
          const start = getForecastStart();
          const end = getForecastEnd();

          query = query.where('date', '>=', start).where('date', '<=', end);

          return query;
        }
      )
      .valueChanges({ idField: 'uid' })
      .pipe(
        map((forecast) =>
          forecast.map((item) =>
            forecastConverter.fromFirestore(
              item,
              this.envService.getFirebaseEnv()
            )
          )
        ),
        distinctUntilChanged()
      )
      .subscribe((forecast) => {
        this.store.setForecast(compact(forecast));
      });
  }

  public async updateVisitorsOverride(
    uid: string,
    visitors: number
  ): Promise<void> {
    const myUser = await this.store.myUser();
    if (!myUser) {
      return Promise.resolve();
    }

    const visitorsForecastFirebase = forecastConverter.toFirestore(
      {
        override: {
          visitors,
          modified: new Date(),
          user: this.angularFirestore.doc<PcCockpitUser>(
            `${PC_COLLECTIONS.COCKPIT_USER_PROFILES}/${myUser.uid}`
          ).ref,
        },
        modified: new Date(),
      },
      this.envService.getFirebaseEnv()
    );
    if (!visitorsForecastFirebase) {
      return Promise.resolve();
    }

    return this.angularFirestore
      .collection<PcForecastDayFirebase>(PC_COLLECTIONS.VISITORS_FORECAST)
      .doc(uid)
      .update({
        ...visitorsForecastFirebase,
        visitors: undefined,
        date: undefined,
        weather: undefined,
      });
  }
}
