import { Injectable } from '@angular/core';
import { AngularFirestore, Query } from '@angular/fire/compat/firestore';
import { holidayConverter } from '@pc-converter';
import { EnvironmentService, StoreService } from '@pc-services';
import { PcEnv, PcHolidayFirebase, PC_COLLECTIONS } from '@pc-types';
import { compact } from 'lodash-es';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HolidaysService {
  constructor(
    private angularFirestore: AngularFirestore,
    private store: StoreService,
    private envService: EnvironmentService
  ) {}

  public fetchAll(): void {
    this.angularFirestore
      .collection<PcHolidayFirebase>(PC_COLLECTIONS.HOLIDAYS, (ref) => {
        let query: Query = ref;
        const env: PcEnv = this.envService.getFirebaseEnv();
        if (env === 'prod') {
          query = query.where('env', '==', env);
        }
        query = query.where('enabled', '==', true);
        return query;
      })
      .valueChanges({ idField: 'uid' })
      .pipe(
        map((holidays) => holidays.map(holidayConverter.fromFirestore)),
        distinctUntilChanged()
      )
      .subscribe((holidays) => {
        this.store.setHolidays(compact(holidays));
      });
  }
}
