import { Injectable, NgZone } from '@angular/core';
import { PcPreviewMode, PcUiState } from '@pc-types';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UiService {
  private uiState = new BehaviorSubject<PcUiState>({
    isContentEditPageActive: false,
    promotionEditBarVisible: false,
    announcementEditBarVisible: false,
    jobEditBarVisible: false,
    globalSpinnerVisible: true,
    cockpitOutletContainerHeight: 0,
    isMobileMenuVisible: false,
    isHeaderFooterHidden: false,
  });

  public previewMode$: Subject<PcPreviewMode> = new Subject();
  public userChanged$: Subject<void> = new Subject();

  constructor(private zone: NgZone) {}

  public state$ = this.uiState.asObservable();

  public setState(delta: Partial<PcUiState>): void {
    this.zone.run(() => {
      const state = this.uiState.getValue();
      const newState = { ...state, ...delta };
      this.uiState.next(newState);
    });
  }
}
