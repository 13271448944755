import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@pc-ui/icon';
import { SelectionListDialogListItemComponent } from './components/selection-list-dialog-list-item/selection-list-dialog-list-item.component';
import { SelectionListDialogListComponent } from './selection-list-dialog-list.component';

@NgModule({
  declarations: [
    SelectionListDialogListComponent,
    SelectionListDialogListItemComponent,
  ],
  imports: [CommonModule, IconModule],
  exports: [SelectionListDialogListComponent],
})
export class SelectionListDialogListModule {}
