import {
  PcFirestoreToConverter,
  PcLogEmail,
  PcLogEmailFirebase,
  PC_EMAIL_RECIPIENT,
} from '@pc-types';
import firebase from 'firebase/compat/app';
export const logEmailConverter: PcFirestoreToConverter<
  PcLogEmailFirebase,
  PcLogEmail
> = {
  toFirestore: (input) => {
    if (
      !input ||
      !input.message ||
      !input.subject ||
      !input.toEmail ||
      !input.source
    ) {
      return undefined;
    }
    try {
      return {
        created: firebase.firestore.Timestamp.now(),
        modified: firebase.firestore.Timestamp.now(),
        fromEmail: input.fromEmail ?? PC_EMAIL_RECIPIENT.DEFAULT,
        fromName: input.fromName ?? 'PlusCity Mietercockpit',
        message: input.message,
        source: 'PlusCity Mietercockpit - ' + (input.source ?? ''),
        subject: input.subject,
        toEmail: input.toEmail,
        toName: input.toName ?? '',
        status: input.status,
        userID: input.userID,
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
};
