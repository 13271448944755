import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlideToggleComponent } from './slide-toggle.component';
import { ErrorModule } from '@pc-ui/error/error.module';

@NgModule({
  declarations: [SlideToggleComponent],
  imports: [CommonModule, ErrorModule],
  exports: [SlideToggleComponent],
})
export class SlideToggleModule {}
