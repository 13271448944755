import { Injectable, NgZone } from '@angular/core';
import { PcIntroJsExtended, PcTourState, PcTourStep } from '@pc-types';
import introJs from 'intro.js';
import { sortBy, uniqBy } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TourService {
  private tourState = new BehaviorSubject<PcTourState>({
    active: false,
    currentStep: 0,
    boxHeight: 0,
    steps: [],
  });

  private introJs?: PcIntroJsExtended;

  constructor(private zone: NgZone) {}

  public async start(): Promise<void> {
    await new Promise((resolve) => setTimeout(resolve, 100));

    this.introJs = window.introJs();
    this.fetchTourSteps();
    await this.setupIntroJs();

    if (!this.introJs) {
      console.warn('introjs not initialized');
      return;
    }

    this.introJs.start();
    this.setState({
      active: true,
      currentStep: 1,
    });
  }

  public end(): void {
    this.introJs?.exit();
    this.endTour();
  }

  private endTour(): void {
    this.setState({
      active: false,
      currentStep: 0,
    });
  }

  private async setupIntroJs(): Promise<void> {
    const steps = this.getSteps();

    this.introJs
      ?.setOptions({
        steps,
        buttonClass: 'introjs-button-custom',
        showProgress: false,
        disableInteraction: true,
        scrollToElement: true,
      })
      .onexit(() => {
        this.endTour();
      })
      .onafterchange(() => {
        setTimeout(() => {
          this.introJs?.refresh();
        }, 400);
      });
  }

  private fetchTourSteps(): void {
    const elementTourSteps = document.querySelectorAll('[data-tour-step]');

    const tourSteps: PcTourStep[] = [];

    elementTourSteps.forEach((elem) => {
      const tourStepStr = elem.getAttribute('data-tour-step');
      const isTourStepSticky = !!elem.getAttribute('data-tour-step-sticky');
      if (tourStepStr) {
        const tourStep = parseInt(tourStepStr, 10);
        if (tourStep) {
          tourSteps.push({
            step: tourStep,
            elem,
            scrollable: !isTourStepSticky,
          });
        }
      }
    });

    this.setState({
      steps: sortBy(uniqBy(tourSteps, 'step'), 'step'),
    });
  }

  private getSteps(): introJs.Step[] {
    const steps: introJs.Step[] = [];

    this.tourState.getValue().steps.forEach((tourStep) => {
      steps.push({
        intro: '',
        element: tourStep.elem,
      });
    });

    return steps;
  }

  public setPrevStep(): void {
    const step = this.tourState.getValue().currentStep - 1;

    // order of commands is important, fixes that: https://netural.atlassian.net/browse/PWC2-2010
    this.setCurrentStep(step);
    this.introJs?.goToStep(step);
  }

  public setNextStep(): void {
    const step = this.tourState.getValue().currentStep + 1;

    // order of commands is important, fixes that: https://netural.atlassian.net/browse/PWC2-2010
    this.setCurrentStep(step);
    this.introJs?.goToStep(step);
  }

  private setCurrentStep(currentStep: number): void {
    this.setState({
      currentStep,
    });
  }

  public state$ = this.tourState.asObservable().pipe(debounceTime(10));

  public setState(delta: Partial<PcTourState>): void {
    this.zone.run(() => {
      const state = this.tourState.getValue();
      const newState = { ...state, ...delta };
      this.tourState.next(newState);
    });
  }

  public refresh(): void {
    this.introJs?.refresh();
  }
}
