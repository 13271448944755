import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShopLogoComponent } from './shop-logo.component';
import { ImageModule } from '@pc-ui/image/image.module';

@NgModule({
  declarations: [ShopLogoComponent],
  imports: [CommonModule, ImageModule],
  exports: [ShopLogoComponent],
})
export class ShopLogoModule {}
