import { modelConverter } from '@pc-converter';
import {
  convertDateToTsFirebase,
  convertDateToTsSeconds,
  convertTsSecondsToDate,
  dateFallback,
} from '@pc-helpers';
import { PcFirestoreConverter, PcOffer, PcOfferFirebase } from '@pc-types';

export const offerConverter: PcFirestoreConverter<PcOfferFirebase, PcOffer> = {
  fromFirestore: (input) => {
    if (
      !input ||
      typeof input.start !== 'number' ||
      typeof input.end !== 'number'
    ) {
      return undefined;
    }
    try {
      const model = modelConverter.fromFirestore(input);
      if (!model) {
        return undefined;
      }

      return {
        ...model,
        validStart: convertTsSecondsToDate(input.validStart ?? input.start),
        redeemStart: input.redeemStart
          ? convertTsSecondsToDate(input.redeemStart)
          : undefined,
        redeemEnd: input.redeemEnd ? input.redeemEnd.toDate() : undefined,
        kind: 'offer',
        type: input.type ?? 'discount',
        capacityClaimed: input.capacityClaimed ?? 0,
        redeemDuration: input.redeemDuration ?? 'unlimited',
        start: convertTsSecondsToDate(input.start),
        end: convertTsSecondsToDate(input.end),
        name: input.name ?? '',
        bookings: input.bookings ? Object.values(input.bookings) : [],
        logo: input.logo,
        badge: input.badge,
        badgePrefix: input.badgePrefix,
        redeemMaxCount: input.redeemMaxCount,
        redeemType: input.redeemType,
        redeemOptions: input.redeemOptions,
        redeemDescription: input.redeemDescription,
        disclaimer: input.disclaimer,
        capacityPerUser: input.capacityPerUser,
        capacityTotal: input.capacityTotal,
        barcode: input.barcode,
        barcodeType: input.barcodeType,
        menuUpload: input.menuUpload,
        recurringRule: input.recurringRule,
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
  toFirestore: (input) => {
    if (!input) {
      return undefined;
    }
    try {
      const model = modelConverter.toFirestore(input);
      if (!model) {
        return undefined;
      }
      return {
        ...model,
        validStart: convertDateToTsSeconds(dateFallback(input.validStart)),
        redeemStart: input.redeemStart
          ? convertDateToTsSeconds(input.redeemStart)
          : input.redeemStart,
        redeemEnd: input.redeemEnd
          ? convertDateToTsFirebase(input.redeemEnd)
          : input.redeemEnd,
        type: input.type ?? 'discount',
        start: convertDateToTsSeconds(dateFallback(input.start)),
        end: convertDateToTsSeconds(dateFallback(input.end)),
        name: input.name ?? '',
        bookings: undefined,
        logo: input.logo,
        badge: input.badge,
        badgePrefix: input.badgePrefix,
        redeemMaxCount: input.redeemMaxCount,
        redeemType: input.redeemType,
        redeemOptions: input.redeemOptions,
        redeemDescription: input.redeemDescription,
        disclaimer: input.disclaimer,
        capacityPerUser: input.capacityPerUser,
        capacityTotal: input.capacityTotal,
        barcode: input.barcode,
        barcodeType: input.barcodeType,
        menuUpload: input.menuUpload,
        recurringRule: input.recurringRule,
        redeemDuration: input.redeemDuration,
        capacityClaimed: undefined,
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
};
