import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountRequestDialogComponent } from './account-request-dialog.component';
import { DialogModule } from '@pc-ui/dialog';
import { ButtonModule } from '@pc-ui/button';
import { MatDialogModule } from '@angular/material/dialog';
import { InputModule } from '@pc-ui/input';
import { RadioGroupModule } from '@pc-ui/radio-group';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LabelModule } from '@pc-ui/label';
import { TextareaModule } from '@pc-ui/textarea';
import { CheckboxModule } from '@pc-ui/checkbox';

@NgModule({
  declarations: [AccountRequestDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    DialogModule,
    ButtonModule,
    InputModule,
    RadioGroupModule,
    FormsModule,
    ReactiveFormsModule,
    LabelModule,
    TextareaModule,
    CheckboxModule,
  ],
  exports: [AccountRequestDialogComponent],
})
export class AccountRequestDialogModule {}
