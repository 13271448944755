import { PcImageFirebaseOld } from '@pc-types';

export const PC_DEFAULT_UPLOAD_FOLDER = 'cockpit-test-uploads';

export function generateFileName(originalFileName: string): string {
  const timeStamp = new Date().getTime();
  return `${timeStamp}-${originalFileName}`;
}

export function getOriginalFileName(timestampedFileName: string): string {
  return timestampedFileName.replace(/^\d{13}\-/, '');
}

export function convertFirebaseImageToString(
  image: PcImageFirebaseOld | undefined
): string | undefined {
  if (!image) {
    return undefined;
  }
  if (image.bucket === 'remote' && image.url) {
    return image.url;
  } else if (image.fullPath && image.bucket) {
    return `https://firebasestorage.googleapis.com/v0/b/${
      image.bucket
    }/o/${encodeURIComponent(image.fullPath)}?alt=media`;
  } else if (image.url) {
    return image.url;
  }
  return undefined;
}
