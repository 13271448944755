import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsComponent } from './notifications.component';
import { NotificationsItemComponent } from './components/notifications-item/notifications-item.component';
import { IconModule } from '@pc-ui/icon';

@NgModule({
  declarations: [NotificationsComponent, NotificationsItemComponent],
  imports: [CommonModule, IconModule],
  exports: [NotificationsComponent],
})
export class NotificationsModule {}
