import { Injectable, NgZone } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { userPermissionConverter } from '@pc-converter';
import { isAnnouncementActive } from '@pc-helpers';
import {
  PC_COLLECTIONS,
  PcAnnouncement,
  PcAutocompleteOptionGroupable,
  PcBooking,
  PcCockpitUser,
  PcCompetition,
  PcContentEditHeaderData,
  PcEvent,
  PcForecast,
  PcGlobalPermissions,
  PcHoliday,
  PcHolidayFirebase,
  PcJob,
  PcKidsCheckin,
  PcModel,
  PcNews,
  PcNotification,
  PcNotificationScope,
  PcOffer,
  PcOpeningHoursDay,
  PcPermissions,
  PcPermissionsFirebase,
  PcPromotion,
  PcReport,
  PcShop,
  PcShopCategory,
  PcShopCategoryType,
  PcShopContract,
  PcShopOpeningHoursRequest,
  PcShopSimple,
  PcTag,
  PcTicket,
  PcTicketCategory,
  PcTicketLocation,
  PcUser,
} from '@pc-types';
import { endOfDay, isFuture } from 'date-fns';
import { first as _first, compact, sortBy } from 'lodash-es';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  firstValueFrom,
} from 'rxjs';
import { distinctUntilChanged, filter, first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  private contentEditHeaderData = new BehaviorSubject<
    PcContentEditHeaderData | undefined
  >(undefined);

  // Models
  private competitions = new BehaviorSubject<PcCompetition[] | undefined>(
    undefined
  );
  private events = new BehaviorSubject<PcEvent[] | undefined>(undefined);
  private news = new BehaviorSubject<PcNews[] | undefined>(undefined);
  private offers = new BehaviorSubject<PcOffer[] | undefined>(undefined);

  // Feed
  private feedCompetitions = new BehaviorSubject<PcCompetition[] | undefined>(
    undefined
  );
  private feedEvents = new BehaviorSubject<PcEvent[] | undefined>(undefined);
  private feedNews = new BehaviorSubject<PcNews[] | undefined>(undefined);
  private feedOffers = new BehaviorSubject<PcOffer[] | undefined>(undefined);

  private promotions = new BehaviorSubject<PcPromotion[] | undefined>(
    undefined
  );
  private bookings = new BehaviorSubject<PcBooking[] | undefined>(undefined);

  private myShopOpeningHoursRequest = new BehaviorSubject<
    PcShopOpeningHoursRequest | undefined
  >(undefined);

  private announcements = new BehaviorSubject<PcAnnouncement[] | undefined>(
    undefined
  );

  private shops = new BehaviorSubject<PcShopSimple[] | undefined>(undefined);
  private myShop = new BehaviorSubject<PcShop | undefined | null>(undefined);
  private shopCategories = new BehaviorSubject<PcShopCategory[] | undefined>(
    undefined
  );
  private notificationsShopRead = new BehaviorSubject<
    PcNotification[] | undefined
  >(undefined);
  private notificationsUserRead = new BehaviorSubject<
    PcNotification[] | undefined
  >(undefined);
  private notificationsShopUnread = new BehaviorSubject<
    PcNotification[] | undefined
  >(undefined);
  private notificationsUserUnread = new BehaviorSubject<
    PcNotification[] | undefined
  >(undefined);
  private forecast = new BehaviorSubject<PcForecast | undefined>(undefined);
  private tags = new BehaviorSubject<PcTag[] | undefined>(undefined);
  private holidays = new BehaviorSubject<PcHoliday[] | undefined>(undefined);
  private user = new BehaviorSubject<PcUser | undefined | null>(undefined);
  private myPermissionsFirebase = new BehaviorSubject<
    PcPermissionsFirebase | undefined | null
  >(undefined);
  private reports = new BehaviorSubject<PcReport[] | undefined | null>(
    undefined
  );
  private jobs = new BehaviorSubject<PcJob[] | undefined>(undefined);
  private kids = new BehaviorSubject<PcKidsCheckin[] | undefined>(undefined);
  private shopContracts = new BehaviorSubject<PcShopContract[] | undefined>(
    undefined
  );
  private tickets = new BehaviorSubject<PcTicket[] | undefined | null>(
    undefined
  );
  public ticketCategories = new BehaviorSubject<
    PcTicketCategory[] | undefined | null
  >(undefined);
  public ticketLocations = new BehaviorSubject<
    PcTicketLocation[] | undefined | null
  >(undefined);

  private cockpitUsers = new BehaviorSubject<PcCockpitUser[] | undefined>(
    undefined
  );

  constructor(
    private zone: NgZone,
    private angularFirestore: AngularFirestore
  ) {}

  /**
   * User
   */

  public setUser(user: PcUser | undefined): void {
    this.zone.run(() => {
      this.user.next(user);
    });
  }

  get user$(): Observable<PcUser | null> {
    return this.user
      .asObservable()
      .pipe(filter((user): user is PcUser | null => user !== undefined));
  }

  /**
   * User Permissions
   */

  public setMyPermissionsFirebase(
    permissionsFirebase: PcPermissionsFirebase | null
  ): void {
    this.zone.run(() => {
      this.myPermissionsFirebase.next(permissionsFirebase);
    });
  }

  get permissions$(): Observable<PcPermissions> {
    return this.myPermissionsFirebase.pipe(
      filter(
        (permissionsFirebase): permissionsFirebase is PcPermissionsFirebase =>
          !!permissionsFirebase
      ),
      map((permissionsFirebase) => {
        const permissions = permissionsFirebase.cockpit
          ? _first(Object.values(permissionsFirebase.cockpit))
          : undefined;

        return {
          content: undefined,
          promotions: undefined,
          shop: undefined,
          insights: undefined,
          jobs: undefined,
          reservepickup: undefined,
          reports: undefined,
          usermanagement: undefined,
          announcements: undefined,
          forecastmanagement: undefined,
          kids: undefined,
          ticketing: undefined,
          ...permissions,
        };
      }),
      distinctUntilChanged()
    );
  }

  get myGlobalPermissions$(): Observable<PcGlobalPermissions> {
    return this.myPermissionsFirebase.pipe(
      map((permissionsFirebase) => {
        return permissionsFirebase
          ? userPermissionConverter.fromFirestore(permissionsFirebase)
          : undefined;
      }),
      filter(
        (globalPermissions): globalPermissions is PcGlobalPermissions =>
          !!globalPermissions
      ),
      distinctUntilChanged()
    );
  }

  /**
   * Models
   */

  get models$(): Observable<PcModel[]> {
    return combineLatest([
      this.news$,
      this.offers$,
      this.competitions$,
      this.events$,
    ]).pipe(
      map(([news, offers, competitions, events]) => {
        const models: PcModel[] = [];
        models.push(...news);
        models.push(...offers);
        models.push(...competitions);
        models.push(...events);

        return models;
      }),
      distinctUntilChanged()
    );
  }

  get modelIds$(): Observable<string[] | undefined> {
    return this.models$.pipe(
      map((models) => {
        if (!models) {
          return undefined;
        }
        return models.map((model) => model.uid);
      }),
      distinctUntilChanged()
    );
  }

  get feedModels$(): Observable<PcModel[]> {
    return combineLatest([
      this.feedNews$,
      this.feedOffers$,
      this.feedCompetitions$,
      this.feedEvents$,
    ]).pipe(
      map(([news, offers, competitions, events]) => {
        const models: PcModel[] = [];
        models.push(...news);
        models.push(...offers);
        models.push(...competitions);
        models.push(...events);

        return models;
      }),
      distinctUntilChanged()
    );
  }

  public modelById$(uid: string): Observable<PcModel | undefined> {
    return this.models$.pipe(
      map((models) => {
        return models?.find((model) => model.uid === uid);
      })
    );
  }

  public feedModelById$(uid: string): Observable<PcModel | undefined> {
    return this.feedModels$.pipe(
      map((models) => {
        return models?.find((model) => model.uid === uid);
      })
    );
  }

  /**
   * Promotions
   */

  public setPromotions(promotions: PcPromotion[] | undefined): void {
    this.zone.run(() => {
      this.promotions.next(promotions);
    });
  }

  /** returns all promotions (including disabled) - recommended for manager use only! */
  public promotionsAll$ = this.promotions.asObservable().pipe(
    filter((items): items is PcPromotion[] => items !== undefined),
    distinctUntilChanged()
  );

  private promotionsEnabled$(): Observable<PcPromotion[] | undefined> {
    return this.promotionsAll$.pipe(
      map((promotions) => {
        if (!promotions) {
          return undefined;
        }

        return promotions.filter((promotion) => promotion.enabled);
      })
    );
  }

  /** returns available promotions (enabled only + invitation for user). can be used for tenant AND manager */
  get promotionsAvailable$(): Observable<PcPromotion[] | undefined> {
    return combineLatest([
      this.promotionsEnabled$(),
      this.permissions$,
      this.myShop$,
    ]).pipe(
      filter(([promotions, permissions]) => !!promotions && !!permissions),
      map(([promotions, permissions, myShop]) => {
        if (promotions && permissions) {
          if (permissions.content === 'tenant' && myShop) {
            return promotions.filter((promotion) => {
              return !!promotion.shops.find((shop) => shop.id === myShop.uid);
            });
          }

          if (permissions.content === 'manager') {
            return promotions;
          }
        }

        return undefined;
      }),
      distinctUntilChanged()
    );
  }

  public promotionById$(uid: string): Observable<PcPromotion | undefined> {
    return this.promotionsAll$.pipe(
      map((promotions) => {
        return promotions?.find((promotion) => promotion.uid === uid);
      })
    );
  }

  /**
   * Shops
   */

  public setShops(shops: PcShopSimple[] | undefined): void {
    this.zone.run(() => {
      this.shops.next(shops);
    });
  }

  public shops$ = this.shops.asObservable().pipe(
    filter((items): items is PcShopSimple[] => items !== undefined),
    first()
  );

  public shopSimpleById$(uid: string): Observable<PcShopSimple | undefined> {
    return this.shops.pipe(
      map((shopsMap) => shopsMap?.find((shop) => shop.uid === uid))
    );
  }

  public setMyShop(myShop: PcShop | null): void {
    this.zone.run(() => {
      this.myShop.next(myShop);
    });
  }

  get myShop$(): Observable<PcShop | null> {
    return this.myShop.asObservable().pipe(
      filter((myShop): myShop is PcShop | null => myShop !== undefined),
      distinctUntilChanged()
    );
  }

  public async myShopId(): Promise<string | undefined> {
    return (await firstValueFrom(this.myShop$.pipe(first())))?.uid;
  }

  public async myUser(): Promise<PcUser | null> {
    return firstValueFrom(this.user$.pipe(first()));
  }

  get myShopCategoryType$(): Observable<PcShopCategoryType | undefined> {
    return this.myShop$.pipe(
      map((myShop) => {
        return myShop ? _first(myShop.categoryTypes) : undefined;
      }),
      distinctUntilChanged()
    );
  }

  /**
   * Shop Categories
   */

  public setShopCategories(shopCategories: PcShopCategory[] | undefined): void {
    this.zone.run(() => {
      this.shopCategories.next(shopCategories);
    });
  }

  public shopCategories$ = this.shopCategories.asObservable();

  get getShopsAsAutocompleteOptions$(): Observable<
    PcAutocompleteOptionGroupable[]
  > {
    return combineLatest([this.shops$, this.shopCategories$]).pipe(
      filter(([shops, shopCategories]) => !!shops && !!shopCategories),
      map(([shops, shopCategories]) => {
        if (!shops || !shopCategories) {
          return [];
        }
        return shops.map((shop) => {
          return {
            key: shop.uid,
            label: shop.name,
            parent: shop.categoryTypes,
            groups: shop.shopCategories.map((shopCategory) => {
              return (
                shopCategories?.find((s) => s.uid === shopCategory.id)?.name ??
                ''
              );
            }),
          };
        });
      })
    );
  }

  /**
   * Offers
   */
  public setOffers(offers: PcOffer[]): void {
    this.zone.run(() => {
      this.offers.next(offers);
    });
  }

  public offers$ = this.offers
    .asObservable()
    .pipe(filter((items): items is PcOffer[] => items !== undefined));

  public setFeedOffers(offers: PcOffer[]): void {
    this.zone.run(() => {
      this.feedOffers.next(offers);
    });
  }

  public feedOffers$ = this.feedOffers
    .asObservable()
    .pipe(filter((items): items is PcOffer[] => items !== undefined));

  get offersReservePickup$(): Observable<PcOffer[]> {
    return this.offers$.pipe(
      map((offers) =>
        offers?.filter((offer) => offer.type === 'reservepickup')
      ),
      distinctUntilChanged()
    );
  }

  get coupons$(): Observable<PcOffer[]> {
    return this.offers$.pipe(
      map((offers) => {
        return offers.filter((offer) => offer.type === 'coupon');
      }),
      distinctUntilChanged()
    );
  }

  /**
   * News
   */

  public setNews(news: PcNews[]): void {
    this.zone.run(() => {
      this.news.next(news);
    });
  }

  public news$ = this.news
    .asObservable()
    .pipe(filter((items): items is PcNews[] => items !== undefined));

  public setFeedNews(news: PcNews[]): void {
    this.zone.run(() => {
      this.feedNews.next(news);
    });
  }

  public feedNews$ = this.feedNews
    .asObservable()
    .pipe(filter((items): items is PcNews[] => items !== undefined));

  /**
   * Events
   */

  public setEvents(events: PcEvent[]): void {
    this.zone.run(() => {
      this.events.next(events);
    });
  }

  public events$ = this.events
    .asObservable()
    .pipe(filter((items): items is PcEvent[] => items !== undefined));

  public setFeedEvents(events: PcEvent[]): void {
    this.zone.run(() => {
      this.feedEvents.next(events);
    });
  }

  public feedEvents$ = this.feedEvents
    .asObservable()
    .pipe(filter((items): items is PcEvent[] => items !== undefined));

  /**
   * Competitions
   */

  public setCompetitions(competitions: PcCompetition[]): void {
    this.zone.run(() => {
      this.competitions.next(competitions);
    });
  }

  public competitions$ = this.competitions
    .asObservable()
    .pipe(filter((items): items is PcCompetition[] => items !== undefined));

  public setFeedCompetitions(competitions: PcCompetition[]): void {
    this.zone.run(() => {
      this.feedCompetitions.next(competitions);
    });
  }

  public feedCompetitions$ = this.feedCompetitions
    .asObservable()
    .pipe(filter((items): items is PcCompetition[] => items !== undefined));

  /**
   * Tags
   */

  public setTags(tags: PcTag[] | undefined): void {
    this.zone.run(() => {
      this.tags.next(tags);
    });
  }

  public tags$ = this.tags.asObservable().pipe(
    filter((items): items is PcTag[] => items !== undefined),
    distinctUntilChanged()
  );

  /**
   * Forecast
   */

  public setForecast(forecast: PcForecast | undefined): void {
    this.zone.run(() => {
      this.forecast.next(forecast);
    });
  }

  public forecast$ = this.forecast.asObservable().pipe(
    filter((items): items is PcForecast => items !== undefined),
    distinctUntilChanged()
  );

  /**
   * Holidays
   */

  public setHolidays(holidays: PcHoliday[] | undefined): void {
    this.zone.run(() => {
      this.holidays.next(holidays);
    });
  }

  public holidays$ = this.holidays.asObservable().pipe(
    filter((items): items is PcHoliday[] => items !== undefined),
    distinctUntilChanged()
  );

  public holidaysFuture$ = this.holidays
    .asObservable()
    .pipe(
      map((holidays) =>
        holidays?.filter((holiday) => isFuture(endOfDay(holiday.date)))
      )
    );

  /**
   * Cockpit-Users
   */

  public setCockpitUsers(cockpitUsers: PcCockpitUser[] | undefined): void {
    this.zone.run(() => {
      this.cockpitUsers.next(cockpitUsers);
    });
  }

  public cockpitUsers$ = this.cockpitUsers.asObservable().pipe(
    filter((items): items is PcCockpitUser[] => items !== undefined),
    distinctUntilChanged()
  );

  public cockpitUser$(uid: string): Observable<PcCockpitUser | undefined> {
    return this.cockpitUsers.pipe(
      map((cockpitUsers) => cockpitUsers?.find((user) => user.uid === uid)),
      distinctUntilChanged()
    );
  }

  public cockpitUserById$(uid: string): Observable<PcCockpitUser | undefined> {
    return this.cockpitUsers$.pipe(
      map((users) => {
        return users?.find((user) => user.uid === uid);
      })
    );
  }

  /**
   * Content-Edit Feed-Type
   */

  public setContentEditHeaderData(
    data: PcContentEditHeaderData | undefined
  ): void {
    this.zone.run(() => {
      this.contentEditHeaderData.next(data);
    });
  }

  public contentEditHeaderData$ = this.contentEditHeaderData.asObservable();

  /**
   * News
   */

  public setNotifications(
    notifications: PcNotification[],
    scope: PcNotificationScope,
    read: boolean
  ): void {
    this.zone.run(() => {
      if (read) {
        if (scope === 'shop') {
          this.notificationsShopRead.next(notifications);
        } else if (scope === 'user') {
          this.notificationsUserRead.next(notifications);
        }
      } else {
        if (scope === 'shop') {
          this.notificationsShopUnread.next(notifications);
        } else if (scope === 'user') {
          this.notificationsUserUnread.next(notifications);
        }
      }
    });
  }

  get notifications$(): Observable<PcNotification[]> {
    return combineLatest([
      this.notificationsShopRead,
      this.notificationsUserRead,
      this.notificationsShopUnread,
      this.notificationsUserUnread,
    ]).pipe(
      map(
        ([
          notificationShopRead,
          notificationUserRead,
          notificationShopUnread,
          notificationUserUnread,
        ]) => {
          const notifications: PcNotification[] = [];
          if (notificationShopRead) {
            notifications.push(...notificationShopRead);
          }
          if (notificationUserRead) {
            notifications.push(...notificationUserRead);
          }
          if (notificationShopUnread) {
            notifications.push(...notificationShopUnread);
          }
          if (notificationUserUnread) {
            notifications.push(...notificationUserUnread);
          }

          return notifications;
        }
      ),
      distinctUntilChanged()
    );
  }

  /**
   * Bookings
   */

  public setBookings(bookings: PcBooking[] | undefined): void {
    this.zone.run(() => {
      this.bookings.next(bookings);
    });
  }

  public bookings$ = this.bookings.asObservable();

  get bookingsReservePickup$(): Observable<PcBooking[] | undefined> {
    return this.bookings.pipe(
      map((bookings) => bookings?.filter((booking) => !!booking.offer)),
      distinctUntilChanged()
    );
  }

  /**
   * Shop Opening Hours
   */

  public setMyShopOpeningHoursRequest(
    request: PcShopOpeningHoursRequest | undefined
  ): void {
    this.zone.run(() => {
      this.myShopOpeningHoursRequest.next(request);
    });
  }

  public myShopOpeningHoursRequest$ =
    this.myShopOpeningHoursRequest.asObservable();

  /**
   * Announcements
   */

  public setAnnouncements(announcements: PcAnnouncement[] | undefined): void {
    this.zone.run(() => {
      this.announcements.next(announcements);
    });
  }

  public announcements$ = this.announcements.asObservable().pipe(
    filter((items): items is PcAnnouncement[] => items !== undefined),
    distinctUntilChanged()
  );

  get announcementsActive$(): Observable<PcAnnouncement[] | undefined> {
    return this.announcements.pipe(
      map((announcements) =>
        announcements?.filter((announcement) =>
          isAnnouncementActive(announcement)
        )
      ),
      distinctUntilChanged()
    );
  }

  get announcementsInactive$(): Observable<PcAnnouncement[] | undefined> {
    return this.announcements.pipe(
      map((announcements) =>
        announcements?.filter(
          (announcement) => !isAnnouncementActive(announcement)
        )
      ),
      distinctUntilChanged()
    );
  }

  public shopSpecialHolidays$(
    shopId: string
  ): Observable<PcOpeningHoursDay[] | undefined> {
    return this.holidays$.pipe(
      map((holidays) => {
        return sortBy(
          compact(
            holidays
              ?.filter((holiday) => {
                return (
                  isFuture(endOfDay(holiday.date)) &&
                  holiday.openedShops?.find(
                    (openedShop) => openedShop.shopID === shopId
                  )
                );
              })
              .map((holiday) => {
                const openedShop = holiday.openedShops?.find(
                  (openedShop) => openedShop.shopID === shopId
                );

                if (!openedShop) {
                  return;
                }

                return {
                  from: openedShop.from,
                  to: openedShop.to,
                  date: holiday.date,
                  name: holiday.name,
                  holiday: this.angularFirestore
                    .collection<PcHolidayFirebase>(PC_COLLECTIONS.HOLIDAYS)
                    .doc(holiday.uid).ref,
                };
              })
          ),
          'date'
        );
      }),
      distinctUntilChanged()
    );
  }

  /**
   * Reports
   */

  public setReports(reports: PcReport[] | null): void {
    this.zone.run(() => {
      this.reports.next(reports);
    });
  }

  public reports$ = this.reports.asObservable().pipe(
    filter((items): items is PcReport[] => items !== undefined),
    distinctUntilChanged()
  );

  /**
   * Jobs
   */

  public setJobs(jobs: PcJob[] | undefined): void {
    this.zone.run(() => {
      this.jobs.next(jobs);
    });
  }

  public jobs$ = this.jobs.asObservable().pipe(
    filter((items): items is PcJob[] => items !== undefined),
    distinctUntilChanged()
  );

  public jobById$(uid: string): Observable<PcJob | undefined> {
    return this.jobs$.pipe(
      map((jobs) => {
        return jobs?.find((job) => job.uid === uid);
      })
    );
  }

  /**
   * Kids
   */

  public setKids(kids: PcKidsCheckin[] | undefined): void {
    this.zone.run(() => {
      this.kids.next(kids);
    });
  }

  public kids$ = this.kids.asObservable();

  /**
   * ShopContract
   */

  public setShopContracts(shopContracts: PcShopContract[] | undefined): void {
    this.zone.run(() => {
      this.shopContracts.next(shopContracts);
    });
  }

  public shopContracts$ = this.shopContracts.asObservable();

  /**
   * Ticketing
   */

  public setTickets(tickets: PcTicket[] | null): void {
    this.zone.run(() => {
      this.tickets.next(tickets);
    });
  }

  public tickets$ = this.tickets.asObservable();

  public setTicketCategories(
    ticketCategories: PcTicketCategory[] | null
  ): void {
    this.zone.run(() => {
      this.ticketCategories.next(ticketCategories);
    });
  }

  public ticketCategories$ = this.ticketCategories.asObservable();

  public setTicketLocations(ticketLocations: PcTicketLocation[] | null): void {
    this.zone.run(() => {
      this.ticketLocations.next(ticketLocations);
    });
  }

  public ticketLocations$ = this.ticketLocations.asObservable();

  public ticketById$(uid: string): Observable<PcTicket | undefined> {
    return this.tickets$.pipe(
      map((tickets) => {
        return tickets?.find((ticket) => ticket.uid === uid);
      })
    );
  }
}
