import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { neverError } from '@pc-helpers';
import { PcContentCreateKind } from '@pc-types';
import { PcModelKindPipe } from '../pc-model-type/pc-model-type.pipe';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'pcContentCreateKind',
})
export class PcContentCreateKindPipe implements PipeTransform {
  constructor(private modelKindPipe: PcModelKindPipe) {}

  public transform(
    value: PcContentCreateKind | undefined | null
  ): string | undefined {
    if (!value) {
      return undefined;
    }

    switch (value) {
      case 'offer_menu':
        return 'Wochenmenü';
      case 'offer_coupon':
        return 'Gutscheine';
      case 'competition':
        return this.modelKindPipe.transform(value);
      case 'event':
        return this.modelKindPipe.transform(value);
      case 'offer':
        return this.modelKindPipe.transform(value);
      case 'news':
        return this.modelKindPipe.transform(value);
      default:
        throw neverError('', value);
    }
  }
}
