import { Injectable } from '@angular/core';
import { AngularFirestore, Query } from '@angular/fire/compat/firestore';
import { promotionConverter } from '@pc-converter';
import { EnvironmentService, StoreService } from '@pc-services';
import {
  PcEnv,
  PcPromotion,
  PcPromotionFirebase,
  PcStatusSimple,
  PC_COLLECTIONS,
} from '@pc-types';
import { compact, keys, pick } from 'lodash-es';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PromotionService {
  constructor(
    private angularFirestore: AngularFirestore,
    protected store: StoreService,
    protected envService: EnvironmentService
  ) {}

  public delete(promotionId: string): Promise<void> {
    const item: Partial<PcPromotion> = {};
    item.status = 'deleted';
    item.modified = new Date();

    const itemForFirebase = pick(
      promotionConverter.toFirestore(item),
      keys(item)
    );

    return this.angularFirestore
      .collection<PcPromotionFirebase>(PC_COLLECTIONS.COCKPIT_PROMOTIONS)
      .doc(promotionId)
      .update(itemForFirebase);
  }

  public fetchAll(): void {
    this.angularFirestore
      .collection<PcPromotionFirebase>(
        PC_COLLECTIONS.COCKPIT_PROMOTIONS,
        (ref) => {
          let query: Query = ref;
          const env: PcEnv = this.envService.getFirebaseEnv();
          const status: PcStatusSimple = 'deleted';

          query = query.where('env', '==', env).where('status', '!=', status);

          return query;
        }
      )
      .valueChanges({ idField: 'uid' })
      .pipe(
        map((promotions) => promotions.map(promotionConverter.fromFirestore)),
        distinctUntilChanged()
      )
      .subscribe((promotions) => {
        this.store.setPromotions(compact(promotions));
      });
  }

  public async update(
    promotionId: string,
    item: Partial<PcPromotion>
  ): Promise<void> {
    item.modified = new Date();
    const itemForFirebase = pick(
      promotionConverter.toFirestore(item),
      keys(item)
    );

    return this.angularFirestore
      .collection<PcPromotionFirebase>(PC_COLLECTIONS.COCKPIT_PROMOTIONS)
      .doc(promotionId)
      .update(itemForFirebase);
  }

  public async create(item: Partial<PcPromotion>): Promise<void> {
    item.env = this.envService.getFirebaseEnv();
    item.enabled = false;
    item.created = new Date();
    item.modified = new Date();
    item.status = 'published';

    const itemForFirebase = promotionConverter.toFirestore(item);
    if (!itemForFirebase) {
      return Promise.resolve();
    }

    const ref = this.angularFirestore.collection<PcPromotionFirebase>(
      PC_COLLECTIONS.COCKPIT_PROMOTIONS
    );

    await ref.add(itemForFirebase);
  }

  public setEnabled(promotionId: string, enabled: boolean): Promise<void> {
    const item: Partial<PcPromotion> = {
      enabled,
      modified: new Date(),
    };

    const itemForFirebase = pick(
      promotionConverter.toFirestore(item),
      keys(item)
    );

    return this.angularFirestore
      .collection<PcPromotionFirebase>(PC_COLLECTIONS.COCKPIT_PROMOTIONS)
      .doc(promotionId)
      .update(itemForFirebase);
  }
}
