import { Injectable } from '@angular/core';
import {
  PcAlgoliaIndex,
  PcAlgoliaSearchOptions,
  PcUserProfileAlgolia,
} from '@pc-types';
import algoliasearch, { SearchClient } from 'algoliasearch';

@Injectable({
  providedIn: 'root',
})
export class AlgoliaService {
  private _client?: SearchClient;

  private readonly APP_ID = '3K6A87G9K3';
  private readonly API_KEY = '8da41dbb75fe508229a45ee4c5191f55';

  constructor() {}

  private getClient(): SearchClient {
    if (!this._client) {
      this._client = algoliasearch(this.APP_ID, this.API_KEY);
    }
    return this._client;
  }

  public getUsers(searchTerm: string): Promise<PcUserProfileAlgolia[]> {
    return this.search<PcUserProfileAlgolia>('user-profiles', searchTerm, {
      attributesToRetrieve: ['firstname', 'lastname', 'email', 'objectID'],
      hitsPerPage: 20,
    });
  }

  public async getUserByEmail(
    email: string
  ): Promise<PcUserProfileAlgolia | undefined> {
    const users = await this.getUsers(email);

    return users.find((user) => user.email === email);
  }

  private search<AlgoliaType>(
    algoliaIndex: PcAlgoliaIndex,
    searchTerm: string,
    options?: PcAlgoliaSearchOptions
  ): Promise<AlgoliaType[]> {
    return new Promise((resolve) => {
      const index = this.getClient().initIndex(algoliaIndex);

      index.search<AlgoliaType>(searchTerm, options).then(({ hits }) => {
        resolve(hits);
      });
    });
  }
}
