import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  CollectionReference,
  Query,
} from '@angular/fire/compat/firestore';
import { shopOpeningHoursRequestConverter } from '@pc-converter';
import { StoreService } from '@pc-services';
import {
  PcCockpitUser,
  PcEnv,
  PcShop,
  PcShopFirebase,
  PcShopOpeningHoursRequest,
  PcShopOpeningHoursRequestFirebase,
  PcShopOpeningHoursRequestStatus,
  PC_COLLECTIONS,
} from '@pc-types';
import { compact, first, keys, pick } from 'lodash-es';
import { firstValueFrom, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { EnvironmentService } from '../environment/environment.service';

@Injectable({
  providedIn: 'root',
})
export class ShopOpeningHoursRequestsService {
  constructor(
    private angularFirestore: AngularFirestore,
    private store: StoreService,
    private envService: EnvironmentService
  ) {}

  public fetchMyRequest(): void {
    this.store.myGlobalPermissions$
      .pipe(
        filter((globalPermissions) =>
          globalPermissions.modules.includes('shop')
        ),
        switchMap(() => {
          return this.store.myShop$.pipe(
            filter((myShop): myShop is PcShop => !!myShop),
            switchMap((myShop) => {
              return this.requestByShopId$(myShop.uid);
            })
          );
        })
      )

      .subscribe((request) => {
        this.store.setMyShopOpeningHoursRequest(request);
      });
  }

  public async create(
    item: Partial<PcShopOpeningHoursRequest>,
    shopId: string
  ): Promise<void> {
    if (!item) {
      console.warn('item missing');
      return Promise.resolve();
    }

    const user = await this.store.myUser();
    if (!user) {
      return Promise.resolve();
    }

    item.modified = new Date();
    item.author = this.angularFirestore.doc<PcCockpitUser>(
      `${PC_COLLECTIONS.COCKPIT_USER_PROFILES}/${user.uid}`
    ).ref;

    const itemForFirebase = shopOpeningHoursRequestConverter.toFirestore(item);
    if (!itemForFirebase) {
      return Promise.resolve();
    }

    await this.angularFirestore
      .collection<PcShopFirebase>(PC_COLLECTIONS.SHOPS)
      .doc(shopId)
      .collection<PcShopOpeningHoursRequestFirebase>(
        PC_COLLECTIONS.SHOP_OPENING_HOURS_REQUESTS
      )
      .add(itemForFirebase);
  }

  public async update(
    openingHoursRequestId: string,
    item: Partial<PcShopOpeningHoursRequest>,
    shopId: string
  ): Promise<void> {
    const user = await this.store.myUser();
    if (!user) {
      return Promise.resolve();
    }

    item.modified = new Date();
    item.author = this.angularFirestore.doc<PcCockpitUser>(
      `${PC_COLLECTIONS.COCKPIT_USER_PROFILES}/${user.uid}`
    ).ref;

    const itemForFirebase = pick(
      shopOpeningHoursRequestConverter.toFirestore(item),
      keys(item)
    );

    return this.angularFirestore
      .collection<PcShopFirebase>(PC_COLLECTIONS.SHOPS)
      .doc(shopId)
      .collection<PcShopOpeningHoursRequestFirebase>(
        PC_COLLECTIONS.SHOP_OPENING_HOURS_REQUESTS
      )
      .doc(openingHoursRequestId)
      .update(itemForFirebase);
  }

  public requestByShopId$(
    shopId: string
  ): Observable<PcShopOpeningHoursRequest | undefined> {
    return this.requestsByShopId$(shopId).pipe(
      map((requests) => first(requests)),
      distinctUntilChanged()
    );
  }

  private requestsByShopId$(
    shopId: string
  ): Observable<PcShopOpeningHoursRequest[] | undefined> {
    return this.angularFirestore
      .collection<PcShopFirebase>(PC_COLLECTIONS.SHOPS)
      .doc(shopId)
      .collection<PcShopOpeningHoursRequestFirebase>(
        PC_COLLECTIONS.SHOP_OPENING_HOURS_REQUESTS,
        (ref) => {
          return this.getOpenRequestsQuery(ref);
        }
      )
      .valueChanges({ idField: 'uid' })
      .pipe(
        map((requests) =>
          compact(
            requests?.map((request) =>
              shopOpeningHoursRequestConverter.fromFirestore(request)
            )
          )
        )
      );
  }

  public async updateOpenRequests(
    shopId: string,
    item: Partial<PcShopOpeningHoursRequest>
  ): Promise<void> {
    const itemForFirebase = pick(
      shopOpeningHoursRequestConverter.toFirestore(item),
      keys(item)
    );

    const batch = this.angularFirestore.firestore.batch();

    const docs = await firstValueFrom(
      this.angularFirestore
        .collection<PcShopFirebase>(PC_COLLECTIONS.SHOPS)
        .doc(shopId)
        .collection<PcShopOpeningHoursRequestFirebase>(
          PC_COLLECTIONS.SHOP_OPENING_HOURS_REQUESTS,
          (ref) => {
            return this.getOpenRequestsQuery(ref);
          }
        )
        .get()
    );

    docs?.docs.forEach((doc) => {
      batch.update(doc.ref, itemForFirebase);
    });

    return batch.commit();
  }

  private getOpenRequestsQuery(ref: CollectionReference): Query {
    const validStatus: PcShopOpeningHoursRequestStatus[] = [
      'published',
      'review',
      'draft',
      'rejected',
    ];
    const env: PcEnv = this.envService.getFirebaseEnv();

    return ref
      .where('status', 'in', validStatus)
      .where('env', '==', env)
      .orderBy('created', 'desc');
  }
}
