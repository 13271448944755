import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IconModule } from '@pc-ui/icon';
import { TooltipModule } from '@pc-ui/tooltip/tooltip.module';
import { ContentcreateMenuItemComponent } from './components/contentcreate-menu-item/contentcreate-menu-item.component';
import { ContentcreateMenuFlapComponent } from './contentcreate-menu-flap/contentcreate-menu-flap.component';
import { ContentcreateMenuTooltipComponent } from './contentcreate-menu-tooltip/contentcreate-menu-tooltip.component';

@NgModule({
  declarations: [
    ContentcreateMenuTooltipComponent,
    ContentcreateMenuFlapComponent,
    ContentcreateMenuItemComponent,
  ],
  imports: [CommonModule, TooltipModule, IconModule, RouterModule],
  exports: [ContentcreateMenuTooltipComponent, ContentcreateMenuFlapComponent],
})
export class ContentcreateMenuModule {}
