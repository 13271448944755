import { MatDialogConfig } from '@angular/material/dialog';
import { PcConfirmDialogData } from '@pc-dialogs/confirm-dialog/confirm-dialog.component';
import {
  PcTicket,
  PcTicketLevel,
  PC_DIALOG_MAX_SIZE,
  PC_DIALOG_SIZE,
} from '@pc-types';

export function isCheckAndArchiveButtonVisible(ticket: PcTicket): boolean {
  return ticket.enabled && ticket.status === 'processing';
}

export function getArchiveDialogConfig(): MatDialogConfig<PcConfirmDialogData> {
  return {
    width: PC_DIALOG_SIZE.S,
    maxWidth: PC_DIALOG_MAX_SIZE.M,
    data: {
      text: 'Wollen Sie diesen Eintrag wirklich archivieren?',
      confirmText: 'Archivieren',
      cancelText: 'Abbrechen',
    },
  };
}

export function getLevelLabel(level: PcTicketLevel | undefined): string {
  if (!level) {
    return '';
  }
  switch (level) {
    case '0':
      return 'Erdgeschoß';
    case '1':
      return '1. Obergeschoß';
    case '2':
      return '2. Obergeschoß';
    case '3':
      return '3. Obergeschoß';
    case '4':
      return '4. Obergeschoß';
    case '5':
      return '5. Obergeschoß';
    case '6':
      return '6. Obergeschoß';
    case '7':
      return '7. Obergeschoß';
    case '8':
      return '8. Obergeschoß';
    default:
      return level;
  }
}
