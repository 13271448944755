import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  private isEnabled = false;

  constructor(private gtmService: GoogleTagManagerService) {}

  public enable(): void {
    this.isEnabled = true;
  }

  public disable(): void {
    this.isEnabled = false;
  }

  public pushTag(object: object): void {
    if (this.isEnabled) {
      this.gtmService.pushTag(object);
    }
  }
}
