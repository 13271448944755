import { PcMapFirebase } from '@pc-types';

export function convertArrayToFirebaseMap<T extends string = string>(
  array: T[] | undefined
): PcMapFirebase<T> {
  const firebaseMap: PcMapFirebase = {};

  array?.forEach((item) => {
    firebaseMap[item] = true;
  });
  return firebaseMap;
}

export function convertFirebaseMapToArray<T extends string = string>(
  firebaseMap: PcMapFirebase<T> | undefined
): T[] {
  if (!firebaseMap) {
    return [];
  }
  return Object.keys(firebaseMap).filter((item) => {
    return firebaseMap[item as T];
  }) as T[];
}
