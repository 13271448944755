import { Injectable } from '@angular/core';
import { PcEnv, PcEnvironment, PcSentryEnv } from '@pc-types';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  private environment!: PcEnvironment;

  constructor() {}

  public getFirebaseEnv(): PcEnv {
    return this.environment.env;
  }

  public getSentryEnv(): PcSentryEnv {
    return this.environment.sentryEnv;
  }

  public getWebsiteUrl(): string {
    return this.environment.websiteUrl;
  }

  public setEnv(environment: PcEnvironment): void {
    this.environment = environment;
  }
}
