<div class="pc-btn-inner inline-flex items-center justify-center" [ngClass]="getButtonClasses()">
  <div class="flex items-center text-left" [ngClass]="getContentClasses()">
    <div class="shrink-0" *ngIf="isLoading || icon">
      <pc-spinner *ngIf="isLoading" [size]="iconSize"></pc-spinner>
      <pc-icon *ngIf="!isLoading && icon" class="fill-current" [ngClass]="getIconClasses()" [size]="iconSize" [icon]="icon"></pc-icon>
    </div>
    <span>
      <ng-content></ng-content>
    </span>
  </div>
</div>
