import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CookiesService } from 'src/app/shared/services/application/cookies/cookies.service';
import { RouteService } from 'src/app/shared/services/route/route.service';

@Component({
  selector: 'pc-cookiebar',
  templateUrl: './cookiebar.component.html',
  styleUrls: ['./cookiebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookiebarComponent implements OnInit {
  constructor(
    private cookiesService: CookiesService,
    private routeService: RouteService
  ) {}

  ngOnInit(): void {}

  public onDataProtectionClicked(): void {
    window.open(this.routeService.routes.dataProtection.url, '_blank');
  }

  public onCookieSettingsClicked(): void {
    window.open(this.routeService.routes.cookieSettings.url, '_self');
  }

  public onDeclineAllClick(): void {
    this.cookiesService.declineAll();
    this.hideCookieBar();
  }

  public onAcceptAllClick(): void {
    this.cookiesService.acceptAll();
    this.hideCookieBar();
  }

  private hideCookieBar(): void {
    this.cookiesService.hideCookieBar();
  }
}
