import {
  convertDateToTsFirebase,
  convertTsMilliSecondsToDate,
} from '@pc-helpers';
import {
  PcCompetitionParticipant,
  PcCompetitionParticipantFirebase,
  PcFirestoreConverter,
} from '@pc-types';
import firebase from 'firebase/compat/app';

export const competitionParticipantConverter: PcFirestoreConverter<
  PcCompetitionParticipantFirebase,
  PcCompetitionParticipant
> = {
  fromFirestore: (input) => {
    if (!input || !input.complete) {
      return undefined;
    }

    return {
      uid: input.uid,
      firstname: input.firstname ?? '',
      lastname: input.lastname ?? '',
      winner: input.winner ? input.winner.toDate() : undefined,
      modified: input.modified
        ? typeof input.modified === 'number'
          ? convertTsMilliSecondsToDate(input.modified)
          : input.modified?.toDate()
        : new Date(),
      complete: true,
      phone: input.phone,
      email: input.email,
      answer: input.answer,
      rewards: input.rewards,
      imageUpload: input.imageUpload,
      created: input.created
        ? typeof input.created === 'number'
          ? convertTsMilliSecondsToDate(input.created)
          : input.created?.toDate()
        : new Date(),
      postcode: input.postcode,
      street: input.street,
      country: input.country,
      city: input.city,
      userID: input.userID,
      competition: input.competition,
      answerIndex: input.answerIndex,
      collected: input.collected?.map((collectedItem) => {
        return {
          code: collectedItem.code,
          created: collectedItem.created
            ? collectedItem.created.toDate()
            : new Date(),
        };
      }),
      dob: input.dob
        ? typeof input.dob === 'number'
          ? convertTsMilliSecondsToDate(input.dob)
          : input.dob.toDate()
        : undefined,
    };
  },
  toFirestore: (input) => {
    if (!input) {
      return undefined;
    }
    return {
      uid: input.uid,
      firstname: input.firstname ?? '',
      lastname: input.lastname ?? '',
      winner: input.winner ? convertDateToTsFirebase(input.winner) : undefined,
      modified: firebase.firestore.Timestamp.now(),
      phone: input.phone,
      email: input.email,
      answer: input.answer,
      rewards: input.rewards,
      imageUpload: input.imageUpload,
      complete: undefined,
      created: input.created
        ? convertDateToTsFirebase(input.created)
        : firebase.firestore.Timestamp.now(),
      postcode: input.postcode,
      street: input.street,
      country: input.country,
      city: input.city,
      userID: input.userID,
      competition: input.competition,
      answerIndex: input.answerIndex,
      collected: input.collected?.map((collectedItem) => {
        return {
          code: collectedItem.code,
          created: collectedItem.created
            ? convertDateToTsFirebase(collectedItem.created)
            : firebase.firestore.Timestamp.now(),
        };
      }),
      dob: input.dob ? convertDateToTsFirebase(input.dob) : undefined,
    };
  },
};
