import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ShopLogoModule } from '@pc-ui/shop-logo/shop-logo.module';
import { UserImageModule } from '@pc-ui/user-image';
import { UserItemComponent } from './user-item.component';

@NgModule({
  declarations: [UserItemComponent],
  imports: [CommonModule, ShopLogoModule, UserImageModule],
  exports: [UserItemComponent],
})
export class UserItemModule {}
