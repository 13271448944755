import { HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import {
  AngularFirestoreModule,
  Settings,
  SETTINGS,
} from '@angular/fire/compat/firestore';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { NdsModule } from '@netural/nds';
import { DialogAllModule } from '@pc-dialogs/dialog-all.module';
import { PC_ENVIRONMENT } from '@pc-environment';
import { LayoutModule } from '@pc-layout';
import { createErrorHandler, TraceService } from '@sentry/angular';
import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
// import { NdsModule } from '../../../../netural/nds/projects/nds/src/public-api';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';

const firestoreSettings: Settings = {
  ignoreUndefinedProperties: true,
  merge: true,
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(PC_ENVIRONMENT.firebase),
    AngularFirestoreModule,
    LayoutModule,
    DialogAllModule,
    NdsModule.forRoot({
      basePath: 'designsystem',
      storyblok: {
        version: 'draft',
        accessToken: 'tOiFyIaerkKCDM4g4eQiRQtt',
      },
      logLevel: 'TRACE',
    }),
  ],
  providers: [
    { provide: SETTINGS, useValue: firestoreSettings },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        fullLibraryLoader: () => import('highlight.js'),
      },
    },
    { provide: LOCALE_ID, useValue: 'de-AT' },
    { provide: 'googleTagManagerId', useValue: 'GTM-WMCSFWK' },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: PC_ENVIRONMENT.env === 'dev',
      }),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
