import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { reportConverter } from '@pc-converter';
import { convertDateToTsFirebase } from '@pc-helpers';
import { EnvironmentService, StoreService } from '@pc-services';
import {
  PC_COLLECTIONS,
  PcReport,
  PcReportFirebase,
  PcShopFirebase,
} from '@pc-types';
import {
  addYears,
  endOfMonth,
  endOfYear,
  getMonth,
  getYear,
  isBefore,
  startOfMonth,
  startOfYear,
  subYears,
} from 'date-fns';
import { compact, keys, pick, uniqBy } from 'lodash-es';
import { Observable, combineLatest, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  constructor(
    private angularFirestore: AngularFirestore,
    private store: StoreService,
    private envService: EnvironmentService
  ) {}

  public fetchAllForMyShop(): void {
    combineLatest([this.store.permissions$, this.store.myShop$])
      .pipe(
        switchMap(([permissions, myShop]) => {
          const shopId = myShop?.uid;
          const hasPermission = permissions.reports === 'tenant';
          if (!shopId || !hasPermission) {
            return of(null);
          }
          return this.fetchByShopIdAndContractId$(shopId, null);
        })
      )
      .subscribe((reports) => {
        this.store.setReports(reports);
      });
  }

  public fetchByShopIdAndContractId$(
    shopId: string,
    contractId: string | null
  ): Observable<PcReport[]> {
    return this.angularFirestore
      .collection<PcShopFirebase>(PC_COLLECTIONS.SHOPS)
      .doc(shopId)
      .collection<PcReportFirebase>(PC_COLLECTIONS.REPORTS, (ref) => {
        const env = this.envService.getFirebaseEnv();
        return ref.where('env', '==', env);
      })
      .valueChanges({ idField: 'uid' })
      .pipe(
        map((reports) => {
          if (contractId) {
            return reports.filter((report) => report.contractId === contractId);
          }
          return reports;
        }),
        map((reports) => this.mapReports(reports)),
        distinctUntilChanged()
      );
  }

  public async update(
    reportId: string,
    item: Partial<PcReport>,
    shopId: string
  ): Promise<void> {
    item.modified = new Date();

    const itemForFirebase = pick(reportConverter.toFirestore(item), keys(item));

    return this.angularFirestore
      .collection<PcShopFirebase>(PC_COLLECTIONS.SHOPS)
      .doc(shopId)
      .collection<PcReportFirebase>(PC_COLLECTIONS.REPORTS)
      .doc(reportId)
      .update(itemForFirebase);
  }

  public async create(item: Partial<PcReport>, shopId: string): Promise<void> {
    if (!item) {
      console.warn('item missing');
      return;
    }

    item.created = new Date();
    item.modified = new Date();
    item.shop = this.angularFirestore.doc<PcShopFirebase>(
      `${PC_COLLECTIONS.SHOPS}/${shopId}`
    ).ref;

    const itemForFirebase = reportConverter.toFirestore(item);
    if (!itemForFirebase) {
      return;
    }

    const ref = this.angularFirestore
      .collection<PcShopFirebase>(PC_COLLECTIONS.SHOPS)
      .doc(shopId)
      .collection<PcReportFirebase>(PC_COLLECTIONS.REPORTS);

    await ref.add(itemForFirebase);
  }

  public allReports$(): Observable<PcReport[]> {
    return this.getReportsBetweenDates$(
      subYears(new Date(), 15),
      addYears(new Date(), 1)
    );
  }

  public allReportsOfYears$(
    date: Date,
    dateCompare: Date
  ): Observable<PcReport[]> {
    const minDate = isBefore(date, dateCompare) ? date : dateCompare;
    const maxDate = isBefore(date, dateCompare) ? dateCompare : date;

    return this.getReportsBetweenDates$(
      startOfYear(minDate),
      endOfYear(maxDate)
    );
  }

  public allReportsOfMonth$(date: Date): Observable<PcReport[]> {
    return this.getReportsBetweenDates$(
      startOfMonth(date),
      endOfMonth(date)
    ).pipe(debounceTime(500));
  }

  private getReportsBetweenDates$(
    start: Date,
    end: Date
  ): Observable<PcReport[]> {
    return this.angularFirestore
      .collectionGroup<PcReportFirebase>(PC_COLLECTIONS.REPORTS, (ref) => {
        const env = this.envService.getFirebaseEnv();

        ref = ref.where('date', '<=', convertDateToTsFirebase(end));
        ref = ref.where('date', '>=', convertDateToTsFirebase(start));
        ref = ref.where('env', '==', env);

        return ref;
      })
      .valueChanges({ idField: 'uid' })
      .pipe(map((reports) => this.mapReports(reports)));
  }

  public recentReports$(): Observable<PcReport[]> {
    return this.angularFirestore
      .collectionGroup<PcReportFirebase>(PC_COLLECTIONS.REPORTS, (ref) => {
        const env = this.envService.getFirebaseEnv();

        ref = ref.where('env', '==', env).orderBy('created', 'desc').limit(100);

        return ref;
      })
      .valueChanges({ idField: 'uid' })
      .pipe(map((reports) => this.mapReports(reports)));
  }

  private mapReports(
    reports: (PcReportFirebase & {
      uid: string;
    })[]
  ): PcReport[] {
    return uniqBy(
      compact(
        reports.map((report) => reportConverter.fromFirestore(report))
      ).map((report) => {
        return {
          ...report,
          date: startOfMonth(report.date),
        };
      }),
      (report) =>
        `${getYear(report.date)}-${getMonth(report.date)}-${report.shop.id}`
    );
  }
}
