import { Injectable } from '@angular/core';
import { StorageService } from '@pc-services';
import {
  PcAcceptedCookies,
  PcCookieType,
  PC_COOKIE_BAR_HIDDEN_KEY,
  PC_COOKIE_PREFIX,
  PC_COOKIE_TYPES,
} from '@pc-types';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CookiesService {
  constructor(private storageService: StorageService) {}

  private _acceptedCookies$ = new BehaviorSubject<
    PcAcceptedCookies | undefined
  >(undefined);
  public acceptedCookies$ = this._acceptedCookies$.asObservable();

  private _isCookieBarHidden$ = new BehaviorSubject<boolean>(false);
  public isCookieBarHidden$ = this._isCookieBarHidden$.asObservable();

  public init(): void {
    this._acceptedCookies$.next(this.loadFromStorage());
    this._isCookieBarHidden$.next(this.loadCookieBarHiddenFromStorage());
  }

  private getFreshAcceptedCookies(): PcAcceptedCookies {
    return {
      statistics: false,
    };
  }

  public set(key: PcCookieType, isEnabled: boolean): void {
    let acceptedCookies = this._acceptedCookies$.getValue();
    if (!acceptedCookies) {
      acceptedCookies = this.getFreshAcceptedCookies();
    }
    acceptedCookies[key] = isEnabled;
    this.save(acceptedCookies);
  }

  public acceptAll(): void {
    this.setAll(true);
  }

  public declineAll(): void {
    this.setAll(false);
  }

  private setAll(isEnabled: boolean): void {
    const acceptedCookies = this.getFreshAcceptedCookies();
    PC_COOKIE_TYPES.forEach((cookieType) => {
      acceptedCookies[cookieType] = isEnabled;
    });
    this.save(acceptedCookies);
  }

  private save(acceptedCookies: PcAcceptedCookies): void {
    this.saveToStorage(acceptedCookies);
    this._acceptedCookies$.next(acceptedCookies);
  }

  public hideCookieBar(): void {
    this.saveCookieBarHiddenToStorage(true);
    this._isCookieBarHidden$.next(true);
  }

  private saveToStorage(acceptedCookies: PcAcceptedCookies): void {
    Object.keys(acceptedCookies).forEach((k) => {
      if (!acceptedCookies) {
        return;
      }
      const cookieType = k as PcCookieType;
      this.storageService.set<boolean>(
        PC_COOKIE_PREFIX + cookieType,
        !!acceptedCookies[cookieType]
      );
    });
  }

  private loadFromStorage(): PcAcceptedCookies {
    const ret = this.getFreshAcceptedCookies();
    PC_COOKIE_TYPES.forEach((cookieType) => {
      ret[cookieType] = !!this.storageService.get<boolean>(
        PC_COOKIE_PREFIX + cookieType
      );
    });
    return ret;
  }

  private saveCookieBarHiddenToStorage(isHidden: boolean): void {
    this.storageService.set<boolean>(PC_COOKIE_BAR_HIDDEN_KEY, isHidden);
  }

  private loadCookieBarHiddenFromStorage(): boolean {
    return !!this.storageService.get<boolean>(PC_COOKIE_BAR_HIDDEN_KEY);
  }
}
