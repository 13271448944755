export function convertNl2Br(value: string): string {
  if (!value) {
    return '';
  }

  return value.replace(/(?:\r\n|\r|\n)/g, '<br>');
}

export function convertBr2Nl(value: string): string {
  if (!value) {
    return '';
  }

  return value.replace(/<br>/g, '\n');
}
