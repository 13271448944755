import { PcCalendarItem } from '@pc-types';
import { endOfDay, isAfter, isBefore, startOfDay } from 'date-fns';

export function getCalendarItemsByDate<CalendarItemType extends PcCalendarItem>(
  items: CalendarItemType[],
  date: Date
): CalendarItemType[] {
  return items.filter((calendarItem) => {
    return !!calendarItem.dates.find((dateRange) => {
      return (
        !isAfter(date, endOfDay(dateRange.to)) &&
        !isBefore(date, startOfDay(dateRange.from))
      );
    });
  });
}

export function toPercentage(value: number): string {
  return `${Math.floor(Math.min(Math.max(value, 0), 1) * 100)}%`;
}
