import {
  convertArrayToFirebaseMap,
  convertDateToTsFirebase,
  convertFirebaseMapToArray,
  convertTsSecondsToDate,
} from '@pc-helpers';
import {
  PcFirestoreConverter,
  PcModelBase,
  PcModelFirebaseBase,
} from '@pc-types';
import firebase from 'firebase/compat/app';
import { statusFeedbackConverter } from './status-feedback.converter';
export const modelConverter: PcFirestoreConverter<
  PcModelFirebaseBase,
  PcModelBase
> = {
  fromFirestore: (input) => {
    if (!input || !input.modified || !input.created) {
      return undefined;
    }
    try {
      // ignore scope=direct
      if (
        input.scope !== 'public' &&
        input.scope !== 'roles' &&
        input.scope !== 'private'
      ) {
        return undefined;
      }

      return {
        uid: input.uid,
        created:
          typeof input.created === 'number'
            ? convertTsSecondsToDate(input.created)
            : input.created.toDate(),
        modified: input.modified.toDate(),
        tags: input.tags ? convertFirebaseMapToArray(input.tags) : [],
        shops: input.shops ? convertFirebaseMapToArray(input.shops) : [],
        isCrew:
          input.scope === 'roles' &&
          !!input.scopeRoles?.includes('shop-employee'),
        readonly: input.readonly ?? false,
        categoryTypes: input.categoryTypes ?? [],
        description: input.description ?? '',
        enabled: input.enabled ?? false,
        env: input.env ?? 'dev',
        images: input.images ?? [],
        slug: input.slug ?? '',
        status: input.status ?? 'draft',
        title: input.title ?? '',
        statusFeedback: statusFeedbackConverter.fromFirestore(
          input.statusFeedback
        ),
        websiteUrl: input.websiteUrl,
        websiteUrlTarget: input.websiteUrlTarget,
        promotions: input.promotions,
        author: input.author,
        highlight: input.highlight,
        highlightIndex: input.highlightIndex,
        highlightTitle: input.highlightTitle,
        highlightStart: input.highlightStart?.toDate(),
        highlightEnd: input.highlightEnd?.toDate(),
        ctaLabel: input.ctaLabel,
        ctaUrl: input.ctaUrl,
        url: input.url,
        isPrivate: input.scope === 'private',
        forceNotification: input.forceNotification,
        scope: input.scope ?? 'public',
        scopeRoles: input.scopeRoles ?? [],
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
  toFirestore: (input) => {
    if (!input) {
      return undefined;
    }
    try {
      return {
        created: firebase.firestore.Timestamp.now(),
        modified: firebase.firestore.Timestamp.now(),
        tags: input.tags ? convertArrayToFirebaseMap(input.tags) : {},
        shops: input.shops ? convertArrayToFirebaseMap(input.shops) : {},
        scope: input.scope ?? 'public',
        scopeRoles: input.scopeRoles,
        readonly: input.readonly ?? false,
        categoryTypes: input.categoryTypes ?? [],
        description: input.description ?? '',
        enabled: input.enabled ?? false,
        env: input.env ?? 'dev',
        images: input.images ?? [],
        slug: undefined,
        status: input.status ?? 'draft',
        title: input.title ?? '',
        statusFeedback: statusFeedbackConverter.toFirestore(
          input.statusFeedback
        ),
        websiteUrl: input.websiteUrl,
        websiteUrlTarget: input.websiteUrlTarget,
        promotions: input.promotions,
        author: input.author,
        highlight: input.highlight,
        highlightIndex: input.highlightIndex,
        highlightTitle: input.highlightTitle,
        highlightStart: input.highlightStart
          ? convertDateToTsFirebase(input.highlightStart)
          : null,
        highlightEnd: input.highlightEnd
          ? convertDateToTsFirebase(input.highlightEnd)
          : null,
        ctaLabel: input.ctaLabel,
        ctaUrl: input.ctaUrl,
        url: input.url,
        forceNotification: input.forceNotification,
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
};
