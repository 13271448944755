import { MatDialogConfig } from '@angular/material/dialog';
import { PcQuickactionMenuDialogData } from '@pc-types';

export function getQuickactionMenuDialogConfig(
  item: PcQuickactionMenuDialogData
): MatDialogConfig<PcQuickactionMenuDialogData> {
  return {
    width: '100%',
    maxWidth: 'none',
    data: item,
    position: {
      bottom: '0',
    },
    panelClass: 'pc-dialog-rounded-none',
  };
}
