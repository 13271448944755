import { PcPermissionRole, PcStatusFeedbackable } from '@pc-types';

export function isStatusFeedbackVisible(item: PcStatusFeedbackable): boolean {
  return (
    (item.status === 'draft' || item.status === 'rejected') &&
    !!item.statusFeedback
  );
}

export function isStatusErrorColor(item: PcStatusFeedbackable): boolean {
  return item.status === 'rejected' && !!item.statusFeedback;
}

export function getTextForRejectDialog(permission: PcPermissionRole): {
  headline: string;
  subline?: string;
} {
  return {
    headline:
      permission === 'tenant'
        ? 'Ihr Beitrag wurde abgelehnt'
        : 'Beitrag wurde abgelehnt',
    subline:
      permission === 'tenant'
        ? 'Berücksichtigen Sie dieses Feedback bevor Sie es erneut zur Freigabe senden.'
        : undefined,
  };
}
