import { modelConverter } from '@pc-converter';
import {
  convertDateToTsSeconds,
  convertTsSecondsToDate,
  dateFallback,
} from '@pc-helpers';
import { PcFirestoreConverter, PcNews, PcNewsFirebase } from '@pc-types';

export const newsConverter: PcFirestoreConverter<PcNewsFirebase, PcNews> = {
  fromFirestore: (input) => {
    if (
      !input ||
      typeof input.start !== 'number' ||
      typeof input.end !== 'number'
    ) {
      return undefined;
    }
    try {
      const model = modelConverter.fromFirestore(input);
      if (!model) {
        return undefined;
      }
      return {
        ...model,
        kind: 'news',
        archive: input.archive ?? false,
        readRequired: input.readRequired ?? false,
        start: convertTsSecondsToDate(input.start),
        end: convertTsSecondsToDate(input.end),
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
  toFirestore: (input) => {
    if (!input) {
      return undefined;
    }
    try {
      const model = modelConverter.toFirestore(input);
      if (!model) {
        return undefined;
      }
      return {
        ...model,
        readRequired:
          !!input.readRequired &&
          !!(model?.scope === 'roles') &&
          !!model?.scopeRoles?.includes('shop-employee'),
        archive: input.archive ?? false,
        start: convertDateToTsSeconds(dateFallback(input.start)),
        end: convertDateToTsSeconds(dateFallback(input.end)),
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
};
