import { DocumentReference } from '@angular/fire/compat/firestore';
import { convertDateToTsFirebase, dateFallback } from '@pc-helpers';
import {
  PcBooking,
  PcBookingFirebase,
  PcFirestoreConverter,
  PcShopFirebase,
} from '@pc-types';
import { addMilliseconds, differenceInMilliseconds } from 'date-fns';
import firebase from 'firebase/compat/app';
export const bookingConverter: PcFirestoreConverter<
  PcBookingFirebase,
  PcBooking
> = {
  fromFirestore: (input) => {
    if (
      !input ||
      !input.startDate ||
      !input.endDate ||
      !input.bookingDate ||
      !input.statusText ||
      !input.created ||
      !input.shop
    ) {
      return undefined;
    }
    try {
      return {
        uid: input.uid,
        firstName: input.firstName ?? '',
        lastName: input.lastName ?? '',
        number: input.number ?? '',
        persons: input.persons ?? 1,
        duration: input.duration ?? 0,
        smsConfirmation: input.smsConfirmation ?? false,
        emailConfirmation: input.emailConfirmation ?? false,
        created: input.created.toDate(),
        modified: input.modified
          ? input.modified.toDate()
          : input.created.toDate(),
        startDate: input.startDate.toDate(),
        endDate: input.endDate.toDate(),
        bookingDate: input.bookingDate.toDate(),
        confirmed: input.confirmed ? input.confirmed.toDate() : undefined,
        statusText: input.statusText,
        sourcePlatform: input.sourcePlatform ?? 'website',
        statusSource: input.statusSource ?? 'website',
        shop: input.shop,
        comment: typeof input.comment === 'string' ? input.comment : undefined,
        salutation: input.salutation,
        phone: input.phone,
        email: input.email,
        offer: input.offer,
        userProfile: input.userProfile,
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
  toFirestore: (input) => {
    if (!input) {
      return undefined;
    }
    try {
      const endDate =
        input.endDate ??
        (input.duration && input.startDate
          ? addMilliseconds(input.startDate, input.duration)
          : undefined);
      const duration =
        input.duration ??
        (input.endDate && input.startDate
          ? differenceInMilliseconds(input.startDate, input.endDate)
          : 0);

      return {
        created: firebase.firestore.Timestamp.now(),
        modified: firebase.firestore.Timestamp.now(),
        startDate: convertDateToTsFirebase(dateFallback(input.startDate)),
        endDate: convertDateToTsFirebase(dateFallback(endDate)),
        bookingDate: convertDateToTsFirebase(dateFallback(input.bookingDate)),
        confirmed: input.confirmed
          ? convertDateToTsFirebase(input.confirmed)
          : input.confirmed,
        firstName: input.firstName ?? '',
        lastName: input.lastName ?? '',
        number: input.number ?? '',
        persons: input.persons ?? 1,
        duration,
        smsConfirmation: input.smsConfirmation ?? false,
        emailConfirmation: input.emailConfirmation ?? false,
        statusText: input.statusText ?? 'processing',
        sourcePlatform: input.sourcePlatform ?? 'website',
        statusSource: input.statusSource ?? 'website',
        shop: input.shop as DocumentReference<PcShopFirebase>,
        salutation: input.salutation,
        phone: input.phone,
        email: input.email,
        offer: input.offer,
        userProfile: input.userProfile,
        comment: undefined,
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
};
