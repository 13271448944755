import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { ButtonModule } from '@pc-ui/button';
import { DialogModule } from '@pc-ui/dialog';
import { InputModule } from '@pc-ui/input';
import { ReservepickupRedeemDialogComponent } from './reservepickup-redeem-dialog.component';

@NgModule({
  declarations: [ReservepickupRedeemDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    ButtonModule,
    InputModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
  ],
  exports: [ReservepickupRedeemDialogComponent],
})
export class ReservepickupRedeemDialogModule {}
