import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorModule } from '@pc-ui/error';
import { IconModule } from '@pc-ui/icon';
import { InputModule } from '@pc-ui/input/input.module';
import { LabelModule } from '@pc-ui/label';
import { InputStepperComponent } from './input-stepper.component';

@NgModule({
  imports: [
    CommonModule,
    ErrorModule,
    ReactiveFormsModule,
    FormsModule,
    IconModule,
    LabelModule,
    InputModule,
  ],
  declarations: [InputStepperComponent],
  exports: [InputStepperComponent],
})
export class InputStepperModule {}
