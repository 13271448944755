import { DocumentReference } from '@angular/fire/compat/firestore';
import { PcCockpitUser, PcIcon, PcTsFirebase } from '@pc-types';
import { addDays, startOfToday } from 'date-fns';

type PcForecastDayGeneric<DateType, WeatherType> = {
  date: DateType;
  modified?: DateType;
  visitors: number;
  weather?: WeatherType;
  override?: PcForecastOverrideGeneric<DateType>;
};

export type PcForecastDayFirebase = PcForecastDayGeneric<
  PcTsFirebase,
  PcForecastWeatherFirebase
> & {
  overrideDev?: PcForecastOverrideFirebase;
};

export type PcForecastDay = PcForecastDayGeneric<Date, PcForecastWeather> & {
  uid: string;
  visitorsFinal: number;
};

export type PcForecast = PcForecastDay[];

export type PcForecastWeather = {
  icon?: PcIcon;
  label: string;
  temperature: number;
};

export type PcForecastWeatherFirebase = {
  code: number;
  label: string;
  temperature: number;
};

type PcForecastOverrideGeneric<DateType> = {
  visitors: number;
  modified?: DateType;
  user?: DocumentReference<PcCockpitUser>;
};

export type PcForecastOverride = PcForecastOverrideGeneric<Date>;
export type PcForecastOverrideFirebase =
  PcForecastOverrideGeneric<PcTsFirebase>;

export const PC_VISITORS_FORECAST_DAYS = 13;

export type PcVisitorForecastType = 'normal' | 'high' | 'veryhigh';

export type PcVisitorForecastItem = {
  label: string;
  gradient: string;
  start: number;
};

export const PC_VISITORS_FORECAST_TYPES: PcVisitorForecastType[] = [
  'normal',
  'high',
  'veryhigh',
];

export function getForecastStart(): Date {
  return startOfToday();
}

export function getForecastEnd(): Date {
  return addDays(getForecastStart(), PC_VISITORS_FORECAST_DAYS);
}
