import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  DocumentReference,
} from '@angular/fire/compat/firestore';
import { kidsConverter } from '@pc-converter';
import { PC_KINDERLAND_SHOP_ID } from '@pc-helpers';
import { EnvironmentService, StoreService } from '@pc-services';
import {
  PcEnv,
  PcKidsCheckin,
  PcKidsCheckinFirebase,
  PC_COLLECTIONS,
} from '@pc-types';
import { compact, keys, pick } from 'lodash-es';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class KidsService {
  private referenceCollectionPath?: string;

  constructor(
    private angularFirestore: AngularFirestore,
    private store: StoreService,
    private envService: EnvironmentService
  ) {}

  public setContacted(kidId: string, contacted: boolean): Promise<void> {
    if (!this.referenceCollectionPath) {
      return Promise.reject('No referenceCollectionPath');
    }

    const item: Partial<PcKidsCheckin> = {
      contacted,
      modified: new Date(),
    };

    const itemForFirebase = pick(kidsConverter.toFirestore(item), keys(item));

    return this.angularFirestore
      .collection<PcKidsCheckinFirebase>(this.referenceCollectionPath)
      .doc(kidId)
      .update(itemForFirebase);
  }

  public checkOut(kidId: string): Promise<void> {
    if (!this.referenceCollectionPath) {
      return Promise.reject('No referenceCollectionPath');
    }

    const item: Partial<PcKidsCheckin> = {
      status: 'checkedout',
      contacted: true,
      modified: new Date(),
    };

    const itemForFirebase = pick(kidsConverter.toFirestore(item), keys(item));

    return this.angularFirestore
      .collection<PcKidsCheckinFirebase>(this.referenceCollectionPath)
      .doc(kidId)
      .update(itemForFirebase);
  }

  public fetchAll(): void {
    this.store.myGlobalPermissions$
      .pipe(
        filter((globalPermissions) =>
          globalPermissions.modules.includes('kids')
        ),
        switchMap(() => {
          this.referenceCollectionPath = `${PC_COLLECTIONS.SHOPS}/${PC_KINDERLAND_SHOP_ID}/${PC_COLLECTIONS.KIDS_CHECKINS}`;
          return this.angularFirestore
            .collection<PcKidsCheckinFirebase>(
              this.referenceCollectionPath,
              (ref) => {
                const env: PcEnv = this.envService.getFirebaseEnv();
                return ref.where('env', '==', env);
              }
            )

            .valueChanges({ idField: 'uid' });
        }),
        map((kids) => compact(kids.map(kidsConverter.fromFirestore))),
        distinctUntilChanged()
      )
      .subscribe((kids) => {
        this.store.setKids(compact(kids));
      });
  }

  public async create(
    item: Partial<PcKidsCheckin>
  ): Promise<DocumentReference<PcKidsCheckinFirebase> | undefined> {
    if (!this.referenceCollectionPath) {
      console.warn('referenceCollectionPath missing');
      return;
    }

    if (!item) {
      console.warn('item missing');
      return;
    }

    item.env = this.envService.getFirebaseEnv();
    item.created = new Date();
    item.modified = new Date();
    item.arrival = new Date();
    item.status = 'checkedin';

    // item = await this.appendDataForCreation(item);

    const itemForFirebase = kidsConverter.toFirestore(item);
    if (!itemForFirebase) {
      return;
    }

    return this.angularFirestore
      .collection<PcKidsCheckinFirebase>(this.referenceCollectionPath)
      .add(itemForFirebase);
  }

  public doc$(path: string): Observable<PcKidsCheckin | undefined> {
    return this.angularFirestore
      .doc<PcKidsCheckinFirebase>(path)
      .valueChanges({ idField: 'uid' })
      .pipe(
        map((firebaseKid) => {
          return firebaseKid
            ? kidsConverter.fromFirestore(firebaseKid)
            : undefined;
        })
      );
  }
}
