import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@pc-helpers';
import { PcTsFirebase, PC_DATE_FORMAT } from '@pc-types';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'pcDate',
})
export class PcDatePipe implements PipeTransform {
  constructor() {}

  public transform(
    date: PcTsFirebase | Date | undefined,
    format?: keyof typeof PC_DATE_FORMAT
  ): string | undefined {
    if (!date) {
      return undefined;
    }

    return formatDate(
      this.convertDate(date),
      format ? PC_DATE_FORMAT[format] : PC_DATE_FORMAT.DATE
    );
  }

  public convertDate(date: PcTsFirebase | Date): Date {
    if (date instanceof Date) {
      return date;
    } else if (typeof date === 'number') {
      return new Date(date);
    } else if (typeof date === 'object') {
      return date.toDate();
    }
    return new Date();
  }
}
