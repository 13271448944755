import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@pc-guards';

const routes: Routes = [
  {
    path: 'start',
    loadChildren: () =>
      import('./start/start.module').then((m) => m.StartModule),
    canActivate: [AuthGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import('./cockpit/cockpit.module').then((m) => m.CockpitModule),
    canActivate: [AuthGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import('./static/static.module').then((mod) => mod.StaticModule),
  },
  // {
  //   path: 'designsystem-pc2021',
  //   loadChildren: () =>
  //     import('./designsystem/designsystem.module').then(
  //       (m) => m.DesignsystemModule
  //     ),
  // },
  {
    path: 'designsystem',
    loadChildren: () =>
      import('./designsystem-nds/designsystem-nds.module').then(
        (m) => m.DesignsystemNdsModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
