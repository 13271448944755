import { Injectable } from '@angular/core';
import { AngularFirestore, Query } from '@angular/fire/compat/firestore';
import { notificationConverter } from '@pc-converter';
import { EnvironmentService, StoreService } from '@pc-services';
import {
  PcEnv,
  PcNotification,
  PcNotificationFirebase,
  PcNotificationScope,
  PcShop,
  PcUser,
  PC_COLLECTIONS,
} from '@pc-types';
import { startOfToday, subDays } from 'date-fns';
import { chunk, compact } from 'lodash-es';
import { firstValueFrom, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private store: StoreService,
    private angularFirestore: AngularFirestore,
    private envService: EnvironmentService
  ) {}

  private readonly limitRead = 300;
  private readonly limitUnread = 50;

  public listenToShopNotifications(isRead: boolean): void {
    this.store.myShop$
      .pipe(
        filter((myShop): myShop is PcShop => !!myShop),
        switchMap((myShop) => {
          const path = this.getBasePathOfNotification('shop', myShop.uid);
          if (!path) {
            return of([]);
          }
          return this.angularFirestore
            .collection<PcNotificationFirebase>(path, (ref) =>
              isRead
                ? this.getQueryRead(ref, this.limitRead)
                : this.getQueryUnread(ref, this.limitUnread)
            )
            .valueChanges({ idField: 'uid' });
        }),
        map((notifications) =>
          compact(
            notifications
              .map(notificationConverter.fromFirestoreShop)
              .filter((item: PcNotification | undefined) => !!item)
          )
        )
      )
      .subscribe((notifications) => {
        this.store.setNotifications(notifications, 'shop', isRead);
      });
  }

  public listenToUserNotifications(isRead: boolean): void {
    this.store.user$
      .pipe(
        filter((user): user is PcUser => !!user),
        switchMap((user) => {
          const path = this.getBasePathOfNotification('user', user.uid);
          if (!path) {
            return [];
          }

          return this.angularFirestore
            .collection<PcNotificationFirebase>(path, (ref) =>
              isRead
                ? this.getQueryRead(ref, this.limitRead)
                : this.getQueryUnread(ref, this.limitUnread)
            )
            .valueChanges({ idField: 'uid' });
        }),
        map((notifications) =>
          compact(
            notifications
              .map(notificationConverter.fromFirestoreUser)
              .filter((item: PcNotification | undefined) => !!item)
          )
        )
      )
      .subscribe((notifications) => {
        this.store.setNotifications(notifications, 'user', isRead);
      });
  }

  private getQueryRead(query: Query, limit?: number): Query {
    const env: PcEnv = this.envService.getFirebaseEnv();
    query = query
      .where('env', '==', env)
      .where('read', '==', true)
      .where('modified', '>=', subDays(startOfToday(), 10))
      .orderBy('modified', 'desc');

    if (limit) {
      query = query.limit(limit);
    }

    return query;
  }

  private getQueryUnread(query: Query, limit?: number): Query {
    const env: PcEnv = this.envService.getFirebaseEnv();
    query = query
      .where('env', '==', env)
      .where('read', '==', false)
      .orderBy('modified', 'desc');

    if (limit) {
      query = query.limit(limit);
    }

    return query;
  }

  public async markNotificationsAsRead(
    notifications: PcNotification[]
  ): Promise<void> {
    const myUser = await this.store.myUser();
    const myShopId = await this.store.myShopId();

    if (!myUser) {
      return;
    }
    const batch = this.angularFirestore.firestore.batch();

    notifications.forEach((notification) => {
      const id = notification.scope === 'user' ? myUser.uid : myShopId;
      if (!id) {
        return;
      }
      const path = this.getBasePathOfNotification(notification.scope, id);
      if (!path) {
        return;
      }
      const doc = this.angularFirestore
        .collection<PcNotificationFirebase>(path, (ref) => {
          let query: Query = ref;
          const env: PcEnv = this.envService.getFirebaseEnv();
          query = query.where('env', '==', env);
          return query;
        })
        .doc(notification.uid).ref;
      batch.update(doc, { read: true });
    });

    return batch.commit();
  }

  private async getAllUnreadNotificationsFromFirebase(): Promise<
    PcNotification[]
  > {
    const notificationsUnread: PcNotification[] = [];

    const myShopId = await this.store.myShopId();
    if (myShopId) {
      const path = this.getBasePathOfNotification('shop', myShopId);

      const notificationFirebaseDocs = await firstValueFrom(
        this.angularFirestore
          .collection<PcNotificationFirebase>(path, (ref) =>
            this.getQueryUnread(ref)
          )
          .get()
      );

      notificationFirebaseDocs.forEach((notificationFirebaseDoc) => {
        const notification = notificationConverter.fromFirestoreShop({
          ...notificationFirebaseDoc.data(),
          uid: notificationFirebaseDoc.id,
        });
        if (notification) {
          notificationsUnread.push(notification);
        }
      });
    }

    const myUser = await this.store.myUser();
    if (myUser) {
      const path = this.getBasePathOfNotification('user', myUser.uid);

      const notificationFirebaseDocs = await firstValueFrom(
        this.angularFirestore
          .collection<PcNotificationFirebase>(path, (ref) =>
            this.getQueryUnread(ref)
          )
          .get()
      );

      notificationFirebaseDocs.forEach((notificationFirebaseDoc) => {
        const notification = notificationConverter.fromFirestoreUser({
          ...notificationFirebaseDoc.data(),
          uid: notificationFirebaseDoc.id,
        });
        if (notification) {
          notificationsUnread.push(notification);
        }
      });
    }

    return notificationsUnread;
  }

  public async markAllNotificationsAsRead(): Promise<void> {
    const notifications = await this.getAllUnreadNotificationsFromFirebase();

    const notificationChunks = chunk(notifications, 500);

    notificationChunks.forEach(async (notificationChunk) => {
      await this.markNotificationsAsRead(notificationChunk);
    });
  }

  private getBasePathOfNotification(
    scope: PcNotificationScope,
    shopOrUserId: string
  ): string {
    return scope === 'shop'
      ? `${PC_COLLECTIONS.SHOPS}/${shopOrUserId}/${PC_COLLECTIONS.NOTIFICATIONS}`
      : `${PC_COLLECTIONS.USER_PROFILES}/${shopOrUserId}/${PC_COLLECTIONS.NOTIFICATIONS}`;
  }
}
