import { MatDialogConfig } from '@angular/material/dialog';
import { formatDate, neverError } from '@pc-helpers';
import {
  PC_DATE_FORMAT,
  PC_DIALOG_MAX_SIZE,
  PC_DIALOG_SIZE,
  PC_EMAIL_RECIPIENT,
  PcPermissionRole,
  PcReport,
  PcReportDates,
  PcReportsDialogData,
  PcReportsItemCtaType,
  PcReportsWidgetItem,
  PcShopContractSelectable,
} from '@pc-types';
import {
  addMonths,
  endOfDay,
  endOfMonth,
  endOfYear,
  isBefore,
  isPast,
  isSameMonth,
  set,
  startOfDay,
  startOfMonth,
} from 'date-fns';
import { first, reverse, sortBy } from 'lodash-es';

export function getReportDates(date: Date): PcReportDates {
  return {
    startOfVisible: startOfDay(set(date, { date: 6 })),
    startOfSubmit: startOfDay(endOfMonth(date)),
    endOfSubmit: endOfDay(set(addMonths(date, 1), { date: 15 })),
    endOfEdit: endOfDay(addMonths(endOfYear(date), 1)),
  };
}

export function getReportWidgetItemsByTimeSpan(
  start: Date,
  end: Date,
  reports: PcReport[] | undefined
): PcReportsWidgetItem[] {
  const months = getMonthsBetweenDesc(start, end);

  return months
    .map((month) => {
      return {
        date: month,
        report: reports?.find((report) => {
          return isSameMonth(report.date, month);
        }),
      };
    })
    .filter((reportItem) =>
      isPast(getReportDates(reportItem.date).startOfVisible)
    );
}

export function getMonthsBetweenDesc(start: Date, end: Date): Date[] {
  const months: Date[] = [];

  for (
    let date = start;
    isBefore(date, endOfMonth(end));
    date = addMonths(date, 1)
  ) {
    months.push(date);
  }

  return reverse(months);
}

export type PcReportState =
  | 'edit'
  | 'view'
  | 'waiting'
  | 'warning'
  | 'outdated'
  | 'future';

export function getState(
  report: PcReport | undefined,
  date: Date
): PcReportState {
  if (report) {
    return isPast(getReportDates(date).endOfEdit) ? 'view' : 'edit';
  } else {
    return isPast(getReportDates(date).endOfEdit)
      ? 'outdated'
      : isPast(getReportDates(date).endOfSubmit)
      ? 'warning'
      : isPast(getReportDates(date).startOfSubmit)
      ? 'waiting'
      : 'future';
  }
}

export function getVisibleButtonType(
  state: PcReportState,
  role: PcPermissionRole
): PcReportsItemCtaType | undefined {
  if (role === 'tenant') {
    switch (state) {
      case 'edit':
        return 'edit';
      case 'view':
        return 'view';
      case 'waiting':
      case 'warning':
        return 'create';
      default:
        return undefined;
    }
  } else {
    // manager
    switch (state) {
      case 'edit':
      case 'view':
        return 'edit';
      default:
        return 'create';
    }
  }
}

export function getDialogConfig(
  item: PcReportsDialogData
): MatDialogConfig<PcReportsDialogData> {
  return {
    width: PC_DIALOG_SIZE.M,
    maxWidth: PC_DIALOG_MAX_SIZE.M,
    data: item,
  };
}

export function getReportLabelForManager(
  state: PcReportState,
  reportDates: PcReportDates
): string {
  switch (state) {
    case 'view':
    case 'edit':
      return `Umsatzmeldung eingereicht`;
    case 'waiting':
      return `Umsatzmeldung ausstehend.<br>Diese Umsatzmeldung soll bis spätestens ${formatDate(
        reportDates.endOfSubmit,
        PC_DATE_FORMAT.DATE
      )} vom Mieter eingereicht werden.`;
    case 'warning':
      return `Frist wurde vom Mieter versäumt.<br>Diese Umsatzmeldung kann bis spätestens ${formatDate(
        reportDates.endOfEdit,
        PC_DATE_FORMAT.DATE
      )} vom Mieter nachgereicht werden.`;
    case 'outdated':
      return `Frist wurde vom Mieter versäumt.`;
    case 'future':
      return `Umsatzmeldung kann durch den Mieter ab dem ${formatDate(
        reportDates.startOfSubmit,
        PC_DATE_FORMAT.DATE
      )} erstellt werden.`;

    default:
      throw neverError('', state);
  }
}

export function getReportLabelForTenant(
  state: PcReportState,
  reportDates: PcReportDates
): string {
  switch (state) {
    case 'view':
    case 'edit':
      return `Umsatzmeldung eingereicht`;
    case 'waiting':
      return `Umsatzmeldung ausstehend.<br>Diese Umsatzmeldung muss bis spätestens ${formatDate(
        reportDates.endOfSubmit,
        PC_DATE_FORMAT.DATE
      )} eingereicht werden.`;
    case 'warning':
      return `Achtung, Sie haben die Frist versäumt. Bis zum ${formatDate(
        reportDates.endOfEdit,
        PC_DATE_FORMAT.DATE
      )} können Sie noch Umsatz melden.`;
    case 'outdated':
      return `Sie haben die Frist für dieses Monat verabsäumt. Kontaktieren Sie uns unter <a class="underline link-black-gray" href="mailto:${PC_EMAIL_RECIPIENT.SALES}">${PC_EMAIL_RECIPIENT.SALES}</a>.`;
    case 'future':
      return `Sie können Ihre Umsatzmeldung ab dem ${formatDate(
        reportDates.startOfSubmit,
        PC_DATE_FORMAT.DATE
      )} erstellen.`;

    default:
      throw neverError('', state);
  }
}

export function getReportsByShopContract(
  reports: PcReport[],
  shopContract: PcShopContractSelectable
): PcReport[] {
  return reports.filter(
    (report) =>
      report.shop.id === shopContract.shop.uid &&
      (!shopContract.contract?.uid ||
        report.contractId === shopContract.contract.uid)
  );
}

export function getReportsStart(
  reports: PcReport[],
  shopContract: PcShopContractSelectable
): Date | undefined {
  if (reports.length) {
    const firstReportDate = first(
      sortBy(
        getReportsByShopContract(reports, shopContract).map(
          (report) => report.date
        )
      )
    );
    if (firstReportDate) {
      return startOfMonth(firstReportDate);
    }
  }

  if (shopContract.contract?.start) {
    return startOfMonth(shopContract.contract.start);
  }

  return shopContract.shop.reportsStart
    ? startOfMonth(shopContract.shop.reportsStart)
    : undefined;
}

export const PC_REPORTS_COMPARE_YEAR_START = 2019;
