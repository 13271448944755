import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { ButtonModule } from '@pc-ui/button';
import { DialogModule } from '@pc-ui/dialog';
import { ErrorModule } from '@pc-ui/error/error.module';
import { InputModule } from '@pc-ui/input/input.module';
import { LoginDialogComponent } from './login-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    MatDialogModule,
    InputModule,
    ReactiveFormsModule,
    FormsModule,
    ErrorModule,
    DialogModule,
  ],
  declarations: [LoginDialogComponent],
})
export class LoginDialogModule {}
