import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@pc-ui/icon';
import { IndicatorForecastVisitorsModule } from '@pc-ui/indicator-forecast-visitors';
import { ForecastVisitorsComponent } from './forecast-visitors.component';

@NgModule({
  declarations: [ForecastVisitorsComponent],
  imports: [CommonModule, IconModule, IndicatorForecastVisitorsModule],
  exports: [ForecastVisitorsComponent],
})
export class ForecastVisitorsModule {}
