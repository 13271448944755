import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationComponent } from './pagination.component';
import { PaginationBulletsModule } from '@pc-ui/pagination-bullets';
import { ButtonModule } from '@pc-ui/button';
import { IconModule } from '@pc-ui/icon';

@NgModule({
  declarations: [PaginationComponent],
  imports: [CommonModule, PaginationBulletsModule, ButtonModule, IconModule],
  exports: [PaginationComponent],
})
export class PaginationModule {}
