import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorModule } from '@pc-ui/error/error.module';
import { LabelModule } from '@pc-ui/label/label.module';
import { TextareaComponent } from './textarea.component';

@NgModule({
  declarations: [TextareaComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ErrorModule,
    LabelModule,
  ],
  exports: [TextareaComponent],
})
export class TextareaModule {}
