import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { PcPipesModule } from '@pc-pipes';
import { ButtonModule } from '@pc-ui/button';
import { DialogModule } from '@pc-ui/dialog';
import { ErrorModule } from '@pc-ui/error';
import { InputModule } from '@pc-ui/input';
import { MessageModule } from '@pc-ui/message';
import { SpinnerModule } from '@pc-ui/spinner';
import { CompetitionRewardsDialogComponent } from './competition-rewards-dialog.component';

@NgModule({
  declarations: [CompetitionRewardsDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    ButtonModule,
    SpinnerModule,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    InputModule,
    PcPipesModule,
    MessageModule,
    ErrorModule,
  ],
  exports: [CompetitionRewardsDialogComponent],
})
export class CompetitionRewardsDialogModule {}
