import {
  PcFirestoreConverter,
  PcNewsStatus,
  PcNewsStatusFirebase,
} from '@pc-types';
import firebase from 'firebase/compat/app';

export const newsStatusConverter: PcFirestoreConverter<
  PcNewsStatusFirebase,
  PcNewsStatus
> = {
  fromFirestore: (input) => {
    try {
      if (!input || !input.created || !input.modified) {
        return undefined;
      }

      return {
        uid: input.uid,
        read: input.read ?? false,
        created: input.created.toDate(),
        modified: input.modified.toDate(),
        modelId: input.modelId ?? '',
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
  toFirestore: (input) => {
    if (!input) {
      return undefined;
    }
    try {
      return {
        read: input.read ?? false,
        created: firebase.firestore.Timestamp.now(),
        modified: firebase.firestore.Timestamp.now(),
        modelId: input.modelId ?? '',
      };
    } catch (err) {
      console.error(err);
      console.error('Wrong Input: ', input);
      return undefined;
    }
  },
};
