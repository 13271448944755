import { neverError } from '@pc-helpers';
import { PcJobLevel } from '@pc-types';

export function getJobLevelLabel(level: PcJobLevel): string {
  switch (level) {
    case 'fulltime':
      return 'Vollzeit';
    case 'parttime':
      return 'Teilzeit';
    case 'minor':
      return 'Geringfügig';
    default:
      throw neverError('', level);
  }
}
